import { Component, OnInit, Input, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'emt-quick-edit-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss'],
})
export class SuccessComponent implements OnInit {
  constructor() {}

  @Input() flightNum: string;
  @Input() carrierCode: string;
  @Output() another: EventEmitter<any> = new EventEmitter();

  ngOnInit() {}

  editAnother(another) {
    this.another.emit(another);
  }
}
