import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '../../../../services/config/config.service';
import { filter, find, includes, toInteger } from 'lodash';
import * as moment_ from 'moment';
import { Observable, of, Subject, Subscription, BehaviorSubject } from 'rxjs';
import { pluck, catchError, map } from 'rxjs/operators';
import { FlightLegHistory } from '../../models';
import { FlightLeg, FlightLegClass, OriginalEquipment, FlifoTimerestriction } from '../../models/flight-leg';
import { GanttChartShipInfoResponse, GateActivityResponse, FlightLegEventTimes } from '../../models/gantt-chart';
import { CrewTurn } from '../../models/crew-turn';
import { PassengerMetrics } from '../../models/passenger-metrics';
import { FlightLegList } from '../../models/flight-leg-list';
import { PredictedTimes } from '../../models/predicted-times';
import { QuickEditRequest } from 'projects/core/src/app/models/quick-edit/quick-edit-request.model';

const moment = moment_;

@Injectable({
  providedIn: 'root',
})
export class FlightLegsService {
  public flightLegSubject = new BehaviorSubject<FlightLeg>(null);
  public flightLegHistorySubject = new BehaviorSubject<{ flightLegHistory: FlightLegHistory[] }>({ flightLegHistory: [] });
  public shipSubject = new BehaviorSubject<GanttChartShipInfoResponse[]>([]);
  public currentShipSubject = new BehaviorSubject<GanttChartShipInfoResponse>(null);
  public downlineLegSubject = new BehaviorSubject<GanttChartShipInfoResponse>(null);
  public filteredDownlineLegSubject = new BehaviorSubject<GanttChartShipInfoResponse>(null);
  public arrivalGateInfoSubject = new BehaviorSubject<GateActivityResponse[]>([]);
  public departureGateInfoSubject = new BehaviorSubject<GateActivityResponse[]>([]);
  public crewSubject = new BehaviorSubject<{ pilotInfo: CrewTurn[]; attendantInfo: CrewTurn[] }>({ pilotInfo: [], attendantInfo: [] });
  public originalEquipmentSubject = new BehaviorSubject<OriginalEquipment>(null);
  public timeRestrictionSubject = new BehaviorSubject<FlifoTimerestriction>(null);
  public eventTimesSubject = new BehaviorSubject<FlightLegEventTimes>(null);
  public passengerMetricsSubject = new BehaviorSubject<PassengerMetrics>(null);
  public predictedTimesSubject = new BehaviorSubject<PredictedTimes>(new PredictedTimes());

  private currentLegId = null;
  private previousLegId = null;

  public flightLegDataSubject = new Subject<any>();

  private baseUrl: string;
  private supportedCarriers: string[];
  private masterSubscription: Subscription;
  private flightLegSubscription: Subscription;

  constructor(private http: HttpClient, private configService: ConfigService) {
    this.baseUrl = this.configService.getSettings('flightSuite');
    this.supportedCarriers = this.configService.getSettings('deltaDcCarriers');
  }

  loadData(id: string) {
    if (this.masterSubscription) {
      this.masterSubscription.unsubscribe();
    }
    if (this.flightLegSubscription) {
      this.flightLegSubscription.unsubscribe();
    }

    if (!id) {
      this.resetSubjects();
      return;
    }
    this.masterSubscription = new Subscription();

    this.currentLegId = id;
    if (!this.previousLegId) {
      this.previousLegId = id;
    }
    if (this.currentLegId !== this.previousLegId) {
      this.previousLegId = id;
      this.resetSubjects();
    }

    const flightLegHistorySubscription = this.loadFlightLegHistory(id).subscribe(data => {
      this.flightLegHistorySubject.next(data);
    });
    this.masterSubscription.add(flightLegHistorySubscription);

    const predictedTimesSubscription = this.getPredictedTimes(id).subscribe(data => {
      if (data) {
        this.predictedTimesSubject.next(data);
      } else {
        this.predictedTimesSubject.next(new PredictedTimes());
      }
    });
    this.masterSubscription.add(predictedTimesSubscription);

    this.flightLegSubscription = this.loadLeg(id).subscribe(results => {
      const unknownGates = ['TBA', 'TBD', 'UNK', 'FBO', 'OPS'];
      const legData = results['flightLegs'][0];
      let leg = new FlightLegClass(legData);
      this.flightLegSubject.next(leg); // this is where we'll output things related to flight leg

      // things needed to make subsequent calls
      let bestIn = moment.utc(leg.bestTimes.inUtc);
      let bestOut = moment.utc(leg.bestTimes.outUtc);
      let departureFrom = moment
        .utc(leg.bestTimes.outUtc)
        .subtract(9, 'hours')
        .toISOString();
      let departureTo = bestIn.toISOString();
      let arrivalFrom = bestOut.toISOString();
      let arrivalTo = moment
        .utc(leg.bestTimes.inUtc)
        .add(9, 'hours')
        .toISOString();

      const shipSubcription = this.getGanttChartShipInfo(id, leg.aircraft.shipNum, 0).subscribe(shipResults => {
        const currentShipInfo = find(shipResults, ship => ship.label === 'CurrentFlight');
        this.currentShipSubject.next(currentShipInfo);
        const shipInfo = filter(shipResults, ship => ship.label !== 'CurrentFlight');
        this.shipSubject.next(shipInfo);
        const downlineLeg = find(shipResults, ship => ship.label === 'ShipDownStream');
        this.downlineLegSubject.next(downlineLeg);
      });
      this.masterSubscription.add(shipSubcription);

      const filteredShipSubscription = this.getGanttChartShipInfo(id, leg.aircraft.shipNum, 180).subscribe(shipResults => {
        const filteredDownlineLeg = find(shipResults, ship => ship.label == 'ShipDownStream');
        this.filteredDownlineLegSubject.next(filteredDownlineLeg);
      });

      this.masterSubscription.add(filteredShipSubscription);

      if (leg.internalArrivalGateId !== null && !unknownGates.includes(leg.internalArrivalGateId)) {
        const ganttchartarrivalgateinfoSub = this.getGanttChartGateInfo(
          leg.destinationAirport.airportCode,
          leg.internalArrivalGateId,
          arrivalFrom,
          arrivalTo
        ).subscribe(results => {
          const arrivalGateInfo = results;
          this.arrivalGateInfoSubject.next(arrivalGateInfo);
        });
        this.masterSubscription.add(ganttchartarrivalgateinfoSub);
      }

      if (leg.internalDepartureGateId !== null && !unknownGates.includes(leg.internalDepartureGateId)) {
        const ganttchartdeparturesub = this.getGanttChartGateInfo(
          leg.originAirport.airportCode,
          leg.internalDepartureGateId,
          departureFrom,
          departureTo
        ).subscribe(results => {
          const departureGateInfo = results;
          this.departureGateInfoSubject.next(departureGateInfo);
        });
        this.masterSubscription.add(ganttchartdeparturesub);
      }

      // only if it's a supported delta dc carrier
      if (includes(this.supportedCarriers, leg.operatingCarrierCode)) {
        // Crew
        const crewSub = this.getGanttChartCrewInfo(
          leg.flightNum,
          leg.flightOriginDate,
          leg.originAirport.airportCode,
          leg.destinationAirport.airportCode,
          leg.operatingCarrierCode
        ).subscribe(results => {
          let crewInfo: { pilotInfo: CrewTurn[]; attendantInfo: CrewTurn[] } = { pilotInfo: [], attendantInfo: [] };
          if (results && results.data) {
            crewInfo.pilotInfo = filter(results.data, rotation => rotation['crewInfo']['employeeRoleCode'] === 'P') || [];
            crewInfo.attendantInfo = filter(results.data, rotation => rotation['crewInfo']['employeeRoleCode'] === 'F') || [];
          }
          this.crewSubject.next(crewInfo);
        });
        this.masterSubscription.add(crewSub);

        // Original Equipment
        const originalEquipmentSubscription = this.getOriginalEquipment(id).subscribe(results => {
          const originalEquipment = results;
          this.originalEquipmentSubject.next(originalEquipment);
        });
        this.masterSubscription.add(originalEquipmentSubscription);

        // Passenger Metrics (Customer Metrics)
        const passengerMetricsSubscription = this.loadPassengerCountMetrics(
          leg.destinationAirport.airportCode,
          leg.operatingCarrierCode,
          leg.originAirport.airportCode,
          leg.flightNum,
          leg.flightOriginDate
        ).subscribe(data => {
          this.passengerMetricsSubject.next(data);
        });
        this.masterSubscription.add(passengerMetricsSubscription);

        // Crew Time Restrictions
        const timeRestrictionSubscription = this.flifoTimeRestrictionData(
          leg.flightNum,
          leg.flightOriginDate,
          leg.originAirport.airportCode,
          leg.destinationAirport.airportCode,
          leg.operatingCarrierCode
        ).subscribe(results => {
          const flifoTimeRestrictionData = results;
          this.timeRestrictionSubject.next(flifoTimeRestrictionData);
        });
        this.masterSubscription.add(timeRestrictionSubscription);

        // Event Times
        const eventTimesSubscription = this.getFlightLegEventTimes(id).subscribe(results => {
          const eventTimes = results;
          this.eventTimesSubject.next(eventTimes);
        });
        this.masterSubscription.add(eventTimesSubscription);
      }
    });
  }

  resetSubjects() {
    this.flightLegSubject.next(null);
    this.flightLegHistorySubject.next({ flightLegHistory: [] });
    this.shipSubject.next([]);
    this.downlineLegSubject.next(null);
    this.filteredDownlineLegSubject.next(null);
    this.arrivalGateInfoSubject.next([]);
    this.departureGateInfoSubject.next([]);
    this.crewSubject.next({ pilotInfo: [], attendantInfo: [] });
    this.originalEquipmentSubject.next(null);
    this.timeRestrictionSubject.next(null);
    this.eventTimesSubject.next(null);
    this.passengerMetricsSubject.next(null);
  }

  resetSubscriptions() {
    this.masterSubscription && this.masterSubscription.unsubscribe();
    this.flightLegSubscription && this.flightLegSubscription.unsubscribe();
  }

  loadLeg(id: string) {
    return this.http.get<FlightLegList>(`${this.baseUrl}flightLeg/${id}`).pipe(
      catchError(error => {
        console.log('Error retrieving Flight Leg ', error);
        return of(null);
      })
    );
  }

  loadPassengerCountMetrics(
    arrivalStation: string,
    carrierCode: string,
    departureStation: string,
    flightNum: string,
    flightOriginDate: string
  ): Observable<PassengerMetrics> {
    const url = `${this.baseUrl}flightinfo/flightLeg/passengerCountMetrics`,
      params = new HttpParams()
        .set('arrStn', arrivalStation)
        // .set('crrCode', carrierCode)
        // Set carrier code for passenger metrics to 'DL' for now, to get valid booked/boarded number
        .set('crrCode', 'DL')
        .set('deprtStn', departureStation)
        .set('fltNumber', flightNum)
        .set('fltOrignDt', flightOriginDate);

    return this.http.get(url, { params }).pipe(
      catchError(error => {
        console.log('Error retrieving Flight Leg ', error);
        return of(null);
      })
    );
  }

  loadFlightLegHistory(id: string) {
    const flightLegHistoryBaseUrl = `${this.baseUrl}flightinfo/flightLeg/${id}/history`,
      limitParam = new HttpParams().set('limit', '500');
    return this.http.get(flightLegHistoryBaseUrl, { params: limitParam }).pipe(
      catchError(error => {
        console.log('Error retrieving Flight Leg History ', error);
        return of(null);
      })
    );
  }

  public getOriginalEquipment(flightLegId): Observable<OriginalEquipment> {
    const url = `${this.baseUrl}flightinfo/flightLeg/${flightLegId}/originalEquipment`;
    return this.http.get<OriginalEquipment>(url).pipe(
      catchError(error => {
        console.log('Error retrieving Original Equipment ', error);
        return of(null);
      })
    );
  }

  public getGanttChartShipInfo(id: string, shipNumber: number, filterWindow: number): Observable<GanttChartShipInfoResponse[]> {
    if (shipNumber && id) {
      return this.getLegsDataByShipAndLegId(shipNumber.toString(), id, filterWindow).pipe(
        pluck('flightLegTabList'),
        map((ships: GanttChartShipInfoResponse[]) => {
          // TODO: make it so that the api returns minutes in number format so we don't have to do a weird value hack
          return !ships? [] : ships.map(ship => {
            if (ship && ship.minimumStandardGroundTime && ship.minimumStandardGroundTime.minimumStdGroundTimeMinutes) {
              ship.minimumStandardGroundTime.minimumStdGroundTimeMinutes = toInteger(
                ship.minimumStandardGroundTime.minimumStdGroundTimeMinutes
              );
              return ship;
            } else {
              return ship;
            }
          });
        }),
        catchError(error => {
          console.log('Error retrieving Gantt Chart Ship Info ', error);
          return [];
        })
      );
    } else {
      return of([]);
    }
  }

  public quickEdit(flightId: string, request: QuickEditRequest) {
    const url = `${this.baseUrl}flightinfo/flightLeg/${flightId}/edit/quick`;
    return this.http.patch(url, request);
  }

  getGanttChartCrewInfo(
    flightNum,
    flightOriginDate,
    originAirport,
    destinationAirport,
    operatingCarrierCode
  ): Observable<{ data: CrewTurn[] }> {
    const url = `${this.baseUrl}crew/turn`,
      params = new HttpParams()
        .set('flightNum', flightNum)
        .set('origDate', flightOriginDate)
        .set('origStation', originAirport)
        .set('destStation', destinationAirport)
        .set('carrierCode', operatingCarrierCode);

    return this.http.get(url, { params }).pipe(
      catchError(error => {
        console.log('Error retrieving Crew Info for Gantt ', error);
        return of(null);
      })
    );
  }

  public getFlightLegEventTimes(flightLegId: string): Observable<FlightLegEventTimes> {
    const url = `${this.baseUrl}flightinfo/flightLeg/${flightLegId}/eventTimes`;
    return this.http.get(url).pipe(
      catchError(error => {
        console.log('Error retrieving Flight Leg Event Times', error);
        return of(null);
      })
    );
  }

  public flifoTimeRestrictionData(
    flightNum,
    flightOriginDate,
    originAirport,
    destinationAirport,
    operatingCarrierCode
  ): Observable<FlifoTimerestriction> {
    const url = `${this.baseUrl}crew/timeoutsByFlight?`; // Real data
    // const url = 'assets/crew-timeout-restriction.json'; // mock data
    const params = new HttpParams()
      .set('flightNum', flightNum)
      .set('origStation', originAirport)
      .set('destStation', destinationAirport)
      .set('origDate', flightOriginDate)
      .set('carrierCode', operatingCarrierCode);
    return this.http.get(url, { params }).pipe(
      catchError(error => {
        console.log('Error retrieving Original Equipment ', error);
        return of(null);
      })
    );
  }

  private getGanttChartGateInfo(station: string, gate: string, from: string, to: string): Observable<GateActivityResponse[]> {
    const url = `${this.baseUrl}activity/gate`,
      params = new HttpParams()
        .set('station', station)
        .set('gate', gate)
        .set('fromTime', from)
        .set('toTime', to);

    return this.http.get(url, { params }).pipe(
      pluck('gateTurns'),
      catchError(error => {
        console.log('Error retrieving Crew Info for Gantt ', error);
        return of(null);
      })
    );
  }

  // This just makes the call out
  private getLegsDataByShipAndLegId(shipNum: string, flightId: string, filterWindow: number) {
    const url = `${this.baseUrl}activity/ship`;
    const flightParams = new HttpParams()
      .set('shipNum', shipNum)
      .set('flightLegId', flightId)
      .set('filterWindow', filterWindow.toString());
    return this.http.get(url, { params: flightParams });
  }

  // takes it wraps it in an error if we don't get data
  public getLegsData(leg: FlightLeg) {
    if (leg && leg.aircraft && leg.aircraft.shipNum && leg.flightId) {
      return this.getLegsDataByShipAndLegId(leg.aircraft.shipNum.toString(), leg.flightId, 0).pipe(
        catchError(error => {
          console.log('Error retrieving Crew Info for Gantt ', error);
          return of(null);
        })
      );
    } else {
      return of({ flightLegTabList: [] });
    }
  }

  public getPredictedTimes(id: string): Observable<PredictedTimes> {
    const url = `${this.baseUrl}flightinfo/flightLeg/${id}/predictedTimes`;
    return this.http.get(url).pipe(
      catchError(error => {
        console.log('Error retrieving Predicted times', error);
        return of(null);
      })
    );
  }
}
