<div class="alerts-list">
  <div class="header-bar">
    <emt-delta-icon iconName="icon-bell" [fill]="'black'"></emt-delta-icon>
    <span class="header-title">Notifications</span>
    <span class="notification-count">{{ (alerts$ | async)?.length }}</span>
    <emt-delta-icon class="settings-button" iconName="icon-settings" [fill]="'black'"></emt-delta-icon>
  </div>
  <div class="alert-items">
    <div class="alert-item" [ngClass]="alert.severity.toLowerCase()" *ngFor="let alert of alerts$ | async; trackBy: alertTrackByFn">
      <span class="no-priority" *ngIf="alert.severity === 'LOW'"></span>
      <emt-delta-icon
        class="priority-icon"
        [width]="'18px'"
        [height]="'18px'"
        [fill]="getAlertColor(alert)"
        iconName="icon-error"
        *ngIf="alert.severity !== 'LOW'"
      ></emt-delta-icon>
      <span class="priority" [ngClass]="alert.severity.toLowerCase()" *ngIf="alert.severity !== 'LOW'">{{ alert.severity }} priority</span>
      <span class="date">{{ showTime(alert.createDateTime) }}</span>
      <div class="title">{{ alert.station ? alert.station : alert.flightNumber }}</div>
      <div class="content">{{ alert.message }}</div>
    </div>
  </div>
</div>
