<ul class="tabs bootstrap">
  <li class="tab" *ngFor="let tab of openTabs; let i = index; trackBy: trackByFn" [ngClass]="i === selectedTabIndex ? 'selected' : ''">
    <div class="tab-content" (click)="selectTab(i, tab)">
      <span>{{ tab.name }}</span>
    </div>
    <div class="close-button" (click)="deleteTab(i)">
      <span>
        ×
      </span>
    </div>
  </li>
</ul>
