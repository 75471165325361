import * as moment from 'moment';
import { isEmpty } from '../../../../../../common/src/lib/components/date-picker/ngb-util';
import { AbstractControl, ValidatorFn } from '@angular/forms';
import { FlightLeg } from '../../../feature-modules/flight-info/models';
import { BehaviorSubject } from 'rxjs';

export const timeRegex = /^([01]\d|2[0-3]):?([0-5]\d)$/g;
export const dateRegex = /^(0[1-9]|1[0-2])\/([0-2][\d]|3[0-1])$/g;
export const dateMonthRegex = /^([0-2]?[\d]|3[0-1])([A-Z]{3})$/g;
export const dateOnlyRegex = /^((0?[1-9])|([1-2][\d])|(3[0-1]))$/g;
export const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

export function isDelayCodeValid(
  demandCodeVal: string,
  originalEstimatedOut: string,
  newEstimatedOutTime: string,
  newEstimatedOutDate: string,
  scheduledOutTime: string
) {
  let newOut = momentFromFields(newEstimatedOutTime, newEstimatedOutDate);
  let origOut = moment.utc(scheduledOutTime);
    if(newOut && origOut && !origOut.isBefore(newOut)){ 
      return true
    }else if (isEmpty(originalEstimatedOut) && !isEmpty(newEstimatedOutDate) && !isEmpty(newEstimatedOutTime)) {
      return !isEmpty(demandCodeVal);
    } else if (!isEmpty(originalEstimatedOut) && !isEmpty(newEstimatedOutTime)) {
      const ogEstOutMoment = moment.utc(originalEstimatedOut);
      const newEstimatedOut = momentFromFields(newEstimatedOutTime, newEstimatedOutDate);
      return isEmpty(demandCodeVal) && ogEstOutMoment.isSame(newEstimatedOut, 'minute');
    }
}

export function isDelayValid(flightOut, time, date) {
  if (isEmpty(time) || isEmpty(date)) {
    return true;
  } else {
    const newEstimatedOut = momentFromFields(time, date);
    const momentFlightOut = moment(flightOut).utc();
    let diff = newEstimatedOut.diff(momentFlightOut, 'minutes');
    return diff > -241 && diff < 1201;
  }
}

export function momentFromFields(time: string, date: string): moment.Moment {
  let fieldMoment = moment.utc(`${time} ${date}`, 'HHmm MM/DD');
  if (moment.utc().month() === 11 && fieldMoment.month() === 0) {
    fieldMoment.year(moment.utc().year() + 1);
  } else if (moment.utc().month() === 0 && fieldMoment.month() === 11) {
    fieldMoment.year(moment.utc().year() - 1);
  }
  return fieldMoment;
}

export function regexMatch(regex: RegExp): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!control.value) {
      return null;
    }
    return control.value.match(regex) ? null : { pattern: { match: false, value: control.value } };
  };
}

export function validateDelayCode(estOutDate: AbstractControl, estOutTime: AbstractControl, flightLeg:BehaviorSubject<FlightLeg>): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const estOut = momentFromFields(estOutTime.value, estOutDate.value);
    const schedOut = flightLeg.value &&
      flightLeg.value.scheduledFlightMovementEventTimes &&
      flightLeg.value.scheduledFlightMovementEventTimes.outEventUtcTs
        ? flightLeg.value.scheduledFlightMovementEventTimes.outEventUtcTs
        : null;
    const delayNeeded = estOut.isAfter(schedOut);
    const delayCodeEmpty = control.value ? isEmpty(control.value.value) : true;
    return delayNeeded && delayCodeEmpty ? {required: true} : null;
  };
}

export function validateDelay(flightLegSubject: BehaviorSubject<FlightLeg>): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    let flightLeg = flightLegSubject.value;
    if (control.get('estimatedOutTime') && control.get('estimatedOutDate') && flightLeg) {
      const newEstOutTime = control.get('estimatedOutTime').value;
      const newEstOutDate = control.get('estimatedOutDate').value;
      const fltOut =
        flightLeg.scheduledFlightMovementEventTimes && flightLeg.scheduledFlightMovementEventTimes.outEventUtcTs
          ? flightLeg.scheduledFlightMovementEventTimes.outEventUtcTs
          : null;
      return isDelayValid(fltOut, newEstOutTime, newEstOutDate) ? null : { invalidDelay: true };
    } else {
      return null;
    }
  };
}

export function validateTimeOrder(flightLegSubject: BehaviorSubject<FlightLeg>): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    let flightLeg = flightLegSubject.value;
    const actualOut =
      flightLeg && flightLeg.actualFlightMovementEventTimes && flightLeg.actualFlightMovementEventTimes.outEventUtcTs
        ? moment(flightLeg.actualFlightMovementEventTimes.outEventUtcTs)
        : null;

    const [estimatedOutDate, estimatedOutTime] = [control.get('estimatedOutDate'), control.get('estimatedOutTime')];
    const [estimatedOffDate, estimatedOffTime] = [control.get('estimatedOffDate'), control.get('estimatedOffTime')];
    const [estimatedInDate, estimatedInTime] = [control.get('estimatedInDate'), control.get('estimatedInTime')];

    const estimatedOut =
      !isEmpty(estimatedOutDate.value) && !isEmpty(estimatedOutTime.value)
        ? momentFromFields(estimatedOutTime.value, estimatedOutDate.value)
        : null;
    const estimatedOff =
      !isEmpty(estimatedOffDate.value) && !isEmpty(estimatedOffTime.value)
        ? momentFromFields(estimatedOffTime.value, estimatedOffDate.value)
        : null;
    const estimatedIn =
      !isEmpty(estimatedInDate.value) && !isEmpty(estimatedInTime.value)
        ? momentFromFields(estimatedInTime.value, estimatedInDate.value)
        : null;
    if (actualOut && estimatedOff && estimatedOff.isSameOrBefore(estimatedOut)) {
      return { outOfSequence: 'Estimated Off cannot be before Actual Out' };
    } else if (estimatedOut && estimatedOff && estimatedOff.isSameOrBefore(estimatedOut)) {
      return { outOfSequence: 'Estimated Off cannot be before Estimated Out' };
    } else if (estimatedOut && estimatedIn && estimatedIn.isSameOrBefore(estimatedOut)) {
      return { outOfSequence: 'Estimated In cannot be before Estimated Out' };
    } else if (estimatedOff && estimatedIn && estimatedIn.isSameOrBefore(estimatedOff)) {
      return { outOfSequence: 'Estimated In cannot be before Estimated Off' };
    } else {
      return null;
    }
  };
}

export interface MessageList{
  warnings:Array<string>
  errors:Array<errorObj>
  errorText:Array<string>
}

export interface errorObj{
  errorId:string
  errorMessage:string
}

export default class QuickEditUtil {}
