export interface FlightMovementEventTimes {
  inEventLocalTs: string;
  inEventUtcTs: string;
  offEventLocalTs: string;
  offEventUtcTs: string;
  onEventLocalTs: string;
  onEventUtcTs: string;
  outEventLocalTs: string;
  outEventUtcTs: string;
}

export class FlightMovementEventTimesClass implements FlightMovementEventTimes {
  public inEventLocalTs: string;
  public inEventUtcTs: string;
  public offEventLocalTs: string;
  public offEventUtcTs: string;
  public onEventLocalTs: string;
  public onEventUtcTs: string;
  public outEventLocalTs: string;
  public outEventUtcTs: string;

  constructor(obj: Partial<FlightMovementEventTimes> = {}) {
    this.inEventLocalTs = obj ? obj.inEventLocalTs : null;
    this.inEventUtcTs = obj ? obj.inEventUtcTs : null;
    this.offEventLocalTs = obj ? obj.offEventLocalTs : null;
    this.offEventUtcTs = obj ? obj.offEventUtcTs : null;
    this.onEventLocalTs = obj ? obj.onEventLocalTs : null;
    this.onEventUtcTs = obj ? obj.onEventUtcTs : null;
    this.outEventLocalTs = obj ? obj.outEventLocalTs : null;
    this.outEventUtcTs = obj ? obj.outEventUtcTs : null;
  }
}
