<button
  class="re-usable-button"
  type="button"
  [ngClass]="styleType && styleType"
  [style.width]="width"
  [style.height]="height"
  [disabled]="disabled"
>
  {{ text }}
  <ng-container *ngIf="showCarot">
    <emt-delta-icon class="icon-arrow" iconName="icon-arrow" fill="white"></emt-delta-icon>
  </ng-container>
</button>
