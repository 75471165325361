import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'emt-delta-icon',
  templateUrl: './delta-icon.component.html',
  styleUrls: ['./delta-icon.component.scss'],
})
export class DeltaIconComponent implements OnInit {
  @Input() iconName: string;
  @Input() flip: boolean;
  @Input() fill: string;
  @Input() width: string;
  @Input() height: string;
  @Input() tilt: boolean;
  @Input() degree?: number;

  constructor() {}

  ngOnInit() {}

  getStyles() {
    let styles: { transform?: string } = {};

    if (this.degree) {
      styles.transform = this.degree ? `rotate(${this.degree}deg)` : 'rotate(0deg)';
    }
    return styles;
  }
}
