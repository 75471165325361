import { Action } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';

export enum StationActionTypes {
  LoadStations = '[FlightListView -Stations] Load Stations',
  LoadStationsSuccess = '[FlightListView - Stations] Load Stations Success',
  LoadStationsFail = '[FlightListView - Stations] Load Stations Fail',
}

export class LoadStationsAction implements Action {
  readonly type = StationActionTypes.LoadStations;
  constructor(public payload?: any) {}
}

export class LoadStationsSuccessAction implements Action {
  readonly type = StationActionTypes.LoadStationsSuccess;
  constructor(public payload?: any) {}
}

export class LoadStationsFailAction implements Action {
  readonly type = StationActionTypes.LoadStationsFail;
  constructor(public error: HttpErrorResponse) {}
}

export type StationActions = LoadStationsAction | LoadStationsSuccessAction | LoadStationsFailAction;
