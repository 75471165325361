import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule as NgCommonModule } from '@angular/common';
// NG-Bootstrap Modules
import { NgBootstrapModule } from './shared/ng-bootstrap.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { ChartsModule } from 'ng2-charts';

// Components
import { NavigationComponent } from './components/navigation/navigation.component';
import { AirplaneLoaderComponent } from './components/airplane-loader/airplane-loader.component';
import { ErrorModalComponent } from './components/error-modal/error-modal.component';
import { AlertModalComponent } from './components/alert-modal/alert-modal.component';
import { StationSelectorDialogComponent } from './components/station-selector-dialog/station-selector-dialog.component';
import { BasicTextInputComponent } from './components/basic-text-input/basic-text-input.component';
import { DeltaIconComponent } from './components/delta-icon/delta-icon.component';
import { TableComponent } from './components/table/table.component';
import { SlideToggleComponent } from './components/slide-toggle/slide-toggle.component';
import { ToolTipComponent } from './components/tooltip/tool-tip.component';
import { MetricCardComponent } from './components/metric-card/metric-card.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { CommonHeaderComponent } from './components/common-header/common-header.component';
import { DropDownV2Component } from './components/dropdown-v2/dropdown-v2.component';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { ButtonComponent } from './components/button/button.component';
import { DropdownV3Component } from './components/dropdown-v3/dropdown-v3.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { TaxiTimeComponent } from './components/taxi-time/taxi-time.component';
import { DonutGraphComponent } from './components/donut-graph/donut-graph.component';
import { EmtTaxiTimeCollectionComponent } from './components/taxi-time-collection/taxi-time-collection.component';
import { MetricCardCollectionComponent } from './components/metric-card-collection/metric-card-collection.component';
import { InformationIconComponent } from './components/information-icon/information-icon.component';
import { DefaultReportTemplateComponent } from './components/default-report-template/default-report-template.component';
import { AdvancedReportTemplateComponent } from './components/advanced-report-template/advanced-report-template.component';
import { StationSelectorComponent } from './components/station-selector/station-selector.component';

// Services
import { ErrorService } from './services/error/error.service';
import { AlertService } from './services/alert/alert.service';
import { AlertEventService } from './services/alert-event/alert-event.service';
import { StationsService } from './services/stations/stations.service';
import { MetricCardService } from './services/metric-card/metric-card.service';

// Pipes
import { MomentUtcPipe } from './pipes/momentutc.pipe';
import { ValidateTableCellInputPipe } from './pipes/validate-table-cell-input.pipe';
import { ValidateTableCellClassPipe } from './pipes/validate-table-cell-class.pipe';
import { ValidateTableCellCollapsibilityPipe } from './pipes/validate-table-cell-collapsibility.pipe';
import { DecodeHtmlStringPipe } from './pipes/decode-htm-string.pipe';
import { SortableHeader } from './directives/sortable-header.directive';
import { SliderComponent } from './components/slider/slider.component';
import {FormatQuickEditDatePipe} from "./pipes/format-quick-edit-date.pipe";
import {DropdownSimpleComponent} from "./components/emt-dropdown-simple/emt-dropdown-simple.component";
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';

@NgModule({
  declarations: [
    NavigationComponent,
    AirplaneLoaderComponent,
    ErrorModalComponent,
    AlertModalComponent,
    StationSelectorDialogComponent,
    BasicTextInputComponent,
    DeltaIconComponent,
    TableComponent,
    MomentUtcPipe,
    DropdownComponent,
    ValidateTableCellInputPipe,
    ValidateTableCellClassPipe,
    ValidateTableCellCollapsibilityPipe,
    FormatQuickEditDatePipe,
    CheckboxComponent,
    CommonHeaderComponent,
    DropDownV2Component,
    DatePickerComponent,
    DropdownV3Component,
    DropdownSimpleComponent,
    SlideToggleComponent,
    ToolTipComponent,
    ButtonComponent,
    MetricCardComponent,
    MetricCardCollectionComponent,
    DecodeHtmlStringPipe,
    TaxiTimeComponent,
    DonutGraphComponent,
    EmtTaxiTimeCollectionComponent,
    InformationIconComponent,
    SortableHeader,
    DefaultReportTemplateComponent,
    AdvancedReportTemplateComponent,
    SliderComponent,
    StationSelectorComponent,
  ],
  imports: [
    NgCommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgBootstrapModule,
    NgSelectModule,
    NgbDatepickerModule,
    ChartsModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatInputModule,
    MatSelectModule,
  ],
  providers: [ErrorService, AlertService, AlertEventService, StationsService, MomentUtcPipe, DecodeHtmlStringPipe, MetricCardService],
  // entryComponents: [ErrorModalComponent, AlertModalComponent, StationSelectorDialogComponent],
  exports: [
    NavigationComponent,
    AirplaneLoaderComponent,
    ErrorModalComponent,
    AlertModalComponent,
    StationSelectorDialogComponent,
    BasicTextInputComponent,
    DeltaIconComponent,
    TableComponent,
    MomentUtcPipe,
    FormatQuickEditDatePipe,
    DropdownComponent,
    CheckboxComponent,
    CommonHeaderComponent,
    DropDownV2Component,
    DatePickerComponent,
    DropdownV3Component,
    DropdownSimpleComponent,
    SlideToggleComponent,
    ToolTipComponent,
    ButtonComponent,
    MetricCardComponent,
    MetricCardCollectionComponent,
    DecodeHtmlStringPipe,
    TaxiTimeComponent,
    DonutGraphComponent,
    EmtTaxiTimeCollectionComponent,
    InformationIconComponent,
    SortableHeader,
    DefaultReportTemplateComponent,
    AdvancedReportTemplateComponent,
    SliderComponent,
    StationSelectorComponent,
  ],
})
export class CommonModule {}
