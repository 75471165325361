export default class DelayCode {
  value: string;
  label: string;
  description: string;
}

export const delayCodes: DelayCode[] = [
  { value: '01A', label: '01A - Local', description: 'Local' },
  {
    value: '01B',
    label: '01B - Destination/Enroute',
    description: 'Destination/Enroute',
  },
  { value: '01C', label: '01C - Deicing', description: 'Deicing' },
  {
    value: '01Q',
    label: '01Q - Irregular Routing Weather',
    description: 'Irregular Routing Weather',
  },
  { value: '02A', label: '02A - Local', description: 'Local' },
  {
    value: '02C',
    label: '02C - Industrial Action',
    description: 'Industrial Action',
  },
  {
    value: '02Q',
    label: '02Q - Irregular Routing Airport Conditions',
    description: 'Irregular Routing Airport Conditions',
  },
  {
    value: '03A',
    label: '03A - Aircraft Mechanical Problem',
    description: 'Aircraft Mechanical Problem',
  },
  {
    value: '03I',
    label: '03I - Inflight Entertainment System',
    description: 'Inflight Entertainment System',
  },
  { value: '03P', label: '03P - AOG Parts', description: 'AOG Parts' },
  {
    value: '03Q',
    label: '03Q - Irregular Routing Maintenance',
    description: 'Irregular Routing Maintenance',
  },
  {
    value: '03T',
    label: '03T - Corrective Actions Required',
    description: 'Corrective Actions Required',
  },
  {
    value: '04A',
    label: '04A - Malfunction',
    description: 'Malfunction',
  },
  {
    value: '04Q',
    label: '04Q - Irregular Routing Ground Equipment',
    description: 'Irregular Routing Ground Equipment',
  },
  {
    value: '06A',
    label: '06A - Upline Block Delay',
    description: 'Upline Block Delay',
  },
  {
    value: '06B',
    label: '06B - Block Delay',
    description: 'Block Delay',
  },
  {
    value: '06D',
    label: '06D - Maintenance',
    description: 'Maintenance',
  },
  {
    value: '06F',
    label: '06F - Late Equipment Delivery',
    description: 'Late Equipment Delivery',
  },
  {
    value: '06U',
    label: '06U - Failure to Autocode ore Recognize 96 Code',
    description: 'Failure to Autocode ore Recognize 96 Code',
  },
  {
    value: '07A',
    label: '07A - Late Flight Plan',
    description: 'Late Flight Plan',
  },
  {
    value: '07B',
    label: '07B - Return To Gate Due Extended Taxi DOT',
    description: 'Return To Gate Due Extended Taxi DOT',
  },
  {
    value: '07F',
    label: '07F - Other delay Authorized By OCC',
    description: 'Other delay Authorized By OCC',
  },
  {
    value: '07G',
    label: '07G - Inhibit Of WDR',
    description: 'Inhibit Of WDR',
  },
  {
    value: '07Q',
    label: '07Q - Equipment changed to recover schedule',
    description: 'Equipment changed to recover schedule',
  },
  {
    value: '07W',
    label: '07W - Delayed Due To Planned Early Arrival Due To Winds',
    description: 'Delayed Due To Planned Early Arrival Due To Winds',
  },
  {
    value: '08A',
    label: '08A - Administrative',
    description: 'Administrative',
  },
  {
    value: '08B',
    label: '08B - Crew Scheduling Errors',
    description: 'Crew Scheduling Errors',
  },
  {
    value: '08C',
    label: '08C - Crew Requested Repair Of Maintenance Item',
    description: 'Crew Requested Repair Of Maintenance Item',
  },
  {
    value: '08D',
    label: '08D - Captains Decision',
    description: 'Captains Decision',
  },
  {
    value: '08H',
    label: '08H - Crew Late From Hotel',
    description: 'Crew Late From Hotel',
  },
  {
    value: '08J',
    label: '08J - Pilots Arrived Late From Delayed Flight',
    description: 'Pilots Arrived Late From Delayed Flight',
  },
  { value: '08L', label: '08L - Reroute', description: 'Reroute' },
  {
    value: '08M',
    label: '08M - Late Completion Of Checklist',
    description: 'Late Completion Of Checklist',
  },
  {
    value: '08P',
    label: '08P - Late Pilot Action',
    description: 'Late Pilot Action',
  },
  {
    value: '08Q',
    label: '08Q - Irregular Routing Pilot',
    description: 'Irregular Routing Pilot',
  },
  {
    value: '08R',
    label: '08R - FAR Or Crew Rest',
    description: 'FAR Or Crew Rest',
  },
  {
    value: '08W',
    label: '08W - Insufficient Rest Due To Weather/ATC',
    description: 'Insufficient Rest Due To Weather/ATC',
  },
  {
    value: '08X',
    label: '08X - Late Call For Maintenance',
    description: 'Late Call For Maintenance',
  },
  {
    value: '09B',
    label: '09B - Scheduling Error',
    description: 'Scheduling Error',
  },
  { value: '09E', label: '09E - No Show', description: 'No Show' },
  {
    value: '09F',
    label: '09F - Late Sign-In',
    description: 'Late Sign-In',
  },
  { value: '09G', label: '09G - Illness', description: 'Illness' },
  {
    value: '09H',
    label: '09H - Late From Hotel',
    description: 'Late From Hotel',
  },
  {
    value: '09I',
    label: '09I - Insufficient Rest Due Late Ops',
    description: 'Insufficient Rest Due Late Ops',
  },
  {
    value: '09J',
    label: '09J - Arrived Late From Delayed Inbound Flight',
    description: 'Arrived Late From Delayed Inbound Flight',
  },
  {
    value: '09L',
    label: '09L - Crew Reroute',
    description: 'Crew Reroute',
  },
  {
    value: '09M',
    label: '09M - Late Request For Additional Cabin Supplies',
    description: 'Late Request For Additional Cabin Supplies',
  },
  {
    value: '09N',
    label: '09N - Late Request For Additional Catering',
    description: 'Late Request For Additional Catering',
  },
  {
    value: '09O',
    label: '09O - FAR Compliance',
    description: 'FAR Compliance',
  },
  {
    value: '09P',
    label: '09P - FA Requests Delay In Boarding Process',
    description: 'FA Requests Delay In Boarding Process',
  },
  {
    value: '09Q',
    label: '09Q - Irregular Routing Flight Attendant',
    description: 'Irregular Routing Flight Attendant',
  },
  {
    value: '09W',
    label: '09W - Insufficient Rest Due Weather/ATC',
    description: 'Insufficient Rest Due Weather/ATC',
  },
  {
    value: '10A',
    label: '10A - Insufficient Scheduled Ground Time',
    description: 'Insufficient Scheduled Ground Time',
  },
  {
    value: '11A',
    label: '11A - Late Passenger Check In',
    description: 'Late Passenger Check In',
  },
  {
    value: '11F',
    label: '11F - Stay At Gate And Payload Optimized',
    description: 'Stay At Gate And Payload Optimized',
  },
  {
    value: '11G',
    label: '11G - INOP Fuel Gauge',
    description: 'INOP Fuel Gauge',
  },
  {
    value: '11I',
    label: '11I - Station Excess Carry-On Baggage Handling',
    description: 'Station Excess Carry-On Baggage Handling',
  },
  {
    value: '11J',
    label: '11J - Handling Disabled Passengers or UM',
    description: 'Handling Disabled Passengers or UM',
  },
  {
    value: '11K',
    label: '11K - Above Wing Service Failures',
    description: 'Above Wing Service Failures',
  },
  {
    value: '11L',
    label: '11L - Handling Over Sales',
    description: 'Handling Over Sales',
  },
  {
    value: '11M',
    label: '11M - Accommodating Canceled/Delayed Passengers',
    description: 'Accommodating Canceled/Delayed Passengers',
  },
  {
    value: '11O',
    label: '11O - Cabin Service Late Releasing Aircraft',
    description: 'Cabin Service Late Releasing Aircraft',
  },
  {
    value: '11Q',
    label: '11Q - Irregular Routing Station',
    description: 'Irregular Routing Station',
  },
  {
    value: '11R',
    label: '11R - Aircraft Fueling',
    description: 'Aircraft Fueling',
  },
  {
    value: '11S',
    label: '11S - Ramp Failure To Load/Unload In Prescribed Time',
    description: 'Ramp Failure To Load/Unload In Prescribed Time',
  },
  {
    value: '11T',
    label: '11T - Failure To Loan/Unload Timely Due Faulty Onboard System',
    description: 'Failure To Loan/Unload Timely Due Faulty Onboard System',
  },
  {
    value: '11U',
    label: '11U - Ramp Failure To Load/Unload/Closeout Timely',
    description: 'Ramp Failure To Load/Unload/Closeout Timely',
  },
  {
    value: '11X',
    label: '11X - Airport Control Center Decision',
    description: 'Airport Control Center Decision',
  },
  {
    value: '11Z',
    label: '11Z - Inability To Move Or Position Aircraft',
    description: 'Inability To Move Or Position Aircraft',
  },
  {
    value: '12A',
    label: '12A - Awaiting Passenger/Bag - PREDETERMINED',
    description: 'Awaiting Passenger/Bag - PREDETERMINED',
  },
  {
    value: '12C',
    label: '12C - Awaiting Passenger/Bag - LAST MINUTE',
    description: 'Awaiting Passenger/Bag - LAST MINUTE',
  },
  {
    value: '13A',
    label: '13A - Late Or Incomplete Catering',
    description: 'Late Or Incomplete Catering',
  },
  {
    value: '14A',
    label: '14A - Local ATC Delay Awaiting Taxi/Takeoff/Slot Clearance',
    description: 'Local ATC Delay Awaiting Taxi/Takeoff/Slot Clearance',
  },
  {
    value: '14B',
    label: '14B - ATC Delay Program',
    description: 'ATC Delay Program',
  },
  {
    value: '14C',
    label: '14C - Destination ATC Delay',
    description: 'Destination ATC Delay',
  },
  {
    value: '14D',
    label: '14D - Aircraft Blocked At Gate Due Ramp Congestion',
    description: 'Aircraft Blocked At Gate Due Ramp Congestion',
  },
  {
    value: '14Q',
    label: '14Q - Irregular Routing ATC',
    description: 'Irregular Routing ATC',
  },
  {
    value: '15A',
    label: '15A - Customs/Immigration/Agriculture',
    description: 'Customs/Immigration/Agriculture',
  },
  {
    value: '16A',
    label: '16A - Threat/Disruption',
    description: 'Threat/Disruption',
  },
  {
    value: '16B',
    label: '16B - Airline Controlled',
    description: 'Airline Controlled',
  },
  {
    value: '16C',
    label: '16C - Government Controlled',
    description: 'Government Controlled',
  },
  {
    value: '16D',
    label: '16D - Passenger/Baggage Removal From Flight',
    description: 'Passenger/Baggage Removal From Flight',
  },
  {
    value: '16Q',
    label: '16Q - Irregular Routing Security',
    description: 'Irregular Routing Security',
  },
  {
    value: '17A',
    label: '17A - Failure Of Computer Or Communications Systems',
    description: 'Failure Of Computer Or Communications Systems',
  },
  {
    value: '17V',
    label: '17V - Failure Of Communications or Computer Equipment',
    description: 'Failure Of Communications or Computer Equipment',
  },
  {
    value: '18A',
    label: '18A - Found On Arrival',
    description: 'Found On Arrival',
  },
  { value: '18C', label: '18C - Catering', description: 'Catering' },
  { value: '18D', label: '18D - Passenger', description: 'Passenger' },
  {
    value: '18F',
    label: '18F - Fueling Personnel/Equipment',
    description: 'Fueling Personnel/Equipment',
  },
  {
    value: '18I',
    label: '18I - Flight Attnd',
    description: 'Flight Attnd',
  },
  {
    value: '18M',
    label: '18M - Maintenance',
    description: 'Maintenance',
  },
  { value: '18N', label: '18N - Weather', description: 'Weather' },
  { value: '18O', label: '18O - Pilot', description: 'Pilot' },
  {
    value: '18P',
    label: '18P - Above Wing Personnel Or Equipment',
    description: 'Above Wing Personnel Or Equipment',
  },
  {
    value: '18Q',
    label: '18Q - Irregular Routing Aircraft Damage',
    description: 'Irregular Routing Aircraft Damage',
  },
  {
    value: '18R',
    label: '18R - Below Wing Personnel Or Equipment',
    description: 'Below Wing Personnel Or Equipment',
  },
  {
    value: '18S',
    label: '18S - Cabin Service Personnel/Equipment',
    description: 'Cabin Service Personnel/Equipment',
  },
  {
    value: '18T',
    label: '18T - Foreign Object Debris (FOD)',
    description: 'Foreign Object Debris (FOD)',
  },
  {
    value: '19A',
    label: '19A - Passenger Medical Emergency',
    description: 'Passenger Medical Emergency',
  },
  {
    value: '19B',
    label: '19B - Biohazard cleaning',
    description: 'Biohazard cleaning',
  },
  {
    value: '21A',
    label: '21A - Crew Requested - No Change To Final WT Manifest',
    description: 'Crew Requested - No Change To Final WT Manifest',
  },
  {
    value: '96A',
    label: '96A - FAA Staffing Action',
    description: 'FAA Staffing Action',
  },
  { value: '96B', label: '96B - Block', description: 'Block' },
  {
    value: '96C',
    label: '96C - Ramp/Station',
    description: 'Ramp/Station',
  },
  {
    value: '96D',
    label: '96D - Late Arriving Equipment Diversion',
    description: 'Late Arriving Equipment Diversion',
  },
  {
    value: '96F',
    label: '96F - ATC Or Ramp Congestion',
    description: 'ATC Or Ramp Congestion',
  },
  {
    value: '96G',
    label: '96G - Weather/ATC',
    description: 'Weather/ATC',
  },
  {
    value: '96H',
    label: '96H - Maintenance',
    description: 'Maintenance',
  },
  { value: '96I', label: '96I - Pilot', description: 'Pilot' },
  {
    value: '96J',
    label: '96J - Flight Attendant',
    description: 'Flight Attendant',
  },
  { value: '96K', label: '96K - Station', description: 'Station' },
  { value: '96L', label: '96L - Damage', description: 'Damage' },
  {
    value: '96M',
    label: '96M - Flight Control',
    description: 'Flight Control',
  },
  {
    value: '96N',
    label: '96N - Government Forms/Security',
    description: 'Government Forms/Security',
  },
  {
    value: '96O',
    label: '96O - Computer/Communications',
    description: 'Computer/Communications',
  },
  { value: '96P', label: '96P - Customer', description: 'Customer' },
  { value: '96Q', label: '96Q - Marketing', description: 'Marketing' },
  {
    value: '99Q',
    label: '99Q - Irregular Aircraft Routing',
    description: 'Irregular Aircraft Routing',
  },
];

export const LATE_CREW_CODE: DelayCode = {
  value: '06A',
  label: '06A - Upline Block Delay',
  description: 'Upline Block Delay',
};

export const LATE_EQ_CODE: DelayCode = {
  value: '09J',
  label: '09J - Arrived Late From Delayed Inbound Flight',
  description: 'Arrived Late From Delayed Inbound Flight',
};
