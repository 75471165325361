import { createEntityAdapter, EntityState, EntityAdapter } from '@ngrx/entity';
import * as AlertActions from '../../actions/alert/alert.actions';
import { AlertEvent } from '../../../models/alert-event.model';

export interface State extends EntityState<AlertEvent> {
  loading: boolean;
  errMsg: string;
}

export const alertAdapter: EntityAdapter<AlertEvent> = createEntityAdapter<AlertEvent>({
  selectId: alert => alert.createdDateTimeUUID,
  sortComparer: false,
});

const initalState: State = alertAdapter.getInitialState({
  loading: false,
  errMsg: null,
});

export function reducer(state: State = initalState, action: AlertActions.Actions): State {
  switch (action.type) {
    case AlertActions.AlertActionTypes.LOAD_ALERTS:
      return {
        ...state,
        loading: true,
        errMsg: null,
      };
    case AlertActions.AlertActionTypes.LOAD_ALERTS_SUCCESS:
      return alertAdapter.upsertMany(action.payload, {
        ...state,
        loading: false,
        loaded: true,
        error: null,
      });
    case AlertActions.AlertActionTypes.LOAD_ALERTS_FAIL:
      return {
        ...state,
        loading: false,
        errMsg: 'error loading alerts',
      };
    case AlertActions.AlertActionTypes.DELETE_ALERTS:
      return {
        ...state,
        loading: false,
        errMsg: null,
      };
    case AlertActions.AlertActionTypes.DELETE_ALERTS_SUCCESS:
      return {
        ...alertAdapter.removeAll(state),
        loading: false,
        errMsg: null,
      };
    case AlertActions.AlertActionTypes.DELETE_ALERTS_FAIL:
      return {
        ...state,
        loading: false,
        errMsg: 'error deleting alerts',
      };
    case AlertActions.AlertActionTypes.DELETE_ALERT:
      return {
        ...state,
        loading: false,
        errMsg: null,
      };
    case AlertActions.AlertActionTypes.DELETE_ALERT_SUCCESS:
      return {
        ...alertAdapter.removeOne(action.payload.createdDateTimeUUID, state),
        loading: false,
        errMsg: null,
      };
    case AlertActions.AlertActionTypes.DELETE_ALERT_FAIL:
      return {
        ...state,
        loading: false,
        errMsg: 'error deleting alert',
      };
    default:
      return state;
  }
}

export const { selectAll: selectAllAlerts } = alertAdapter.getSelectors();

export const getLoadingStatus = (state: State) => state.loading;

export const getErrorMsg = (state: State) => state.errMsg;
