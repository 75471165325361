import { Directive, Input, Output, EventEmitter } from '@angular/core';
import { SortDirection } from '@angular/material/sort';
import { SortEvent, rotate } from '../utils/report-util';

/* tslint:disable: directive-selector */
@Directive({
  selector: 'th[sortable]',
  host: {
    '[class.asc]': 'direction === "asc"',
    '[class.desc]': 'direction === "desc"',
    '(click)': 'rotate()'
  }
})
export class SortableHeader {

  @Input() sortable: string;
  @Input() direction: SortDirection = '';
  @Output() sort = new EventEmitter<SortEvent>();

  rotate() {
    this.direction = rotate[this.direction];
    this.sort.emit({column: this.sortable, direction: this.direction});
  }
}