import * as SidebarActions from '../../actions/sidebar/sidebar.actions';

export interface State {
  FlightListExpanded: boolean;
  SidebarExpanded: boolean;
}

const initalState: State = {
  FlightListExpanded: false,
  SidebarExpanded: false,
};

export function reducer(state: State = initalState, action: SidebarActions.Actions) {
  switch (action.type) {
    case SidebarActions.TOGGLE_FLIGHT_LIST:
      return Object.assign({}, state, { FlightListExpanded: !state.FlightListExpanded });
    case SidebarActions.EXPAND_FLIGHT_LIST:
      return Object.assign({}, state, { FlightListExpanded: true });
    case SidebarActions.RETRACT_FLIGHT_LIST:
      return Object.assign({}, state, { FlightListExpanded: false });
    case SidebarActions.TOGGLE_SIDEBAR:
      return Object.assign({}, state, { SidebarExpanded: !state.SidebarExpanded });
    case SidebarActions.EXPAND_SIDEBAR:
      return Object.assign({}, state, { SidebarExpanded: true });
    case SidebarActions.RETRACT_SIDEBAR:
      return Object.assign({}, state, { SidebarExpanded: false });
    default:
      return state;
  }
}
