<div class="results-table">
  <table class="table">
    <thead>
      <tr *ngIf="preHeaders && preHeaders.length" class="pre-header-row">
        <ng-container *ngFor="let preHeader of preHeaders">
          <th class="sortable-header" [colSpan]="preHeader.span">{{ preHeader.title }}</th>
        </ng-container>
        <th *ngIf="isCollapsible"></th>
      </tr>
      <tr>
        <ng-container *ngFor="let header of headers">
          <th class="sortable-header">{{ header.title }}</th>
        </ng-container>
        <th *ngIf="isCollapsible"></th>
      </tr>
    </thead>
    <tbody *ngIf="!isDataBlank" id="test">
      <ng-container *ngFor="let cell of body; let i = index">
        <tr>
          <ng-container *ngFor="let header of headers">
            <td
              id="emt-table-body-row"
              [ngClass]="{
                'collapsable-cell': toggledCell === i,
                link: cell | validateTableCellClass: header
              }"
              (click)="onCellClicked(header, cell)"
            >
              {{ cell | validateTableCellInput: header }}
            </td>
          </ng-container>
          <ng-container *ngIf="isCollapsible">
            <td
              [ngClass]="toggledCell === i && 'collapsable-cell'"
              *ngIf="cell | validateTableCellCollapsibility: isCollapsible:collapsibleKey; else showFillerCell"
            >
              <emt-delta-icon class="icon-arrow" [flip]="toggledCell === i" iconName="icon-arrow" (click)="toggleCell(i)"></emt-delta-icon>
            </td>
            <ng-template #showFillerCell>
              <td id="filler-cell"></td>
            </ng-template>
          </ng-container>
        </tr>
        <ng-container *ngIf="toggledCell === i">
          <ng-container *ngFor="let dropdownCell of cell[collapsibleKey]">
            <tr class="collpasable-row">
              <ng-container *ngFor="let header of headers">
                <td [ngClass]="{ 'collapsable-cell': toggledCell === i }">
                  {{ dropdownCell | validateTableCellInput: header }}
                </td>
              </ng-container>
              <td class="collapsable-cell"></td>
            </tr>
          </ng-container>
        </ng-container>
      </ng-container>
    </tbody>
  </table>
  <ng-container *ngIf="isDataBlank && !customTableMsg">
    <p class="no-data-msg" id="no-data-available-msg">NO DATA AVAILABLE</p>
  </ng-container>
  <ng-container *ngIf="isDataBlank && customTableMsg">
    <p class="no-data-msg" id="custom-table-msg">{{ customTableMsg }}</p>
  </ng-container>
</div>
