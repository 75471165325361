
<div *ngIf="loadingRecords" class="d-flex justify-content-center loader">
  <div class="loader-container" style="text-align: center;">
    <div class="spinner-border"></div>
    <div>
      <p>Loading Data...</p>
    </div>
  </div>
</div>

<table class="advanced-report-template-table">
  <thead>
    <tr class="header-row" *ngIf="tableHeaders && tableHeaders.columns">
      <th class="table-header" [sortable]="header.column" (sort)="onSort($event)" *ngFor="let header of tableHeaders.columns">
        <div class="column-wrapper">
          <div class="header-name">
            <span>
              {{ header.column.replace('_', '') }}
            </span>
            <span class="sort-indicator" [ngSwitch]="sortEvent.direction" *ngIf="sortEvent && sortEvent.column === header.column">
              <p *ngSwitchCase="'asc'">
                ↑
              </p>
              <p *ngSwitchCase="'desc'">
                ↓
              </p>
            </span>
          </div>
        </div>
      </th>
    </tr>
  </thead>
  <tbody>
    <tr class="table-row" *ngFor="let row of data" (click)="onRowClick(row)">
      <td class="table-column" *ngFor="let header of tableHeaders.columns" [ngStyle]="getStyles(header)">
        {{ row[header.column] }}
      </td>
    </tr>
    <tr *ngIf="!data || data.length === 0">
      <td class="no-data-found" colspan="100">{{ errorText ? errorText : 'No Data Found' }}</td>
    </tr>
  </tbody>
</table>
