import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Airport } from '../../../models/airport.model';
import { NavItem } from '../../../models/nav-item.model';

@Component({
  selector: 'emt-navigation-item',
  templateUrl: './navigation-item.component.html',
  styleUrls: ['./navigation-item.component.scss'],
})
export class NavigationItemComponent implements OnInit {
  @Input() item: NavItem;
  @Input() activePath: string;
  @Input() flightListExpanded: boolean;
  @Input() stations: Airport[];
  @Output() itemSelected = new EventEmitter();
  public expanded: boolean;

  constructor() {}

  ngOnInit() {}

  onItemSelected(item: NavItem) {
    if (!item.externalLink && item.children && item.children.length) {
      this.expanded = !this.expanded;
    } else {
      this.itemSelected.emit(item);
    }
  }
}
