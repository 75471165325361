import { Pipe, PipeTransform } from '@angular/core';
import { AlertEvent } from '../models/alert-event.model';

@Pipe({
  name: 'alertSeverity',
})
export class AlertSeverityPipe implements PipeTransform {
  transform(items: AlertEvent[], severity: string): AlertEvent[] {
    if (!items) return [];
    if (!severity) return items;

    return items.filter(alert => alert.severity.toLowerCase() === severity.toLowerCase());
  }
}
