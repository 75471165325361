import { Injectable } from '@angular/core';
import { ConfigService } from '../../../services/config/config.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Subject, BehaviorSubject } from 'rxjs';
import * as moment_ from 'moment';
const moment = moment_;
import { AlertService, SearchResults } from 'projects/common/src/public_api';
import * as _ from 'lodash';
import { SortParams } from '../../../models/advanced-search/sort-params.model';
import { SortParamsUpdate } from '../../../models/advanced-search/sort-params-update.model';
import { Params } from '@angular/router';
import { CarrierList } from '../models/carriers.model';

@Injectable({
  providedIn: 'root',
})
export class AdvancedSearchService {
  public searchResults: Subject<SearchResults> = new Subject<SearchResults>();
  public fetchError: Subject<boolean> = new Subject<boolean>();
  public operators = new BehaviorSubject<CarrierList>({ carriers: [], defaults: [] });
  public flightStatuses = new BehaviorSubject<any[]>(null);
  public fleets = new BehaviorSubject<any[]>(null);
  public flightTypeCodes = new BehaviorSubject<any[]>(null);
  public resultsCache: SearchResultsCache[] = [];
  public maxResultsCache = 4;
  public sortParams: Subject<SortParamsUpdate> = new Subject<SortParamsUpdate>();
  public selectedPageNum = 1;

  constructor(private http: HttpClient, private configService: ConfigService, private alertService: AlertService) {}

  public getSearchResults(payload) {
    const cachedAdvSearchResults = this.getResultsFromCache(payload);

    if (cachedAdvSearchResults) {
      console.log('--- Retrieving adv search results from local cache ---');
      this.searchResults.next(cachedAdvSearchResults);
    } else {
      const baseUrl = `${this.configService.getSettings('flightSuite')}`;
      const getSearchResultsParams = {};

      const searchResultsConfig = this.configService.getSettings('search-results');

      if (searchResultsConfig && searchResultsConfig.limit) {
        getSearchResultsParams['limit'] = searchResultsConfig.limit;
      }

      if (payload.callSign) {
        getSearchResultsParams['callSign'] = payload.callSign;
      }
      if (payload.currentFlightStatus) {
        // Array[String]
        getSearchResultsParams['currentFlightStatus'] = payload.currentFlightStatus;
      }
      if (payload.destinationStation) {
        // Array[String]
        getSearchResultsParams['destinationStation'] = payload.destinationStation;
      }
      if (payload.dispatcherId) {
        getSearchResultsParams['dispatcherId'] = payload.dispatcherId;
      }
      if (payload.fleet) {
        // Array[String]
        getSearchResultsParams['fleet'] = payload.fleet;
      }
      if (payload.flightNo) {
        getSearchResultsParams['flightNumber'] = payload.flightNo;
      }
      if (payload.flightType) {
        // Array[String]
        getSearchResultsParams['flightType'] = payload.flightType;
      }
      if (payload.intlFlightIndicator) {
        getSearchResultsParams['intlFlightIndicator'] = payload.intlFlightIndicator;
      }
      if (payload.loadPlannerId) {
        getSearchResultsParams['loadPlannerId'] = payload.loadPlannerId;
      }
      if (payload.operator) {
        // Array[String]
        getSearchResultsParams['operator'] = payload.operator;
      }
      if (payload.originDateFrom) {
        getSearchResultsParams['originStartDate'] = moment(payload.originDateFrom).format('MM/DD/YY');
      }
      if (payload.endTime) {
        getSearchResultsParams['originEndTime'] = this.formatTimeInput(payload.endTime);
      }
      if (payload.originDateTo) {
        getSearchResultsParams['originEndDate'] = moment(payload.originDateTo).format('MM/DD/YY');
      }
      if (payload.startTime) {
        getSearchResultsParams['originStartTime'] = this.formatTimeInput(payload.startTime);
      }
      if (payload.originStation) {
        // Array[String]
        getSearchResultsParams['originStation'] = payload.originStation;
      }
      if (payload.shipNo) {
        getSearchResultsParams['shipNumber'] = payload.shipNo;
      }
      if (payload.tbfmTime) {
        getSearchResultsParams['tbfmTime'] = payload.tbfmTime;
      }
      if (payload.timeDisplayIn) {
        getSearchResultsParams['timeDisplayIn'] = payload.timeDisplayIn;
      }
      if (payload.originDestination) {
        getSearchResultsParams['originDestination'] = payload.originDestination;
      }
      if (payload.display) {
        getSearchResultsParams['display'] = payload.display;
      }
      if (payload.alternate) {
        getSearchResultsParams['alternate'] = payload.alternate;
      }
      if (payload.isAllAlternateSelected) {
        getSearchResultsParams['isAllAlternateSelected'] = payload.isAllAlternateSelected;
      }
      if (payload.registration) {
        getSearchResultsParams['registration'] = payload.registration;
      }
      this.http
        .get(`${baseUrl}advanced`, {
          params: getSearchResultsParams,
        })
        .subscribe(
          (data: SearchResults) => {
            if (data && data.flightLegs) {
              data.lastUpdated = moment();
              this.cacheResults(payload, data);
              this.searchResults.next(data);
              if (searchResultsConfig && searchResultsConfig.limit && data.flightLegs.length >= searchResultsConfig.limit) {
                this.alertService.handleAlert(
                  'Results limit reached. Some flights could be excluded from your results.  Refine your search to return fewer results.',
                  ['OK']
                );
              }
            }
          },
          error => {
            this.fetchError.next(true);
            let errorMessage = 'An error has occurred.';
            if (error instanceof HttpErrorResponse) {
              errorMessage = (error as HttpErrorResponse).message;
            } else {
              errorMessage = error as string;
            }
            this.alertService.handleAlert(errorMessage, ['OK']);
          }
        );
    }
  }

  public loadOperators() {
    const baseUrl = `${this.configService.getSettings('flightSuite')}`;
    this.http.get(baseUrl + 'carriers/completeWithDefaults').subscribe((data: CarrierList) => {
      this.operators.next(data);
    });
  }

  public saveSearchResultsPageNumber(pageNum: number) {
    this.selectedPageNum = pageNum;
  }

  public getSearchResultsPageNumber() {
    return this.selectedPageNum;
  }

  public loadFlightStatuses() {
    const baseUrl = `${this.configService.getSettings('flightSuite')}`;
    this.http
      .get(baseUrl + 'flightStatuses')
      .pipe(
        map((flightStatuses: any) => {
          return flightStatuses.values.map((flightStatus, index) => {
            return {
              name: flightStatus,
              id: flightStatus,
              value: flightStatus,
            };
          });
        })
      )
      .subscribe(data => {
        this.flightStatuses.next(data);
      });
  }
  public loadFleets() {
    const baseUrl = `${this.configService.getSettings('flightSuite')}`;
    return this.http
      .get(baseUrl + 'fleets')
      .pipe(
        map((fleets: { values: Array<string> }) => {
          return fleets.values.map((fleet, index) => {
            return {
              name: fleet,
              id: index,
              value: fleet,
            };
          });
        })
      )
      .subscribe(data => {
        this.fleets.next(data);
      });
  }

  public loadFlightTypeCodes() {
    const baseUrl = `${this.configService.getSettings('flightSuite')}`;
    return this.http.get(baseUrl + 'typeCodes').subscribe((data: {flightTypeCodes: Array<{value: string, name: string}>}) => {
      this.flightTypeCodes.next(data.flightTypeCodes);
    });
  }

  public formatTimeInput(value: string) {
    return value && value.includes(':') ? value : `${value.substr(0, 2)}:${value.substr(2, 4)}`;
  }

  private getResultsFromCache(queryParams: any): any {
    for (let entry of this.resultsCache) {
      let params = entry.params;
      if (_.isEqual(params, queryParams)) {
        return entry.results;
      }
    }
    return null;
  }

  public broadcastParamChange(routeParams: Params, sortParams?: SortParams) {
    let paramMap = {};
    Object.keys(routeParams).forEach(key => (paramMap[key] = routeParams[key]));
    if (sortParams) {
      paramMap['sort'] = sortParams.prop2 ? `${sortParams.prop1},${sortParams.prop2}` : sortParams.prop1;
      paramMap['numericSort'] = sortParams.numericSort;
      paramMap['sortOrder'] = sortParams.sortOrder;
    }
    this.sortParams.next(new SortParamsUpdate(sortParams, paramMap));
  }

  private cacheResults(params: any, results: SearchResults) {
    console.log('--- Caching adv search results ---');
    if (this.resultsCache.length >= this.maxResultsCache) {
      // Sort the results cache so the oldest results are last
      this.resultsCache.sort((a, b) => b.date.getTime() - a.date.getTime());
      this.resultsCache.pop();
    }
    this.resultsCache.push({ results: results, date: new Date(), params: params });
  }

  public clearCache(queryParams: any) {
    if (queryParams) {
      this.resultsCache = this.resultsCache.filter(entry => {
        if (_.isEqual(entry.params, queryParams)) {
          return false;
        } else {
          return true;
        }
      });
    } else {
      this.resultsCache = [];
    }
  }
}

export class SearchResultsCache {
  params: any;
  date: Date;
  results: SearchResults;
}
