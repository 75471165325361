import { createSelector } from '@ngrx/store';
import * as fromReducer from '../../reducers/station/station.reducers';
import { AppState } from '../../index';

const selectStation = (state: AppState) => state.stations;

export const featureSelector = createSelector(
  selectStation,
  (state: fromReducer.State) => state
);

export const getStationsLoaded = createSelector(
  featureSelector,
  fromReducer.getStationsLoaded
);

export const getStationsLoading = createSelector(
  featureSelector,
  fromReducer.getStationsLoading
);

export const getStationEntities = createSelector(
  featureSelector,
  fromReducer.selectStationEntities
);

export const getAllStations = createSelector(
  getStationEntities,
  entities => {
    return Object.keys(entities).map(key => entities[key]);
  }
);
