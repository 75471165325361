import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertModalComponent } from '../../components/alert-modal/alert-modal.component';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  constructor(private modalService: NgbModal) {}

  public handleAlert(message: string, actions: string[], options?: any) {
    if(this.modalService.hasOpenModals()){
      this.modalService.dismissAll();
    }
    const modalRef = this.modalService.open(AlertModalComponent);
    modalRef.componentInstance.actions = actions;
    modalRef.componentInstance.message = message;
    return modalRef;
  }
}
