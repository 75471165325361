<div class="header">
  <div class="left-nav">
    <emt-delta-icon class="icon-hamburger" iconName="icon-hamburger" (click)="toggleSideNav()"></emt-delta-icon>
    <span class="title">EMT</span>
  </div>
  <div class="alert-bar">
    <emt-alert-bar></emt-alert-bar>
  </div>
  <div class="right-nav">
    <emt-search-input></emt-search-input>
    <div class="user" [ngClass]="accountDropdownOpen ? 'open' : ''" (click)="accountDropdownOpen = !accountDropdownOpen">
      <emt-delta-icon class="icon-person" iconName="icon-person" [fill]="!accountDropdownOpen && 'white'"></emt-delta-icon>
      <div>
        <p class="name">
          <span class="last-name">{{ getLastName() }}</span>
          <span class="first-name">{{ getFirstName() }}</span>
        </p>
        <p class="role">
          <span class="role-name">{{ getRoleName(userRole) }}</span>
        </p>
      </div>
      <emt-delta-icon class="icon-arrow" iconName="icon-arrow" [fill]="!accountDropdownOpen && 'white'"></emt-delta-icon>
      <div class="account-dropdown" *ngIf="accountDropdownOpen">
        <div class="option">
          <emt-delta-icon class="icon" iconName="icon-settings"></emt-delta-icon>
          Settings
        </div>
        <div class="option" (click)="logoutModal = true">
          <emt-delta-icon class="icon" iconName="icon-logOut"></emt-delta-icon>
          Log Out
        </div>
      </div>
    </div>
    <span class="time">{{ time }}</span>
  </div>
  <emt-logout-modal *ngIf="logoutModal" (closeLogout)="logoutModal = false"></emt-logout-modal>
</div>
