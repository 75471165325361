import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ConfigService } from '../../services/config/config.service';
import { AuthService } from '../../services/auth/auth.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { SelectRoleCallbackComponent } from '../../components/select-role-callback/select-role-callback.component';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild {
  private authorizationRouteMap = {
    'flight-info': 'flightList',
    'flight-list': 'flightList',
    capacity: 'gateCapacity',
    deice: 'deice',
    throughput: 'stationThroughput',
    'advanced-search': 'advancedSearch',
    'policy-engine': 'policyEngine',
    cancellations: 'cancellation',
    'integrated-crew-hub': 'integratedCrewHub',
    'irop-replay': 'iropReplay',
    'jv-gates': 'jvGates',
    'severe-weather-index': 'severeWeatherIndex',
    'air-traffic-control': 'airTrafficControl',
    diversionManagement: 'diversionManagement',
    'operational-codes': 'operationalCodes'
  };

  constructor(
    private authService: AuthService,
    private router: Router,
    private configService: ConfigService,
    private modalService: NgbModal
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    return this.authService.isLoggedIn().then(loggedIn => {
      if (loggedIn) {
        if (route.queryParams['Role']) {
          const vipsRole = route.queryParams['Role'];
          return this.authService.getAuthorization(null, this.formatVipsRole(vipsRole)).then(byPassResult => {
            if (byPassResult && byPassResult.sub) {
              this.setAuth(byPassResult, route);
            } else {
              let modalRef = this.modalService.open(SelectRoleCallbackComponent, { centered: true, beforeDismiss: () => false });

              modalRef.result.then(
                selectedRole => {
                  return this.authService.getAuthorization(selectedRole).then(secondResult => {
                    if (secondResult && secondResult.sub) {
                      this.setAuth(secondResult, route);
                    }
                  });
                },
                reason => {
                  console.log('dismissed with reason', this.getDismissReason(reason));
                }
              );
            }
            return true;
          });
        }
        else if (route.queryParams['selectedRole']) {
          const selectedRoleParam = route.queryParams['selectedRole'];
          return this.authService.getAuthorization(selectedRoleParam).then(byPassResult => {
            if (byPassResult && byPassResult.sub) {
              this.setAuth(byPassResult, route);
            }

            return true;
          });
        } else {
          return this.authService.getAuthorization().then(result => {
            if (result && result.sub) {
              this.setAuth(result, route);
            } else {
              let modalRef = this.modalService.open(SelectRoleCallbackComponent, { centered: true, beforeDismiss: () => false });

              modalRef.result.then(
                selectedRole => {
                  return this.authService.getAuthorization(selectedRole).then(secondResult => {
                    if (secondResult && secondResult.sub) {
                      this.setAuth(secondResult, route);
                    }
                  });
                },
                reason => {
                  console.log('dismissed with reason', this.getDismissReason(reason));
                }
              );
            }
            // TODO: always return true right now because Authentication is mocked in BFF
            return true;
          });
        }
      } else {
        // We are not currently authenticated or bypassing auth,
        //  so start that process...

        // Save the location requiring auth that we were trying to access
        // so we can return to it later
        // NOTE: Have to use localStorage instead of sessionStorage since IE11 does
        //       not appear to persist sessionStorage across redirects, so when the
        //       OIDC flow returns to /auth-callback, sessionStorage is empty
        const preURLwithouthost = window.location.href.split(window.location.host)[1];
        const postURLwithouthost = state.url;
        localStorage.setItem(this.configService.getSettings('preAuthPageSessionKey'), preURLwithouthost);
        localStorage.setItem(this.configService.getSettings('postAuthPageSessionKey'), postURLwithouthost);
        localStorage.setItem(this.configService.getSettings('preAuthTypeKey'), AuthService.preAuthTypeKeyFull);

        console.log('User not logged in - beginning auth process...');
        this.authService.startAuthentication();
        return false;
      }
    });
  }

  setAuth(result, route) {
    const authMap = JSON.parse(result.sub);
    const path = route.url && route.url[0] && route.url[0].path ? route.url[0].path : '';
    const authKey = this.authorizationRouteMap[path];
    if (authMap && authKey) {
      const access: boolean = authMap[authKey].access;
      console.log(`Authorized for ${authKey}:`, access);
      return access;
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    return this.canActivate(route, state);
  }

  private formatVipsRole(vipsRole: string): string {
    let formattedVipsRole = vipsRole;
    if (vipsRole.slice(vipsRole.length - 1) === '$') {
      formattedVipsRole = vipsRole.slice(0, -1)
    }
    return formattedVipsRole;
  }
}
