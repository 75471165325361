import { createSelector } from '@ngrx/store';
import * as fromReducer from '../../reducers/auth/auth.reducers';
import { AppState } from '../../index';

const selectAuth = (state: AppState) => state.authState;

export const featureSelector = createSelector(
  selectAuth,
  (state: fromReducer.State) => state
);

export const getUser = createSelector(
  featureSelector,
  fromReducer.getUser
);