import { PipeTransform, Pipe } from '@angular/core';

@Pipe({ name: 'decodeHtmlString' })
export class DecodeHtmlStringPipe implements PipeTransform {
  transform(value: string | number) {
    const tempElement = document.createElement('div');
    tempElement.innerHTML = value.toString();
    return tempElement.innerText;
  }
}
