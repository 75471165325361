import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppState } from '../../../store';
import { AlertEvent } from '../../../models/alert-event.model';
import { selectAllAlerts } from '../../../store/selectors/alert/alert.selectors';
import { Observable, Subscription } from 'rxjs';
import { AlertSeverityPipe } from '../../../pipes/alert-severity.pipe';
import * as moment_ from 'moment';
import { AlertEventService } from 'projects/common/src/public_api';
import { finalize } from 'rxjs/operators';
import { AuthService } from '../../../services/auth/auth.service';
const moment = moment_;

@Component({
  selector: 'emt-alert-bar',
  templateUrl: './alert-bar.component.html',
  styleUrls: ['./alert-bar.component.scss'],
})
export class AlertBarComponent implements OnInit, OnDestroy {
  alerts$: Observable<AlertEvent[]>;
  alertsDropdownOpen = false;
  alertExpansionOpen = false;
  alertSubscription: Subscription;
  sortedAlerts: Array<AlertEvent> = [];
  currentAlertIndex = 0;
  currentAlert: AlertEvent;
  archivingAlert: boolean;

  constructor(
    private store: Store<AppState>,
    private severityPipe: AlertSeverityPipe,
    private alertEventService: AlertEventService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.alerts$ = this.store.pipe(select(selectAllAlerts));
    this.alertSubscription = this.alerts$.subscribe(alerts => {
      this.setupAlerts(alerts);
    });
  }

  ngOnDestroy() {
    if (this.alertSubscription) {
      this.alertSubscription.unsubscribe();
    }
  }

  setupAlerts(alerts: AlertEvent[]) {
    this.sortedAlerts = [];
    const criticalAlerts = this.severityPipe
      .transform(alerts, 'critical')
      .sort((a, b) => moment(a.createDateTime).valueOf() - moment(b.createDateTime).valueOf());
    const highAlerts = this.severityPipe
      .transform(alerts, 'high')
      .sort((a, b) => moment(a.createDateTime).valueOf() - moment(b.createDateTime).valueOf());
    const mediumAlerts = this.severityPipe
      .transform(alerts, 'medium')
      .sort((a, b) => moment(a.createDateTime).valueOf() - moment(b.createDateTime).valueOf());
    this.sortedAlerts = this.sortedAlerts.concat(criticalAlerts);
    this.sortedAlerts = this.sortedAlerts.concat(highAlerts);
    this.sortedAlerts = this.sortedAlerts.concat(mediumAlerts);
    // Filter out any alerts that have been acknowleged by us
    const fullUsername = this.authService.getFullUsername();
    this.sortedAlerts = this.sortedAlerts.filter(alert => {
      // If the alert doesn't have an acknowledgedBy, it stays
      if (alert.acknowledgedBy) {
        // Only keep the alert if our username is not found
        return alert.acknowledgedBy.filter(item => item.userId === fullUsername).length === 0;
      }
      return true;
    });

    // If the current index is beyond the end of the list, reset to the beginning
    if (this.currentAlertIndex >= this.sortedAlerts.length) {
      this.currentAlertIndex = 0;
    }

    if (this.sortedAlerts.length > 0) {
      // If a current alert was saved, find it and move the index to it. If we don't
      // find it, set it back to zero
      if (this.currentAlert) {
        const foundIndex = this.sortedAlerts.findIndex(alert => alert.createdDateTimeUUID === this.currentAlert.createdDateTimeUUID);
        if (foundIndex >= 0) {
          this.currentAlertIndex = foundIndex;
        } else {
          this.currentAlertIndex = 0;
        }
      }
      this.currentAlert = this.sortedAlerts[this.currentAlertIndex];
    } else {
      this.currentAlert = null;
    }
  }

  nextAlert() {
    if (this.sortedAlerts.length > 1) {
      // If we get to the end of the list, go back to the beginning
      if (this.currentAlertIndex === this.sortedAlerts.length - 1) {
        this.currentAlertIndex = 0;
      } else {
        this.currentAlertIndex++;
      }
      this.currentAlert = this.sortedAlerts[this.currentAlertIndex];
    }
  }

  archiveAlert(alertEvent: AlertEvent) {
    this.archivingAlert = true;
    this.alertEventService
      .archiveAlert(alertEvent)
      .pipe(
        finalize(() => {
          this.archivingAlert = false;
        })
      )
      .subscribe(data => {
        if (this.sortedAlerts.length > 1) {
          this.nextAlert();
          // Manually remove the alert from the list. We tried re-fetching the alerts
          // from the BFF, but there is a slight delay and the alert didn't come back
          // as "acknowledged" immediately
          this.sortedAlerts = this.sortedAlerts.filter(alert => alert.createdDateTimeUUID !== alertEvent.createdDateTimeUUID);
        } else {
          // If we archive the last item, remove everything.
          this.sortedAlerts = [];
          this.currentAlert = null;
          this.currentAlertIndex = 0;
        }
      });
  }
}
