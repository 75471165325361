import { Component, OnInit, Input, OnChanges, Output, EventEmitter, ViewChildren, QueryList } from '@angular/core';
import { SortEvent, SortDirection, compare } from '../../utils/report-util';
import { SortableHeader } from '../../directives/sortable-header.directive';

@Component({
  selector: 'emt-default-report-template',
  templateUrl: './default-report-template.component.html',
  styleUrls: ['./default-report-template.component.scss'],
})
export class DefaultReportTemplateComponent implements OnInit, OnChanges {
  @Input() data: any;
  @Input() sortable: string;
  @Input() direction: SortDirection = '';

  @Output() sort = new EventEmitter<SortEvent>();

  @ViewChildren(SortableHeader) headers: QueryList<SortableHeader>;

  public columns: any;
  public sortEvent: SortEvent;

  private row: any;
  private rawData: [];

  constructor() {}

  ngOnInit() {
    this.loadData();
  }

  ngOnChanges(): void {
    this.loadData();
  }

  private loadData() {
    if (this.data) {
      this.prepareColumn();
      if (this.sortEvent) {
        this.onSort(this.sortEvent);
      }
    }
  }

  private prepareColumn() {
    this.row = this.data && this.data.length ? this.data[0] : undefined;
    if (this.row) {
      this.columns = Object.keys(this.row);
    }
    this.rawData = this.data;
  }

  public onSort({ column, direction }: SortEvent) {
    this.sortEvent = { column, direction };
    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    // sorting countries
    if (direction === '') {
      this.data = this.rawData;
    } else {
      this.data = this.rawData.sort((a, b) => {
        const res = compare(a[column], b[column]);
        return direction === 'asc' ? res : -res;
      });
    }
  }
}
