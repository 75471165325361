<div class="alert-bar-view">
  <div class="colored-bar" *ngIf="currentAlert">
    <div class="preview" [ngClass]="currentAlert?.severity.toLowerCase()">
      <span>{{ currentAlert?.subject }}</span>
      <span
        class="details-button"
        [ngClass]="currentAlert?.severity.toLowerCase()"
        *ngIf="currentAlert"
        (click)="alertExpansionOpen = !alertExpansionOpen"
      >
        {{ alertExpansionOpen ? 'Hide Details' : 'See Details' }}
      </span>
    </div>
    <div class="archive-button" [ngClass]="currentAlert?.severity.toLowerCase()" (click)="archivingAlert || archiveAlert(currentAlert)">
      <emt-delta-icon iconName="archive" [width]="20" [height]="20"></emt-delta-icon>
    </div>
    <div class="arrow-button" [ngClass]="currentAlert?.severity.toLowerCase()" (click)="nextAlert()">&rsaquo;</div>
    <div class="critical count">{{ (sortedAlerts | alertSeverity: 'critical').length }}</div>
    <div class="high count">{{ (sortedAlerts | alertSeverity: 'high').length }}</div>
    <div class="medium count">{{ (sortedAlerts | alertSeverity: 'medium').length }}</div>
    <div class="expanded-view" [hidden]="!alertExpansionOpen" [ngClass]="currentAlert?.severity.toLowerCase()">
      <div class="details">
        <div class="column-left">
          <div class="detail-row">
            <div class="date">
              <span>Date:</span>
              {{ currentAlert?.createDateTime | momentUtc: 'MM/DD/YY' }}
            </div>
          </div>
          <div class="detail-row">
            <div class="time">
              <span>Time:</span>
              {{ currentAlert?.createDateTime | momentUtc: 'HH:mm' }}z
            </div>
          </div>
          <div class="detail-row">
            <div class="flight">
              <span>Flight Number:</span>
            </div>
          </div>
        </div>
        <div class="column-right">
          <div class="detail-row">
            <div class="cities">
              <span>City Pairs:</span>
            </div>
          </div>
          <div class="detail-row">
            <div class="category">
              <span>Category:</span>
              {{ currentAlert?.category }}
            </div>
          </div>
          <div class="detail-row">
            <div class="type">
              <span>Alert Type:</span>
              {{ currentAlert?.type }}
            </div>
          </div>
        </div>
        <div class="detail-row description">
          {{ currentAlert?.message }}
        </div>
      </div>
      <div class="right-bar" [ngClass]="currentAlert?.severity.toLowerCase()">
        <div class="list-button"><emt-delta-icon iconName="bullet-list"></emt-delta-icon></div>
        <div class="folders-button"><emt-delta-icon iconName="folders" [width]="24" [height]="24"></emt-delta-icon></div>
      </div>
    </div>
  </div>
  <div class="alerts" [ngClass]="alertsDropdownOpen ? 'open' : ''" (click)="alertsDropdownOpen = !alertsDropdownOpen">
    <emt-delta-icon
      class="icon-bell"
      iconName="icon-bell"
      [height]="29"
      [width]="29"
      [fill]="alertsDropdownOpen && 'black'"
    ></emt-delta-icon>
    <span class="alert-count">{{ sortedAlerts.length }}</span>
    <emt-alerts-list class="alerts-container" *ngIf="alertsDropdownOpen"></emt-alerts-list>
  </div>
</div>
