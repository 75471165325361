import { Injectable } from '@angular/core';
import {Observable,Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
/*
This class will exchange the data
 And notify the creation of tabs. 
*/

export class DataService {
private _notifyTabCreated:Subject<any>=new Subject<any>();

public setNotifyTabCreated(tab:any):any{
this._notifyTabCreated.next(tab);
}

public getNotifyTabCreated(): Observable<any>{
  return this._notifyTabCreated.asObservable();
}

  constructor() { }
}
