import { createSelector } from '@ngrx/store';

import { AppState } from '../..';
import * as fromAlert from '../../reducers/alert/alert.reducer';

const selectAlert = (state: AppState) => state.alertState;

export const selectAllAlerts = createSelector(
  selectAlert,
  fromAlert.selectAllAlerts
);

export const selectAlertLoadingStatus = createSelector(
  selectAlert,
  fromAlert.getLoadingStatus
);

export const selectAlertErrorMsg = createSelector(
  selectAlert,
  fromAlert.getErrorMsg
);
