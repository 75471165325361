<div class="header">
  <h4>
    Success!
  </h4>
</div>
<h6>You have successfully updated flight {{ carrierCode }}{{ flightNum }}.</h6>
<div class="controls">
  <button type="button" class="btn btn-primary" (click)="editAnother(true)">Edit Another</button>
  <button type="button" class="btn btn-secondary" (click)="editAnother(false)">Close</button>
</div>
