import { QuickEditFormModel } from './quick-edit-form.model';
import { FlightLeg } from '../../feature-modules/flight-info/models';
import { FlightMovementEventTimes, FlightMovementEventTimesClass } from 'projects/common/src/lib/models/flight-movement-event-times';

class QuickEditTimes {
  estimatedTimes: FlightMovementEventTimes = new FlightMovementEventTimesClass();
  actualTimes: FlightMovementEventTimes = new FlightMovementEventTimesClass();
}

export class QuickEditRequest {
  flightId: string;
  originalTimes: QuickEditTimes = new QuickEditTimes();
  newTimes: QuickEditTimes = new QuickEditTimes();
  comments: string;
  private dateTimeFormat = 'YYYY-MM-DD[T]HH:mm:ss[Z]';

  constructor(estimatedOut,estimatedIn, estimatedOff, comments, flightLeg: FlightLeg) {
    this.flightId = flightLeg.flightId;
    this.setNewTimes(estimatedIn, estimatedOut, estimatedOff);
    this.setOriginalTimes(flightLeg);
    this.comments = comments;
  }

  private setNewTimes(estimatedIn, estimatedOut, estimatedOff) {
    this.newTimes.estimatedTimes.outEventUtcTs = estimatedOut ? estimatedOut.format(this.dateTimeFormat) : null;
    this.newTimes.estimatedTimes.inEventUtcTs = estimatedIn ? estimatedIn.format(this.dateTimeFormat) : null;
    this.newTimes.estimatedTimes.offEventUtcTs = estimatedOff ? estimatedOff.format(this.dateTimeFormat) : null;
  }

  private setOriginalTimes(flightLeg: FlightLeg) {
    if (flightLeg.estimatedFlightMovementEventTimes) {
      this.originalTimes.estimatedTimes.inEventUtcTs = flightLeg.estimatedFlightMovementEventTimes.inEventUtcTs;
      this.originalTimes.estimatedTimes.outEventUtcTs = flightLeg.estimatedFlightMovementEventTimes.outEventUtcTs;
      this.originalTimes.estimatedTimes.offEventUtcTs = flightLeg.estimatedFlightMovementEventTimes.offEventUtcTs;
    }
    if (flightLeg.actualFlightMovementEventTimes) {
      this.originalTimes.actualTimes.inEventUtcTs = flightLeg.actualFlightMovementEventTimes.inEventUtcTs;
      this.originalTimes.actualTimes.offEventUtcTs = flightLeg.actualFlightMovementEventTimes.offEventUtcTs;
      this.originalTimes.actualTimes.outEventUtcTs = flightLeg.actualFlightMovementEventTimes.outEventUtcTs;
    }
  }
}
