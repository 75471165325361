import { Pipe, PipeTransform } from '@angular/core';
import { Headers } from '../models/table.model';

@Pipe({ name: 'validateTableCellClass' })
export class ValidateTableCellClassPipe implements PipeTransform {
  transform(cell: object, header: Headers): boolean {
    if (header.isLink && header.key && cell[header.key] !== null && cell[header.key] !== undefined && cell[header.key] !== '-') {
      return true;
    } else {
      return false;
    }
  }
}
