<div class="quick-edit-dialog">
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="header">
    <h3>Quick Edit</h3>
  </div>
  <emt-quick-edit-success
    *ngIf="submitSuccess"
    [flightNum]="flightLeg.value.flightNum"
    [carrierCode]="flightLeg.value.operatingCarrierCode"
    (another)="handleQuickEditResponse($event)"
  ></emt-quick-edit-success>
  <div *ngIf="!submitSuccess">
    <div class="header">
      <h4>Enter the flight number to get the most recent times.</h4>
    </div>
    <div class="row flight-search">
      <div class="flight-leg-info col-md-3">
        <h4 [class.invalid]="((searchInput.value === '' || searchInput.value === null) && formDir.submitted) || flightNotFound">
          Flight No*
        </h4>
        <form [formGroup]="searchForm">
          <input
            autofocus
            type="text"
            id="flight-no-input"
            formControlName="flightId"
            (change)="flightSearch()"
            [class.invalid]="(flightLeg.value == null && formDir.submitted) || flightNotFound"
            #searchInput
            placeholder="Enter Number"
          />
          <div class="error-box">
            <emt-delta-icon
              iconName="circle-x"
              *ngIf="((searchInput.value === '' || searchInput.value === null) && formDir.submitted) || flightNotFound"
              f
            ></emt-delta-icon>
            <h6 *ngIf="(searchInput.value === '' || searchInput.value === null) && formDir.submitted" class="error-message">
              Missing flight number
            </h6>
            <h6 *ngIf="flightNotFound" class="error-message">No flight found</h6>
          </div>
        </form>
      </div>
      <div class="col-md-9">
        <section class="flight-information">
          <section *ngIf="flightLeg.value && flightLeg.value.lineOfFlight" class="legs-line">
            <div *ngFor="let leg of flightLeg.value.lineOfFlight; index as n" class="leg leg-{{ n + 1 }}" (click)="selectLeg(n)">
              <span [class.selected-leg]="isSelected(leg.flightId)">
                <span class="iata">
                  {{ leg.originAirportCode }}
                </span>
                <span class="arrow">
                  &rarr;
                </span>
                <span class="iata">
                  {{ leg.destinationAirportCode }}
                </span>
              </span>
            </div>
          </section>
        </section>
      </div>
    </div>
    <div>
      <div class="quick-edit-form">
        <form [formGroup]="quickEditForm" #formDir="ngForm" (ngSubmit)="submit()" [class.submitted]="formDir.submitted">
          <table class="table-bordered table-striped">
            <colgroup>
              <col />
              <col class="small-cell" />
              <col class="small-cell" />
              <col class="small-cell" />
              <col class="small-cell" />
              <col />
              <col class="small-cell" />
              <col class="small-cell" />
            </colgroup>
            <thead>
              <tr>
                <td><h4>Event</h4></td>
                <td colspan="2"><h4>Scheduled</h4></td>
                <td colspan="2"><h4>Estimated</h4></td>
                <td><h4>Delay Code *</h4></td>
                <td colspan="2"><h4>Actual</h4></td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <!-- Out Event Times -->
                <td><h4>Out</h4></td>
                <td>
                  <span
                    *ngIf="
                      flightLeg.value &&
                      flightLeg.value.scheduledFlightMovementEventTimes &&
                      flightLeg.value.scheduledFlightMovementEventTimes.outEventUtcTs
                    "
                  >
                    {{ flightLeg.value.scheduledFlightMovementEventTimes.outEventUtcTs | momentUtc: 'HHmm[z]' }}
                  </span>
                </td>
                <td>
                  <span
                    *ngIf="
                      flightLeg.value &&
                      flightLeg.value.scheduledFlightMovementEventTimes &&
                      flightLeg.value.scheduledFlightMovementEventTimes.outEventUtcTs
                    "
                  >
                    {{ flightLeg.value.scheduledFlightMovementEventTimes.outEventUtcTs | momentUtc: 'MM/DD' }}
                  </span>
                </td>
                <td class="editable">
                  <span [ngClass]="validationClass(estimatedOutTime.control)">
                    <input
                      type="text"
                      (blur)="validateDateTime(); checkDelayCode(flightLeg); setEstimatedInTimes();"
                      formControlName="estimatedOutTime"
                      placeholder="HHMM"
                      minlength="4"
                      [required]="!isEmpty(estimatedOutDate.control)"
                      [attr.disabled]="estimatedOutTime.disabled || null"
                    />
                  </span>
                </td>
                <td class="editable">
                  <span [ngClass]="validationClass(estimatedOutDate.control)">
                    <input
                      type="text"
                      formControlName="estimatedOutDate"
                      maxlength="5"
                      placeholder="MM/DD"
                      (blur)="patchDate(estimatedOutDate); validateDateTime(); checkDelayCode(flightLeg); setEstimatedInTimes();"
                      [required]="!isEmpty(estimatedOutTime.control)"
                      [attr.disabled]="estimatedOutDate.disabled || null"
                    />
                  </span>
                </td>
                <td class="editable">
                  <span [class.invalid-bordered]="delayCode.errors && delayCode.errors.required">
                    <emt-dropdown-simple [control]="delayCode" [data]="demandCodes" [ngClass]="{'disabled': actualTimeOutExists(flightLeg), 'borderless': true}"></emt-dropdown-simple>
                  </span>
                </td>
                <td>
                  <span class="disabled">
                    <input type="text" maxlength="4" formControlName="actualOutTime" placeholder="HHMM" disabled="true" />
                  </span>
                </td>
                <td>
                  <span>
                    <input type="text" maxLength="5" formControlName="actualOutDate" placeholder="MM/DD" disabled="true" />
                  </span>
                </td>
              </tr>

              <!-- Off Event Times -->
              <tr>
                <td><h4>Off</h4></td>
                <td>
                  <span
                    *ngIf="
                      flightLeg.value &&
                      flightLeg.value.scheduledFlightMovementEventTimes &&
                      flightLeg.value.scheduledFlightMovementEventTimes.offEventUtcTs
                    "
                  >
                    {{ flightLeg.value.scheduledFlightMovementEventTimes.offEventUtcTs | momentUtc: 'HHmm[z]' }}
                  </span>
                </td>
                <td>
                  <span
                    *ngIf="
                      flightLeg.value &&
                      flightLeg.value.scheduledFlightMovementEventTimes &&
                      flightLeg.value.scheduledFlightMovementEventTimes.offEventUtcTs
                    "
                  >
                    {{ flightLeg.value.scheduledFlightMovementEventTimes.offEventUtcTs | momentUtc: 'MM/DD' }}
                  </span>
                </td>
                <td class="editable">
                  <span [ngClass]="validationClass(estimatedOffTime.control)">
                    <input
                      type="text"
                      maxlength="4"
                      (blur)="invalidTimeInputs()"
                      formControlName="estimatedOffTime"
                      [placeholder]="estimatedOffTime.placeholder"
                      [required]="!isEmpty(estimatedOffDate.control)"
                      [attr.disabled]="true"
                    />
                  </span>
                </td>
                <td class="editable">
                  <span [ngClass]="validationClass(estimatedOffDate.control)">
                    <input
                      type="text"
                      maxLength="5"
                      formControlName="estimatedOffDate"
                      mask="00/00"
                      [placeholder]="estimatedOffDate.placeholder"
                      (blur)="patchDate(estimatedOffDate)"
                      [required]="!isEmpty(estimatedOffTime.control)"
                      [attr.disabled]="true"
                    />
                  </span>
                </td>
                <td><!-- intentionally left blank --></td>
                <td>
                  <span>
                    <input type="text" maxlength="4" formControlName="actualOffTime" placeholder="HHMM" disabled="true" />
                  </span>
                </td>
                <td>
                  <span>
                    <input type="text" maxLength="4" formControlName="actualOffDate" placeholder="MM/DD" disabled="true" />
                  </span>
                </td>
              </tr>

              <!-- In Event Times -->
              <tr>
                <td><h4>In</h4></td>
                <td>
                  <span
                    *ngIf="
                      flightLeg.value &&
                      flightLeg.value.scheduledFlightMovementEventTimes &&
                      flightLeg.value.scheduledFlightMovementEventTimes.inEventUtcTs
                    "
                  >
                    {{ flightLeg.value.scheduledFlightMovementEventTimes.inEventUtcTs | momentUtc: 'HHmm[z]' }}
                  </span>
                </td>
                <td>
                  <span
                    *ngIf="
                      flightLeg.value &&
                      flightLeg.value.scheduledFlightMovementEventTimes &&
                      flightLeg.value.scheduledFlightMovementEventTimes.inEventUtcTs
                    "
                  >
                    {{ flightLeg.value.scheduledFlightMovementEventTimes.inEventUtcTs | momentUtc: 'MM/DD' }}
                  </span>
                </td>
                <td class="editable">
                  <span [ngClass]="validationClass(estimatedInTime.control)">
                    <input
                      type="text"
                      maxlength="4"
                       (blur)="invalidTimeInputs()"
                      formControlName="estimatedInTime"
                      [placeholder]="estimatedInTime.placeholder"
                      [required]="!isEmpty(estimatedInDate.control)"
                      [attr.disabled]="estimatedInTime.disabled || null"
                    />
                  </span>
                </td>
                <td class="editable">
                  <span [ngClass]="validationClass(estimatedInDate.control)">
                    <input
                      type="text"
                      maxLength="5"
                      formControlName="estimatedInDate"
                      mask="00/00"
                      (blur)="patchDate(estimatedInDate)"
                      [placeholder]="estimatedInDate.placeholder"
                      [required]="!isEmpty(estimatedInTime.control)"
                      [attr.disabled]="estimatedInDate.disabled || null"
                    />
                  </span>
                </td>
                <td><!-- Left intentionally blank --></td>
                <td>
                  <span>
                    <input type="text" maxlength="4" disabled="true" formControlName="actualInTime" placeholder="HHMM" />
                  </span>
                </td>
                <td>
                  <span>
                    <input type="text" maxLength="4" formControlName="actualInDate" placeholder="MM/DD" disabled="true" />
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  <h4 [class.invalid]="formDir.submitted && comments.invalid">Comments</h4>
                </td>
                <td colspan="7" class="editable comment">
                  <span [ngClass]="formDir.submitted && commentsValid == false && messageList.errors.length > 0? 'invalid-bordered': null">
                    <textarea [attr.disabled]="actualTimeOutExists(flightLeg)" formControlName="comments" maxlength="117"></textarea>
                  </span>

                  <div
                    class="character-count"
                    [class.warning]="comments.value && comments.value.length >= 100 && comments.value.length < commentLimit"
                    [class.invalid]="comments.value && comments.value.length >= commentLimit"
                  >
                    <emt-delta-icon
                      *ngIf="comments.value && comments.value.length >= 100"
                      [fill]="comments.value && comments.value.length >= commentLimit ? '#c01933' : '#FF9900'"
                      iconName="icon-warning"
                      [height]="12"
                      [width]="12"
                    ></emt-delta-icon>
                    {{ comments.value ? comments.value.length : 0 }}/{{ commentLimit }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="error-box">
            <span *ngIf="messageList.errors.length > 0">
              <emt-delta-icon
                [iconName]="'circle-x'"
                [fill]="formDir.submitted ? '#c01933' : '#FF9900'"
                [height]="12"
                [width]="12"
              ></emt-delta-icon>
              <h6 [ngClass]="'error-message'">{{messageList.errorText.join(', ')}}</h6>
            </span> 
            <span *ngIf="messageList.warnings.length > 0">
              <emt-delta-icon
                [iconName]="'icon-warning'"
                [fill]="'#FF9900'"
                [height]="12"
                [width]="12"
              ></emt-delta-icon>
              <h6 [ngClass]="'warning-message'">{{messageList.warnings.join(', ')}}</h6>
            </span> 
            <span *ngIf="formDir.submitted && missingDateTime()">
              <emt-delta-icon [iconName]="'circle-x'" [fill]="'#c01933'" [height]="12" [width]="12"></emt-delta-icon>
              <h6 class="error-message">Please enter a time and date.</h6>
            </span>
            <span *ngIf="delayCode.errors && delayCode.errors.required && formDir.submitted">
              <emt-delta-icon iconName="circle-x" fill="#c01933" height="12" width="12"></emt-delta-icon>
              <h6 class="error-message">Please enter a delay code.</h6>
            </span>
            <span *ngIf="formDir.submitted && submissionError">
              <emt-delta-icon
                [iconName]="formDir.submitted ? 'circle-x' : 'icon-warning'"
                [fill]="formDir.submitted ? '#c01933' : '#FF9900'"
                [height]="12"
                [width]="12"
              ></emt-delta-icon>
              <h6 [ngClass]="formDir.submitted ? 'error-message' : 'warning-message'">{{ submissionError }}</h6>
            </span>
            <span *ngIf="formDir.errors && formDir.errors.outOfSequence">
              <emt-delta-icon
                [iconName]="formDir.submitted ? 'circle-x' : 'icon-warning'"
                [fill]="formDir.submitted ? '#c01933' : '#FF9900'"
                [height]="12"
                [width]="12"
              ></emt-delta-icon>
              <h6 [ngClass]="formDir.submitted ? 'error-message' : 'warning-message'">{{ formDir.errors.outOfSequence }}</h6>
            </span>
          </div>
          <div class="controls">
            <button [disabled]="actualTimeOutExists(flightLeg)" type="submit" class="btn btn-primary" value="Submit">Update</button>
            <button [disabled]="actualTimeOutExists(flightLeg)" type="button" class="btn btn-secondary" (click)="reset()">Reset</button>
            <button type="button" class="btn btn-secondary" (click)="close()">Cancel</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
