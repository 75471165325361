import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { Airport } from '../../models/airport';

import { FormGroup } from '@angular/forms';
import { UserDesks } from '../../models/user-desks';

// export enum FlightListSelectorDialogAction {
//   submit = 0,
//   cancel
// }

// export interface FlightListSelectorDialogResult {
//   action: FlightListSelectorDialogAction;
//   data: UserDesks;
// }

@Component({
  selector: 'emt-station-selector-dialog',
  templateUrl: './station-selector-dialog.component.html',
  styleUrls: ['./station-selector-dialog.component.scss'],
})
export class StationSelectorDialogComponent {
  public modalTitle = '';
  public stations: Airport[];
  public selectedStations: string[];
  //#endregion

  //#region Inputs
  @Input() name: string;
  //#endregion

  //#region Constructor
  constructor(public activeModal: NgbActiveModal) {}

  submitDialog() {
    this.activeModal.close(this.selectedStations);
  }

  cancelDialog() {
    this.activeModal.close();
  }
}
