import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from './store';
import { LoadAlertsAction } from './store/actions/alert/alert.actions';
import { Subscription } from 'rxjs';
import { Router, NavigationEnd, NavigationStart, ActivatedRoute } from '@angular/router';
import { ExpandFlightList } from './store/actions/sidebar/sidebar.actions';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { QuickEditComponent } from './components/transactional/quick-edit/quick-edit.component';
import { AuthService } from './services/auth/auth.service';

@Component({
  selector: 'emt-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  private routerSubscription: Subscription;
  public isReportOpened: boolean;

  constructor(private store: Store<AppState>, private router: Router, private route: ActivatedRoute, private modalService: NgbModal, private authService: AuthService) {}

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if ((event.code === 'KeyQ' || event.key === 'q') && event.ctrlKey) {
      if (!this.modalService.hasOpenModals() && this.authService.hasPermission('flifo', 'edit')) {
        this.modalService.open(QuickEditComponent, {
          centered: true,
          backdrop: 'static',
          windowClass: 'md-modal',
        });
      }
    }
  }

  ngOnInit() {
    // Removing Advance Search Cached Data
    console.log('--- Clearing adv search results from local storage ---');
    localStorage.removeItem('advanced-search-results');
    // TODO: Authentication needs local storage, do NOT clear it
    this.store.dispatch(new LoadAlertsAction());
    // Update alerts every 30 seconds
    setInterval(() => {
      this.store.dispatch(new LoadAlertsAction());
    }, 30000);

    this.routerSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.isReportOpened = event.url.includes('report');
        this.loadSidebarModules(event.url);
      }
    });
    // Set desk position into session storage
    this.route.queryParams.subscribe(params => {
      this.setDeskPosition(params['Position']);
    });
  }

  ngOnDestroy() {
    // Removing Advance Search Cached Data
    console.log('--- Clearing adv search results from local storage ---');
    localStorage.removeItem('advanced-search-results');
    // TODO: Authentication needs local storage, do NOT clear it

    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

  loadSidebarModules(url: string) {
    let sidebarComponent = url.match(/\(sidebar:?\S*?\)/g) || [];
    if (sidebarComponent.length > 0) {
      let loadModule = sidebarComponent[0].replace(/\(sidebar:|\)/g, '');
      switch (loadModule) {
        case 'flight-list':
          this.store.dispatch(new ExpandFlightList());
          break;
        default:
          // Add cases as we add more sidebar modules.
          break;
      }
    }
  }

  setDeskPosition(position: string) {
    const deskPosition = position && position.length > 0 ? position : null;
    if (deskPosition) {
      sessionStorage.setItem('rawVDIDeskNo', deskPosition);
      sessionStorage.setItem('VDIDeskNo', deskPosition.slice(-2).trim().padStart(2, '0'));
    }
  }
}
