import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'emt-dropdown-v3',
  templateUrl: './dropdown-v3.component.html',
  styleUrls: ['./dropdown-v3.component.scss'],
})
export class DropdownV3Component {
  @Input() placeholder: string;
  @Input() options: any;
  @Output() valueChanged = new EventEmitter();
}
