import { GeographicCoordinate, GeographicCoordinateClass } from './geographic-coordinate';
import { PerformanceData, PerformanceDataClass } from './performance-data';

export interface Airport {
  airportCode: string;
  airportName: string;
  cityName: string;
  countryCode: string;
  countrySubdivisionCode: string;
  elevationFeetCnt: number;
  geographicCoordinate: GeographicCoordinate;
  icaoAirportCode: string;
  magneticVariationDegreeNum: number;
  timeZoneDesc: string;
  carriers?: string[]; // UI Only
  isDeltaConnectionStation?: boolean; // UI Only
  isDeltaStation?: boolean; // UI Only
  performanceData?: PerformanceData; // UI Only
  arrivalTimeline?: string; // UI Only
  departTimeline?: string; // UI Only
  taxiArrival?: string; // UI Only
  taxiDeparture?: string; // UI Only
  x?: number; // UI Only
  y?: number; // UI Only
}

export class AirportClass implements Airport {
  public airportCode: string;
  public airportName: string;
  public cityName: string;
  public countryCode: string;
  public countrySubdivisionCode: string;
  public elevationFeetCnt: number;
  public geographicCoordinate: GeographicCoordinate;
  public icaoAirportCode: string;
  public magneticVariationDegreeNum: number;
  public timeZoneDesc: string;
  public carriers: string[];
  public isDeltaConnectionStation: boolean;
  public isDeltaStation: boolean;
  public performanceData: PerformanceData;

  // prettier-ignore
  constructor(obj: Partial<Airport> = {}) {
    this.airportCode = obj.airportCode || null;
    this.airportName = obj.airportName || null;
    this.cityName = obj.cityName || null;
    this.countryCode = obj.countryCode || null;
    this.countrySubdivisionCode = obj.countrySubdivisionCode || null;
    this.elevationFeetCnt = obj.elevationFeetCnt || null;
    this.geographicCoordinate = new GeographicCoordinateClass(obj.geographicCoordinate);
    this.icaoAirportCode = obj.icaoAirportCode || null;
    this.magneticVariationDegreeNum = obj.magneticVariationDegreeNum || null;
    this.timeZoneDesc = obj.timeZoneDesc || null;
    this.carriers = obj.carriers || [];
    this.isDeltaConnectionStation = obj.isDeltaConnectionStation || null;
    this.isDeltaStation = obj.isDeltaStation || null;
    this.performanceData = new PerformanceDataClass(obj.performanceData);
  }
}
