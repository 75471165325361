import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

const momentConstructor = moment;

@Pipe({ name: 'momentUtc' })
export class MomentUtcPipe implements PipeTransform {
  transform(value: Date | moment.Moment | string | number, format: string): string {
    return value
      ? momentConstructor(value)
          .utc()
          .format(format)
      : '- - -';
  }
}
