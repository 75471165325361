<form [formGroup]="searchForm" (ngSubmit)="doSearch()">
  <div class="searchRadios">
    <div class="custom-control custom-radio custom-control-inline">
      <input
        type="radio"
        value="flight"
        class="custom-control-input"
        id="searchFlight"
        formControlName="searchOption"
        checked="checked"
        (click)="resetSearchText()"
      />
      <label class="custom-control-label" for="searchFlight">Flight</label>
    </div>
    <div class="custom-control custom-radio custom-control-inline">
      <input
        type="radio"
        value="ship"
        class="custom-control-input"
        id="searchShip"
        formControlName="searchOption"
        (click)="resetSearchText()"
      />
      <label class="custom-control-label" for="searchShip">Ship</label>
    </div>
  </div>
  <div class="search-input">
    <input
      type="text"
      #searchTextField
      required
      formControlName="searchText"
      (keypress)="numberOnly($event)"
      [maxLength]="getMaxLength()"
    />
    <span class="highlight"></span>
    <span class="bar"></span>
    <label>Search</label>
    <emt-delta-icon class="icon-search" iconName="icon-search" (click)="doSearch()"></emt-delta-icon>
  </div>
</form>
