import { Pipe, PipeTransform } from '@angular/core';
import { Headers } from '../models/table.model';
import * as moment from 'moment';
const momentConstructor = moment;

@Pipe({ name: 'validateTableCellInput' })
export class ValidateTableCellInputPipe implements PipeTransform {
  transform(cell: object, header: Headers): string {
    let value;
    if (header.key && cell[header.key] !== null && cell[header.key] !== undefined) {
      value = cell[header.key];
    }

    if (value !== null && value !== undefined && value !== '-' && value !== '') {
      if (header.isDayHour) {
        return (
          momentConstructor(value)
            .utc()
            .format('DD HHmm') + 'Z'
        );
      } else {
        return value;
      }
    } else {
      return '- - -';
    }
  }
}
