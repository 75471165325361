import { UserProfile } from '../../../models/userprofile.model';
import { AuthActions, AuthActionTypes } from '../../actions/auth/auth.actions';

export interface State {
  user : UserProfile ;
}

const initalState: State = {
  user: undefined
};

export function reducer(state: State = initalState, action: AuthActions) {
  switch (action.type) {
    case AuthActionTypes.USER_LOGIN:
      return { user: action.payload.user};
    case AuthActionTypes.USER_LOGOUT:
      return { user: undefined};
    default:
      return state;
  }
}

export const getUser = (state: State) => state.user;