export interface PerformanceData {
  dailyInboundVolume: number;
  dailyOutboundVolume: number;
}

export class PerformanceDataClass implements PerformanceData {
  public dailyInboundVolume: number;
  public dailyOutboundVolume: number;

  constructor(obj: Partial<PerformanceData> = {}) {
    this.dailyInboundVolume = obj.dailyInboundVolume || 0;
    this.dailyOutboundVolume = obj.dailyOutboundVolume || 0;
  }
}
