import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router, ActivatedRoute, Params } from '@angular/router';
import { Subscription } from 'rxjs';
import { NavigationTab } from '../../models/navigation-tab.model';
import { v4 as uuid } from 'uuid';
import { isEqual, isNumber } from 'lodash';
import { AlertService, StationSelectorDialogComponent, Airport } from 'projects/common/src/public_api';
import { AdvancedSearchService } from '../../feature-modules/advanced-search/services/advanced-search.services';
import { DataService } from '../../services/dataService/data-service.service';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { getAllStations } from '../../store/selectors/station/station.selectors';

@Component({
  selector: 'emt-tab-bar',
  templateUrl: './tab-bar.component.html',
  styleUrls: ['./tab-bar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TabBarComponent implements OnInit, OnDestroy {
  public openTabs: NavigationTab[] = [];
  public selectedTabIndex: number;
  private routerSubscription: Subscription;
  private searchServiceSubscription: Subscription;
  public tabLimit = 10;
  private stations: Airport[];
  private tabSubscriptions: Subscription;
  private routerURL: any;
  public currentTabID: string;

  constructor(
    private dataService: DataService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private searchService: AdvancedSearchService,
    private modalService: NgbModal,
    private store: Store<any>
  ) {}

  ngOnInit() {
    this.currentTabID = '';
    this.store.select(getAllStations).subscribe((stations: any[]) => {
      if (!this.stations && stations && stations.length) {
        this.stations = stations;
      }
    });

    this.readTabsFromStorage();
    this.routerSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.routeOnNavigation(event);
        this.routerURL = event;
      }
    });
    this.tabSubscriptions = this.dataService.getNotifyTabCreated().subscribe(tab => {
      if (tab === 'newTabCreated') {
        this.createNewTab(this.routerURL);
      } else if (tab === 'existingTab') {
        this.routeOnNavigation(this.routerURL);
      }
    });
    this.searchServiceSubscription = this.searchService.sortParams.subscribe(sortParams => {
      this.openTabs[this.selectedTabIndex].queryParams = this.getFlatQueryParams(sortParams.queryParams);
    });
  }

  ngOnDestroy() {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
    if (this.searchServiceSubscription) {
      this.searchServiceSubscription.unsubscribe();
    }
    if (this.tabSubscriptions) {
      this.tabSubscriptions.unsubscribe();
    }
  }

  writeTabsToStorage() {
    sessionStorage.setItem('emtTabs', JSON.stringify(this.openTabs));
    sessionStorage.setItem('emtSelectedTab', `${this.selectedTabIndex}`);
  }

  readTabsFromStorage() {
    const tabsString = sessionStorage.getItem('emtTabs');
    const selectedTabIndexString = sessionStorage.getItem('emtSelectedTab');
    if (tabsString && selectedTabIndexString) {
      const tabsObject: NavigationTab[] = JSON.parse(tabsString);
      const selectedIndex: number = +selectedTabIndexString;
      if (tabsObject && isNumber(selectedIndex) && selectedIndex > -1) {
        this.openTabs = tabsObject;
        this.selectedTabIndex = selectedIndex;
        this.currentTabID = this.openTabs[this.selectedTabIndex] ? this.openTabs[this.selectedTabIndex].id : null;
        if (this.openTabs[this.selectedTabIndex] && this.openTabs[this.selectedTabIndex].path.includes('integrated-crew-hub')) {
          this.dataService.setNotifyTabCreated('existingTab');
        }
      }
    }
    if (this.router.url === '/') {
      this.selectedTabIndex = null;
    }
  }

  selectTab(index: number, tab: NavigationTab) {
    if (index !== this.selectedTabIndex) {
      this.currentTabID = tab.id;
      // this.store.dispatch(new NavActions.SelectTab({ tab: index }));
      if (tab && tab.queryParams) {
        let obj= decodeURIComponent(tab.path);
        this.router.navigate([obj], { queryParams: tab.queryParams }).then(() => {
          if (tab.path.includes('integrated-crew-hub')) {
            this.dataService.setNotifyTabCreated('existingTab');
          }
        });
      } else {
        let obj= decodeURIComponent(tab.path);
        this.router.navigate([obj]).then(() => {
          if (tab.path.includes('integrated-crew-hub')) {
            this.dataService.setNotifyTabCreated('existingTab');
          }
        });
      }
    }
  }

  deleteTab(index: number) {
    if (index === this.selectedTabIndex) {
      let newIndex = index;
      if (this.openTabs.length > 1 && index === this.openTabs.length - 1) {
        newIndex--;
      }
      this.openTabs.splice(index, 1);
      this.selectedTabIndex = newIndex;
      if (this.openTabs.length > 0) {
        const tab = this.openTabs[this.selectedTabIndex];
        let obj= decodeURIComponent(tab.path);
        this.router.navigate([obj], { queryParams: tab.queryParams });
      } else {
        // If the sidebar is open, keep it open when we close the last tab
        if (this.router.url.includes('sidebar:flight-list')) {
          this.router.navigateByUrl('/(sidebar:flight-list)');
        } else {
          this.router.navigate(['/']);
        }
      }
    } else {
      let newIndex = this.selectedTabIndex;
      if (index < this.selectedTabIndex) {
        newIndex--;
      }
      this.openTabs.splice(index, 1);
      this.selectedTabIndex = newIndex;
    }
    this.writeTabsToStorage();
    if (this.openTabs.length === 0) {
      this.currentTabID = '';
    } else {
      this.currentTabID = this.openTabs[this.selectedTabIndex].id;
    }
  }

  addNewTab(tab: NavigationTab) {
    if (this.openTabs.length >= this.tabLimit) {
      this.alertService.handleAlert(`You've hit the limit of ${this.tabLimit} tabs. Please remove a tab in order to add a new one.`, [
        'Cancel',
      ]);
      this.selectTab(-1, this.openTabs[this.selectedTabIndex]);
    } else {
      this.currentTabID = tab.id;
      this.openTabs.push(tab);
      this.selectedTabIndex = this.openTabs.length - 1;
    }
  }

  routeOnNavigation(event: NavigationEnd) {
    let extras =
      this.router.getCurrentNavigation() && this.router.getCurrentNavigation().extras ? this.router.getCurrentNavigation().extras : null;
    if (extras && extras.hasOwnProperty('state') && extras.state && extras.state.ignoreRouteChange) {
      return null;
    }

    if (this.openTabs.length == 0) {
      if (
        event.url &&
        event.urlAfterRedirects &&
        !event.url.includes('integrated-crew-hub') &&
        !event.urlAfterRedirects.includes('integrated-crew-hub')
      ) {
        // create first tab
        this.createNewTab(event);
      }
    } else {
      const currentTab = this.openTabs[this.selectedTabIndex];
      const queryParams = this.getFlatQueryParams(this.activatedRoute.snapshot.queryParams);
      const fullUrl = event.urlAfterRedirects.substring(1);
      const path = fullUrl.replace('(sidebar:flight-list)', '').split('?')[0];
      const pathRoot = path.split('/')[0];

      // If replaceActiveTab param/flag is present, replace the active tab. Currently only used for the Deice, STPD, JV Gates in-line station selectors
      if (queryParams && queryParams.replaceActiveTab) {
        this.replaceActiveTab(queryParams);
      } else {
        // Check if we are switching to an existing tab
        let foundTab;
          foundTab = this.searchForExistingTab(currentTab, queryParams, path);

        if (!foundTab) {
          // If we didn't find an existing tab, check to see if we should navigate within the current tab.
          this.navigateWithinCurrentTab(currentTab, queryParams, fullUrl, path, pathRoot, event);
        }
      }
    }
    this.writeTabsToStorage();
  }

  replaceActiveTab(queryParams) {
    // activatedRoute query params are read only, need to be cloned so we can delete the replaceActiveTab param
    const newQueryParams = JSON.parse(JSON.stringify(queryParams));
    // removing replaceActiveTab param
    delete newQueryParams.replaceActiveTab;
    // deleting the active tab
    this.deleteTab(this.selectedTabIndex);
    // re-triggering navigation to either create a new tab or switch to an existing tab depending on params
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: newQueryParams,
    });
  }

  createNewTab(event: NavigationEnd) {
    const path = event.urlAfterRedirects
      .replace('(sidebar:flight-list)', '')
      .split('?')[0]
      .replace(/^\//g, '');
    const splitPaths = path.split('/');
    const pathRoot = splitPaths[0];
    const station = splitPaths[1];

    // Launching the station selector modal for Deice ,diversion management and STPD if no station param is present (linked from sharepoint: emt.com/deice or emt.com/throughput)
    if (pathRoot === 'deice' && !station) {
      this.loadStationSelectorModal(pathRoot);
    }
    if (pathRoot === '/diversionManagement' && !station) {
      this.loadStationSelectorModal(pathRoot);
    } else if (pathRoot === 'throughput' && !station) {
      this.loadStationSelectorModal(pathRoot);
    } else {
      const newTab = this.createTab(path, station, pathRoot);

      if (newTab) {
        this.addNewTab(newTab);
      }
    }
  }

  createTab(path: string, station: string, pathRoot) {
    const queryParams = this.getFlatQueryParams(this.activatedRoute.snapshot.queryParams);

    const tab: NavigationTab = {
      name: null,
      path: path,
      id: uuid(),
      queryParams: queryParams,
    };

    switch (pathRoot) {
      case 'flight-info':
        tab.name = queryParams['t'] || 'Flight Info';
        break;
      case 'deice':
        tab.name = `${station} Deicing`;
        break;
      case 'throughput':
        tab.name = `${station} STPD`;
        break;
      case 'jv-gates':
        tab.name = `${station} JV Gates`;
        break;
      case 'advanced-search':
        tab.name = 'Advanced Search';
        break;
      case 'policy-engine':
        tab.name = 'Policy Engine';
        break;
      case 'cancellations':
        tab.name = 'Cancel Model';
        break;
      case 'capacity':
        tab.name = 'Gate Capacity';
        break;
      case 'user-access':
        tab.name = 'User Access';
        break;
      case 'irop-replay':
        tab.name = 'Irop Replay';
        break;
      case 'severe-weather-index':
        tab.name = 'Severe Weather Index';
        break;
      case 'air-traffic-control':
        tab.name = 'Air Traffic Control';
        break;
      case 'diversionManagement':
        tab.name = `Diversion Management Tool`;
        break;
      case 'operational-codes':
        tab.name = 'Operational Codes';
        break;
      default:
        break;
    }

    return tab.name ? tab : null;
  }
  
  searchForExistingTab(currentTab: NavigationTab, queryParams, path: string) {
    let foundTab = false;
    for (let i = 0; i < this.openTabs.length; i++) {
      const tab = this.openTabs[i];

      if (path.toLowerCase() === tab.path.toLowerCase() && isEqual(queryParams, tab.queryParams)) {
        this.selectedTabIndex = i;
        foundTab = true;
      } else if (
        // If 2 flifo tabs have the same flight number and date
        queryParams['t'] &&
        queryParams['d'] &&
        tab.queryParams['t'] &&
        tab.queryParams['d'] &&
        queryParams['t'] == tab.queryParams['t'] &&
        queryParams['d'] == tab.queryParams['d']
      ) {
        // If we are staying on the same tab, update the parameters
        if (this.selectedTabIndex === i) {
          currentTab.path = path;
          currentTab.queryParams = queryParams;
        }
        this.selectedTabIndex = i;
        foundTab = true;
      } else if (
        // Allow multiple deice and station throughput tabs, but only one per station. Ex one throughput/ATL tab and one deice/ATL
        tab.path === path &&
        (path.includes('throughput') || path.includes('deice') || path.includes('diversionManagement'))
      ) {
        // Update tab queryParams if on the same page
        if (currentTab && currentTab.path === path) {
          currentTab.queryParams = queryParams;
        }

        this.selectedTabIndex = i;
        foundTab = true;
        // allow interminal to route with in the dashboard and route to new tab when new station selected
      }
    }

    return foundTab;
  }

  navigateWithinCurrentTab(currentTab, queryParams, fullUrl: string, path: string, pathRoot: string, event) {
    // Skip if we are navigating from advanced search, and flight-info
    // Results to advanced search results
    if (
      currentTab &&
      pathRoot === currentTab.path.split('/')[0] &&
      (queryParams['useExistingTab'] ||
        (!(currentTab.path.startsWith('advanced-search/results') && path.startsWith('advanced-search/results')) &&
          !(currentTab.path.startsWith('advanced-search/results') && fullUrl === 'advanced-search') &&
          !(currentTab.path.startsWith('integrated-crew-hub') && path.startsWith('integrated-crew-hub')) &&
          !(currentTab.path.startsWith('flight-info') && path.startsWith('flight-info')) &&
          !(currentTab.path.startsWith('deice') && path.startsWith('deice')) &&
          !(currentTab.path.startsWith('jv-gates') && path.startsWith('jv-gates')) &&
          !(currentTab.path.startsWith('diversionManagement') && path.startsWith('diversionManagement')) &&
          !(currentTab.path.startsWith('throughput') && path.startsWith('throughput'))))
    ) {
      currentTab.path = path;
      currentTab.queryParams = queryParams;
    } else {
      // Otherwise, create a new tab
      this.createNewTab(event);
    }
  }
  
  loadStationSelectorModal(pathRoot: string) {
    let item = {
      displayName: 'Deice',
      iconName: '',
      route: '/deice',
      access: 'deice',
    };

    if (pathRoot === 'throughput') {
      item = {
        displayName: 'Station Throughput',
        iconName: '',
        route: '/throughput',
        access: 'stationThroughput',
      };
    }
    if (pathRoot === 'diversionManagement') {
      item = {
        displayName: 'Diversion Management Tool',
        iconName: '',
        route: '/diversionManagement',
        access: 'diversionManagement',
      };
    }
    const modalRef: NgbModalRef = this.modalService.open(StationSelectorDialogComponent, { windowClass: 'customModalClass' });
    modalRef.componentInstance.modalTitle = item.displayName;
    modalRef.componentInstance.stations = this.stations || [];

    modalRef.result.then(result => {
      if (result) {
        this.router.navigate([item.route, result]);
      } else {
        if (
          this.selectedTabIndex !== null &&
          this.selectedTabIndex !== undefined &&
          this.openTabs.length &&
          this.openTabs[this.selectedTabIndex]
        ) {
          // Show previous active tab if user hits cancel
          this.selectTab(null, this.openTabs[this.selectedTabIndex]);
        }
      }
    });
  }

  trackByFn(index) {
    return index;
  }

  // This extracts items out of 1-long arrays to prevent weirdness with refreshing
  // example: "Scheduled" vs ["Scheduled"]
  getFlatQueryParams(queryParams: Params): Params {
    let newParams = {};
    Object.keys(queryParams).map(key => {
      const value = queryParams[key];
      if (Array.isArray(value) && value.length === 1) {
        newParams[key] = value[0];
      } else {
        newParams[key] = value;
      }
    });
    return newParams;
  }
}
