import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ErrorModalComponent } from '../../components/error-modal/error-modal.component';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  constructor(private modalService: NgbModal) {}

  public handleError(error: string, options?: any) {
    const modalRef = this.modalService.open(ErrorModalComponent);
    modalRef.componentInstance.message = error;
  }
}
