import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RoleSelectionService {
  private subject: ReplaySubject<string> = new ReplaySubject<string>();

  constructor() {}

  setSelectedRole = (selectedRole: string) => {
    this.subject.next(selectedRole);
    sessionStorage.setItem('selectedRole', selectedRole);
  };

  getSelectedRole = (): Observable<string> => this.subject.asObservable();
}
