<div class="overlay">
  <div class="logout-container">
    <div class="logout-logo">
      <span class="title">EMT</span>
      <emt-delta-icon class="icon-delta-logo" iconName="icon-delta-logo"></emt-delta-icon>
    </div>
    <div class="logout-title">Log Out</div>
    <div class="logout-content">Are you sure you want to log out?</div>
    <div class="logout-buttons">
      <button class="logout-button" (click)="logout()">Log Out</button>
      <button class="cancel-button" (click)="cancel()">Cancel</button>
    </div>
  </div>
</div>
