import * as moment from 'moment';
import { Router, ActivatedRoute } from '@angular/router';
import { ReportType } from '../models/metric-cards.model';

export type SortDirection = 'asc' | 'desc' | '';
export const rotate: { [key: string]: SortDirection } = { asc: 'desc', desc: '', '': 'asc' };
export const compare = (v1, v2) => (v1 < v2 ? -1 : v1 > v2 ? 1 : 0);

export interface SortEvent {
  column: string;
  direction: SortDirection;
}

export class ReportUtil {
  public static formatHourMinutes = minutes => {
    if (minutes) {
      const hours = Math.floor(minutes / 60);
      const mins = minutes % 60;

      return (hours < 10 ? `0${hours}` : hours) + ':' + (mins < 10 ? `0${mins}` : mins);
    } else {
      return '';
    }
  }

  public static openReport(router: Router, type: ReportType, params: any, relativeTo: ActivatedRoute) {
    let url = router.createUrlTree(
      [`report/${type}`],
      {
        queryParams: params,
        relativeTo
      }).toString();

      url = `${window.location.protocol}//${window.location.host}${url.replace('(sidebar:flight-list)', '')}`;

      let windowParams = 'titlebar=no,toolbar=no,location=no,status=no,menubar=no,resizable=yes,addressbar=no,top=0,left=0';
      windowParams = `${windowParams},width=${screen.width/2},height=${screen.height/2}`;

      window.open(url, '_blank', windowParams);
  }
}
