import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of, defer } from 'rxjs';
import { Action } from '@ngrx/store';
import { switchMap, map, catchError } from 'rxjs/operators';

import { HttpErrorResponse } from '@angular/common/http';
import {
  LoadStationsAction,
  StationActionTypes,
  LoadStationsSuccessAction,
  LoadStationsFailAction,
} from '../../actions/station/station.actions';
import { StationService } from '../../../services/station/station.service';

@Injectable()
export class StationsEffects {
  constructor(private actions$: Actions, private service: StationService) {}
  @Effect()
  loadStations$: Observable<Action> = defer(() =>
    this.actions$.pipe(
      ofType(StationActionTypes.LoadStations),
      switchMap((state: LoadStationsAction) => {
        return this.service.getAirports().pipe(
          // If successful, dispatch success action with result
          map(stations => new LoadStationsSuccessAction(stations)),
          // If request fails, dispatch failed action
          catchError((err: HttpErrorResponse) => of(new LoadStationsFailAction(err)))
        );
      })
    )
  );
}
