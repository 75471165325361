<div class="nav-content" (click)="onItemSelected(item)" [ngClass]="{ 'active-parent-link': item?.route === activePath }">
  <emt-delta-icon
    [ngClass]="item?.iconName"
    [iconName]="item?.iconName"
    class="route-icon"
    [fill]="item?.route === activePath && 'white'"
  ></emt-delta-icon>
  {{ item?.displayName }}
  <emt-delta-icon
    class="arrow"
    iconName="icon-arrow"
    *ngIf="item?.children && item?.children.length"
    [ngClass]="expanded ? 'invert-arrow' : 'none'"
  ></emt-delta-icon>
</div>
<ul *ngIf="expanded && item && item.children && item.children.length">
  <li *ngFor="let child of item?.children" (click)="onItemSelected(child)" [ngClass]="{ 'active-child-link': child?.route === activePath }">
    {{ child.displayName }}
  </li>
</ul>
