import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { PreHeaders, Headers } from '../../models/table.model';

@Component({
  selector: 'emt-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit, OnChanges {
  @Input() preHeaders: PreHeaders[];
  @Input() headers: Headers[];
  @Input() body: Object[];
  @Input() isCollapsible: boolean;
  @Input() collapsibleKey: string;
  @Input() customTableMsg: string;
  @Output() cellClicked = new EventEmitter();
  public isDataBlank = false;
  public toggledCell: number;

  constructor() {}

  ngOnInit() {}

  ngOnChanges() {
    if (this.body && !this.body.length) {
      this.isDataBlank = true;
    }

    if (this.body && this.body.length) {
      this.isDataBlank = false;
    }

    this.toggledCell = null;
  }

  toggleCell(key: number) {
    if (this.toggledCell === key) {
      this.toggledCell = null;
    } else {
      this.toggledCell = key;
    }
  }

  onCellClicked(header, cell) {
    this.cellClicked.emit({ header, cell });
  }
}
