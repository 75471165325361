import * as moment from 'moment';

export class QuickEditFormModel {
  estimatedOut: moment.Moment = null;
  estimatedIn: moment.Moment = null;
  estimatedOff: moment.Moment = null;
  actualOut: moment.Moment = null;
  actualIn: moment.Moment = null;
  actualOff: moment.Moment = null;
}
