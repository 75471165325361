import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, pluck } from 'rxjs/operators';
import { Airport, AirportClass } from 'projects/common/src/public_api';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '../config/config.service';

@Injectable({
  providedIn: 'root',
})
export class StationService {
  constructor(private http: HttpClient, private configService: ConfigService) {}
  getAirports(): Observable<Airport[]> {
    const baseUrl = `${this.configService.getSettings('stations')}`; // Real data
    // baseUrl = 'assets/stations.json'; // Mock data

    return (
      this.http
        .get(baseUrl) // Real data
        // .get(baseUrl) // Mock data
        .pipe(
          pluck('airports'),
          map((airports: Airport[]) => airports.map(airport => new AirportClass(airport)))
        )
    );
  }
}
