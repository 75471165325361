import { Injectable } from '@angular/core';
import { of, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  public tokenSubject = new BehaviorSubject<string>('');
  private token;
  public urlsSubject = new BehaviorSubject<string[]>([]);
  private interceptorUrls: string[];
  constructor() {}

  setToken(token: any) {
    this.token = token;
    this.tokenSubject.next(token);
  }

  setInterceptorUrls(urls: string[]) {
    this.interceptorUrls = urls;
    this.urlsSubject.next(urls);
  }
}
