import { FlightMovementEventTimes, FlightMovementEventTimesClass } from './flight-movement-event-times';
import { Airport } from './airport';
import { Aircraft } from './aircraft';
import { FlightIdentifierChange } from './flight-identifier-change';
import { FlightLegStateChange } from './flight-leg-state-change.model';
import { FlightTracking } from './flight-tracking';
import { Flightplan, FlightplanClass } from './flight-plan';
import { OwnedAircraftType } from './owned-aircraft-type';
import { CodeShareFlight } from './code-share-flight';
import * as moment_ from 'moment';
import { Moment } from 'moment';
const moment = moment_;

export interface FlightLeg {
  actualArrivalGateId: string;
  actualDepartureGateId: string;
  internalArrivalGateId: string;
  internalDepartureGateId: string;
  actualFlightMovementEventTimes: FlightMovementEventTimes;
  actualLineOfFlightSequenceNum: number;
  bestFlightMovementEventTimes: FlightMovementEventTimes;
  aircraft: Aircraft;
  boardingStartUtcTs: string;
  callSignId: string;
  cloaked: boolean;
  codeShareFlights: CodeShareFlight[];
  correctiveActionRequired: boolean;
  correctiveActionUpdateUtcTs: string;
  creationReasonCode: CreationsReasonsType;
  currentFlightLegStateCode: FlightLegStateCodeType;
  destinationAirport: Airport;
  dispatcherDeskId: string;
  estimatedDepartureControlUtcTs: string;
  estimatedFlightMovementEventTimes: FlightMovementEventTimes;
  flightId: string;
  flightIdentifierChanges: FlightIdentifierChange[];
  flightLegStateChanges: FlightLegStateChange[];
  flightNum: string;
  flightOriginDate: string;
  flightplan: Flightplan;
  flightPlanText: string;
  flightTracking: FlightTracking;
  flightTypeCode: string;
  inactiveReasonCode: InactiveReasonsType;
  lineOfFlight: any[];
  loadPlannerId: string;
  minimumLastAllowableTakeoffUtcTs: string;
  minimumLastAllowableBlockInUtcTs: string;
  operatingCarrierCode: string;
  originAirport: Airport;
  originalEquipment: OriginalEquipment;
  passengerDoorCloseUtcTs: string;
  scheduledAircraftTypes: OwnedAircraftType[];
  scheduledArrivalGateId: string;
  scheduledDepartureGateId: string;
  scheduledFlightMovementEventTimes: FlightMovementEventTimes;
  usInternationalDomesticCode: string;
  maintenanceReadyTime?: string; // UI Only
  arrivalVariance?: string; // UI Only
  departureVariance?: string; // UI Only
  pushBackUtcTs: string;
  taxiStartUtcTs: string;
  dispatcherPhoneNum: string;
  tbfmDepartureControlUtcTs: string;

  bestTimes?: {
    bestArrivalLocalTs?: string;
    bestArrivalUtcTs?: string;
    bestDepartureLocalTs?: string;
    bestDepartureUtcTs?: string;

    inBlockLocal?: string;
    inBlockReasonCode?: string;
    inBlockUtc?: string;
    inLocal?: string;
    inReasonCode?: string;
    inUtc?: string;

    ofFBlockReasonCode?: string;
    offBlockLocal?: string;
    offBlockUtc?: string;
    offLocal?: string;
    offReasonCode?: string;
    offUtc?: string;

    onBlockLocal?: string;
    onBlockReasonCode?: string;
    onBlockUtc?: string;
    onLocal?: string;
    onReasonCode?: string;
    onUtc?: string;

    outBlockLocal?: string;
    outBlockReasonCode?: string;
    outBlockUtc?: string;
    outLocal?: string;
    outReasonCode?: string;
    outUtc?: string;
  };
}

export class FlightLegClass implements FlightLeg {
  public actualArrivalGateId: string;
  public actualDepartureGateId: string;
  public internalArrivalGateId: string;
  public internalDepartureGateId: string;
  public actualFlightMovementEventTimes: FlightMovementEventTimes;
  public actualLineOfFlightSequenceNum: number;
  public bestFlightMovementEventTimes: FlightMovementEventTimes;
  public aircraft: Aircraft;
  public boardingStartUtcTs: string;
  public callSignId: string;
  public cloaked: boolean;
  public codeShareFlights: CodeShareFlight[];
  public correctiveActionRequired: boolean;
  public correctiveActionUpdateUtcTs: string;
  public creationReasonCode: CreationsReasonsType;
  public currentFlightLegStateCode: FlightLegStateCodeType;
  public destinationAirport: Airport;
  public dispatcherDeskId: string;
  public estimatedDepartureControlUtcTs: string;
  public estimatedFlightMovementEventTimes: FlightMovementEventTimes;
  public flightId: string;
  public flightIdentifierChanges: FlightIdentifierChange[];
  public flightLegStateChanges: FlightLegStateChange[];
  public flightNum: string;
  public flightOriginDate: string;
  public flightPlanText: string;
  public flightplan: Flightplan;
  public flightTracking: FlightTracking;
  public flightTypeCode: string;
  public inactiveReasonCode: InactiveReasonsType;
  public lineOfFlight: any[];
  public loadPlannerId: string;
  public minimumLastAllowableTakeoffUtcTs: string;
  public minimumLastAllowableBlockInUtcTs: string;
  public operatingCarrierCode: string;
  public originAirport: Airport;
  public originalEquipment: OriginalEquipment;
  public passengerDoorCloseUtcTs: string;
  public scheduledAircraftTypes: OwnedAircraftType[];
  public scheduledArrivalGateId: string;
  public scheduledDepartureGateId: string;
  public scheduledFlightMovementEventTimes: FlightMovementEventTimes;
  public usInternationalDomesticCode: string;
  public maintenanceReadyTime?: string; // UI Only
  public arrivalVariance?: string; // UI Only
  public departureVariance?: string; // UI Only
  pushBackUtcTs: string;
  taxiStartUtcTs: string;
  dispatcherPhoneNum: string;
  tbfmDepartureControlUtcTs: string;

  public bestTimes?: {
    bestArrivalLocalTs?: string;
    bestArrivalUtcTs?: string;
    bestDepartureLocalTs?: string;
    bestDepartureUtcTs?: string;

    inBlockLocal?: string;
    inBlockReasonCode?: string;
    inBlockUtc?: string;
    inLocal?: string;
    inReasonCode?: string;
    inUtc?: string;

    ofFBlockReasonCode?: string;
    offBlockLocal?: string;
    offBlockUtc?: string;
    offLocal?: string;
    offReasonCode?: string;
    offUtc?: string;

    onBlockLocal?: string;
    onBlockReasonCode?: string;
    onBlockUtc?: string;
    onLocal?: string;
    onReasonCode?: string;
    onUtc?: string;

    outBlockLocal?: string;
    outBlockReasonCode?: string;
    outBlockUtc?: string;
    outLocal?: string;
    outReasonCode?: string;
    outUtc?: string;
  };

  // prettier-ignore
  constructor(obj?: Partial<FlightLeg>) {
    this.actualArrivalGateId = obj.actualArrivalGateId || null;
    this.actualDepartureGateId = obj.actualDepartureGateId || null;
    this.internalArrivalGateId = obj.internalArrivalGateId || null;
    this.internalDepartureGateId = obj.internalDepartureGateId || null;
    this.actualFlightMovementEventTimes = new FlightMovementEventTimesClass(obj.actualFlightMovementEventTimes);
    this.actualLineOfFlightSequenceNum = obj.actualLineOfFlightSequenceNum || null;
    this.aircraft = obj.aircraft || null;
    this.bestFlightMovementEventTimes = new FlightMovementEventTimesClass(obj.bestFlightMovementEventTimes);
    this.boardingStartUtcTs = obj.boardingStartUtcTs || null;
    this.callSignId = obj.callSignId || null;
    this.cloaked = obj.cloaked || false;
    this.codeShareFlights = obj.codeShareFlights || [];
    this.correctiveActionRequired = obj.correctiveActionRequired || false;
    this.correctiveActionUpdateUtcTs = obj.correctiveActionUpdateUtcTs || null;
    this.creationReasonCode = obj.creationReasonCode || null;
    this.currentFlightLegStateCode = obj.currentFlightLegStateCode || null;
    this.destinationAirport = obj.destinationAirport || null;
    this.dispatcherDeskId = obj.dispatcherDeskId || null;
    this.estimatedDepartureControlUtcTs = obj.estimatedDepartureControlUtcTs || null;
    this.estimatedFlightMovementEventTimes = new FlightMovementEventTimesClass(obj.estimatedFlightMovementEventTimes);
    this.flightId = obj.flightId || null;
    this.flightIdentifierChanges = obj.flightIdentifierChanges || null;
    this.flightLegStateChanges = obj.flightLegStateChanges || null;
    this.flightNum = obj.flightNum || null;
    this.flightOriginDate = obj.flightOriginDate || null;
    this.flightPlanText = obj.flightPlanText || null;
    this.flightplan = new FlightplanClass(obj.flightplan);
    this.flightTracking = obj.flightTracking || null;
    this.flightTypeCode = obj.flightTypeCode || null;
    this.inactiveReasonCode = obj.inactiveReasonCode || null;
    this.lineOfFlight = obj.lineOfFlight || null;
    this.loadPlannerId = obj.loadPlannerId || null;
    this.minimumLastAllowableTakeoffUtcTs = obj.minimumLastAllowableTakeoffUtcTs || null;
    this.minimumLastAllowableBlockInUtcTs = obj.minimumLastAllowableBlockInUtcTs || null;
    this.operatingCarrierCode = obj.operatingCarrierCode || null;
    this.originAirport = obj.originAirport || null;
    this.originalEquipment = obj.originalEquipment || null;
    this.passengerDoorCloseUtcTs = obj.passengerDoorCloseUtcTs || null;
    this.scheduledAircraftTypes = obj.scheduledAircraftTypes || null;
    this.scheduledArrivalGateId = obj.scheduledArrivalGateId || null;
    this.scheduledDepartureGateId = obj.scheduledDepartureGateId || null;
    this.scheduledFlightMovementEventTimes = new FlightMovementEventTimesClass(obj.scheduledFlightMovementEventTimes);
    this.usInternationalDomesticCode = obj.usInternationalDomesticCode || null;
    this.bestTimes = obj.bestTimes || null;
    this.arrivalVariance = this.getArrivalVariance(obj);
    this.departureVariance = this.getDepartureVariance(obj);
    this.maintenanceReadyTime = this.getMaintenanceReadyTime(obj);
    this.pushBackUtcTs = obj.pushBackUtcTs || null;
    this.taxiStartUtcTs = obj.taxiStartUtcTs || null;
    this.dispatcherPhoneNum = obj.dispatcherPhoneNum || null;
    this.tbfmDepartureControlUtcTs = obj.tbfmDepartureControlUtcTs || null;
  }

  private getArrivalVariance(obj?: Partial<FlightLeg>) {
    return this.calculateVariance(
      obj.actualFlightMovementEventTimes && obj.actualFlightMovementEventTimes.inEventUtcTs,
      obj.estimatedFlightMovementEventTimes && obj.estimatedFlightMovementEventTimes.inEventUtcTs,
      obj.scheduledFlightMovementEventTimes && obj.scheduledFlightMovementEventTimes.inEventUtcTs
    );
  }
  private getDepartureVariance(obj?: Partial<FlightLeg>) {
    return this.calculateVariance(
      obj.actualFlightMovementEventTimes && obj.actualFlightMovementEventTimes.outEventUtcTs,
      obj.estimatedFlightMovementEventTimes && obj.estimatedFlightMovementEventTimes.outEventUtcTs,
      obj.scheduledFlightMovementEventTimes && obj.scheduledFlightMovementEventTimes.outEventUtcTs
    );
  }

  private calculateVariance(actual: string, estimated: string, scheduled: string): string {
    let start: Moment;
    const end = moment(scheduled);
    // Prefer actual vs schedules
    if (actual) {
      start = moment(actual);
    }
    // If actual doesn't exist use estimated time
    if (estimated && !actual) {
      start = moment(estimated);
    }
    // Use moment to retrieve difference
    if (start && end) {
      const variance = moment.duration(start.diff(end)).format('h:mm', {
        trim: false,
      });
      // If variance is 0:00 do not prepend decorator
      if (variance === '0:00') {
        return variance;
      }
      // Prepend string with '+' if the variance is positive
      return variance[0] !== '-' ? `+${variance}` : variance;
    }

    return '0:00';
  }

  private getMaintenanceReadyTime(obj?: Partial<FlightLeg>) {
    // If there is no aircraft assigned OR if the flight has departed OR if the aircraft is IN service OR
    // if the aircraft is not OUT of service, there cannot be an MRT
    let maintenanceReadyTime;
    if (
      !obj.aircraft ||
      (obj.actualFlightMovementEventTimes && obj.actualFlightMovementEventTimes.outEventUtcTs) ||
      obj.aircraft.maintenanceInServiceUtcTs ||
      !obj.aircraft.maintenanceOutServiceUtcTs
    ) {
      maintenanceReadyTime = null;
      return maintenanceReadyTime;
    }

    // If there is a maintenance out
    if (obj.aircraft && obj.aircraft.maintenanceOutServiceUtcTs) {
      maintenanceReadyTime = obj.aircraft.maintenanceEstimatedReadyUtcTs
        ? moment.utc(obj.aircraft.maintenanceEstimatedReadyUtcTs).format('HH:mm[z] DDMMM')
        : 'PENDING';
      return maintenanceReadyTime;
    }

    // In all other cases assume that there is no MRT
    maintenanceReadyTime = null;
    return maintenanceReadyTime;
  }
}

export type FlightLegStateCodeType = 'Scheduled' | 'ShipAtGateBoarding' | 'TaxiOut' | 'InFlight' | 'TaxiIn' | 'AtGate' | 'Inactive';
// | 'N/A'; not a valid status

export type InactiveReasonsType =
  | 'Canceled'
  | 'Deleted'
  | 'Diversion'
  | 'NonContinuingDiversion'
  | 'Flagstop'
  | 'Stubbed'
  | 'Overfly'
  | 'ChangedOrigin'
  | 'ChangedDestination'
  | 'Active';

export type CreationsReasonsType =
  | 'Scheduled'
  | 'Operations'
  | 'ReturnToGate'
  | 'AirReturn'
  | 'Diversion'
  | 'NonContinuingDiversion'
  | 'DiversionRecovery'
  | 'Flagstop'
  | 'Stubbed'
  | 'Overfly'
  | 'ChangedOrigin'
  | 'ChangedDestination';

export interface OriginalEquipment {
  active: boolean;
  aircraftTypeCode: string;
  awabsAircraftTypeCode: string;
  bodyTypeCode: string;
  engineTypeCode: string;
  firstClassSeatCnt: number;
  fleetTypeCode: string;
  fpsAircraftTypeCode: string;
  gacAircraftTypeCode: string;
  icaoAircraftTypeCode: string;
  jvmAircraftTypeCode: string;
  mainCabinSeatCnt: number;
  maintenanceAirportCode: string;
  marketingAircraftTypeCode: string;
  operatedByCarrierCode: string;
  registrationNum: string;
  selectiveCallingCode: string;
  shipNum: number;
  subFleetTypeCode: string;
  totalSeatCnt: number;
}

export class OriginalEquipmentClass implements OriginalEquipment {
  public active: boolean;
  public aircraftTypeCode: string;
  public awabsAircraftTypeCode: string;
  public bodyTypeCode: string;
  public engineTypeCode: string;
  public firstClassSeatCnt: number;
  public fleetTypeCode: string;
  public fpsAircraftTypeCode: string;
  public gacAircraftTypeCode: string;
  public icaoAircraftTypeCode: string;
  public jvmAircraftTypeCode: string;
  public mainCabinSeatCnt: number;
  public maintenanceAirportCode: string;
  public marketingAircraftTypeCode: string;
  public operatedByCarrierCode: string;
  public registrationNum: string;
  public selectiveCallingCode: string;
  public shipNum: number;
  public subFleetTypeCode: string;
  public totalSeatCnt: number;

  constructor(obj: Partial<OriginalEquipment>) {
    this.active = obj.active || null;
    this.aircraftTypeCode = obj.aircraftTypeCode || null;
    this.awabsAircraftTypeCode = obj.awabsAircraftTypeCode || null;
    this.bodyTypeCode = obj.bodyTypeCode || null;
    this.engineTypeCode = obj.engineTypeCode || null;
    this.firstClassSeatCnt = obj.firstClassSeatCnt || null;
    this.fleetTypeCode = obj.fleetTypeCode || null;
    this.fpsAircraftTypeCode = obj.fpsAircraftTypeCode || null;
    this.gacAircraftTypeCode = obj.gacAircraftTypeCode || null;
    this.icaoAircraftTypeCode = obj.icaoAircraftTypeCode || null;
    this.jvmAircraftTypeCode = obj.jvmAircraftTypeCode || null;
    this.mainCabinSeatCnt = obj.mainCabinSeatCnt || null;
    this.maintenanceAirportCode = obj.maintenanceAirportCode || null;
    this.marketingAircraftTypeCode = obj.marketingAircraftTypeCode || null;
    this.operatedByCarrierCode = obj.operatedByCarrierCode || null;
    this.registrationNum = obj.registrationNum || null;
    this.selectiveCallingCode = obj.selectiveCallingCode || null;
    this.shipNum = obj.shipNum || null;
    this.subFleetTypeCode = obj.subFleetTypeCode || null;
    this.totalSeatCnt = obj.totalSeatCnt || null;
  }
}
export class FlifoTimerestriction {
  // block
  blockBaseCity: string;
  blockRotationDate: string;
  blockPairingNumber: string;
  blockRotationDupNumber: string;
  blockPositionType: string;
  blockLattUtcTs: string;
  blockLabitUtcTs: string;
  blockMaxTaxiTimeInMinutes: number;
  estimatedBlockMinutes: number;
  maximumBlockMinutes: number;
  bufferBlockMinutes: number;
  blockBufferRestMinutes: number;
  // Duty
  dutyBaseCity: string;
  dutyRotationDate: string;
  dutyPairingNumber: string;
  dutyRotationDupNumber: string;
  dutyPositionType: string;
  dutyLattWithExtUtcTs: string;
  dutyLabitWithExtUtcTs: string;
  dutyMaxTaxiTimeInMinutes: number;
  estimatedDutyMinutes: number;
  maximumDutyMinutes: number;
  bufferDutyMinutes: number;
  dutyBufferRestMinutes: number;
}
