import * as SidebarActions from '../../store/actions/sidebar/sidebar.actions';
import { Injectable } from '@angular/core';
import { ConfigService } from '../../services/config/config.service';
import { NavItem } from '../../models/nav-item.model';

@Injectable({
  providedIn: 'root',
})
export class NavRoutesService {
  public navRoutes: NavItem[];

  constructor(private config: ConfigService) {
    const featureFlags = config.getSettings('feature-flags');
    const showDiversionManagement =
      featureFlags && featureFlags['diversionManagement'] ? featureFlags['diversionManagement']['routing'] : false;
    this.navRoutes = [
      // Leaving this here, we might add it back later
      // {
      //   displayName: 'My Dashboard',
      //   iconName: 'icon-guage',
      //   access: 'myDashboard',
      //   route: '/',
      //   children: [],
      // },
      {
        displayName: 'Flight List',
        iconName: 'icon-list',
        access: 'flightList',
        dispatch: new SidebarActions.ToggleFlightList(),
        outlet: {
          sidebar: 'flight-list',
        },
        children: [],
      },
      {
        displayName: 'Situational Awareness',
        iconName: 'icon-warning',
        access: 'situationalAwareness',
        route: null,
        children: [
          {
            displayName: 'ATC',
            iconName: '',
            access: 'airTrafficControl',
            route: '/air-traffic-control',
          },
          {
            displayName: 'Station Throughput',
            iconName: '',
            route: '/throughput',
            access: 'stationThroughput',
          },
          {
            displayName: 'Deice',
            iconName: '',
            route: '/deice',
            access: 'deice',
          },
          ...(showDiversionManagement
            ? [
              {
                displayName: 'Diversion Management Tool',
                iconName: '',
                route: '/diversionManagement',
                access: 'diversionManagement',
              },
            ]
            : []),
        ],
      },
      {
        displayName: 'Decision Support',
        iconName: 'icon-float',
        access: 'decisionSupport',
        route: null,
        children: [
          {
            displayName: 'Gate Capacity',
            iconName: '',
            route: '/capacity',
            access: 'gateCapacity',
          },
        ],
      },
      {
        displayName: 'Advanced Search',
        iconName: 'icon-search-add',
        access: 'advancedSearch',
        route: '/advanced-search',
        children: [],
      },
      {
        displayName: 'IROP Replay',
        iconName: 'icon-play',
        access: 'iropReplay',
        route: '/irop-replay',
        children: [],
      },
      {
        displayName: 'Severe Weather Index',
        iconName: 'icon-gust',
        access: 'severeWeatherIndex',
        route: '/severe-weather-index',
        children: [],
      },
      {
        displayName: 'WIT',
        iconName: 'icon-cloud',
        access: 'wit',
        externalLink: 'wit',
        children: [],
      },
      {
        displayName: 'Admin',
        iconName: 'icon-shield',
        access: 'admin',
        route: null,
        children: [
          {
            displayName: 'Policy Engine',
            iconName: '',
            route: '/policy-engine',
            access: 'policyEngine',
          },
          {
            displayName: 'User Access',
            iconName: '',
            route: '/user-access',
            access: 'userAccess',
          },
          {
            displayName: 'JV Gates',
            iconName: '',
            route: '/jv-gates',
            access: 'jvGates',
          },
          {
            displayName: 'Operational Codes',
            iconName: '',
            route: '/operational-codes',
            access: 'operationalCodes',
          },

        ],
      },
      {
        displayName: 'External Links',
        iconName: 'icon-share',
        access: 'externalLinks',
        route: null,
        children: [
          {
            displayName: 'ODT',
            iconName: '',
            route: '/odt',
            access: 'odt',
          },
          {
            displayName: 'BDT',
            iconName: '',
            route: '/bdt',
            access: 'bdt',
          },
          {
            displayName: 'OMS',
            iconName: '',
            route: '/oms',
            access: 'oms',
          },
          {
            displayName: 'Diversion Monitor',
            iconName: '',
            route: '/diversion',
            access: 'diversionMonitor',
          },
          {
            displayName: 'FPS',
            iconName: '',
            route: '/fps',
            access: 'fps',
          },
        ],
      },
      {
        displayName: 'Help',
        iconName: 'icon-question',
        route: '/help',
        children: [],
        access: 'help',
      },
      {
        displayName: 'Send Feedback',
        iconName: 'icon-mail',
        externalLink: 'feedbackEmailLink',
        children: [],
      },
    ];
  }
}
