import { Component, OnInit, Input, Output, EventEmitter, ViewChildren, QueryList, OnChanges, ChangeDetectorRef } from '@angular/core';
import { TableColumnData, TableColumn } from '../../models/reports';
import { SortDirection } from '@angular/material/sort';
import { SortEvent, compare } from '../../utils/report-util';
import { SortableHeader } from '../../directives/sortable-header.directive';

import * as _ from 'lodash';

@Component({
  selector: 'emt-advanced-report-template',
  templateUrl: './advanced-report-template.component.html',
  styleUrls: ['./advanced-report-template.component.scss'],
})
export class AdvancedReportTemplateComponent implements OnInit, OnChanges {
  @Input() data: any;
  @Input() tableHeaders: TableColumnData;
  @Input() sortable: string;
  @Input() direction: SortDirection = '';
  @Input() errorText: string;
  @Output() sort = new EventEmitter<SortEvent>();
  @Output() rowClickedEvent = new EventEmitter<any>();

  @ViewChildren(SortableHeader) headers: QueryList<SortableHeader>;

  // public columns: any;
  public sortEvent: SortEvent;
  public sortEventAnotherColumn: SortEvent;
  private row: any;
  private rawData: [];
  public loadingRecords = false;

  constructor(public cdr: ChangeDetectorRef) {}

  ngOnInit() {
    //fix for loader to show only on load
    this.loadingRecords = true;
    this.loadData();
  }

  ngOnChanges(): void {
    this.loadData();
    this.loadingRecords = false;
  }

  public onRowClick(rowData){
    this.rowClickedEvent.emit(rowData);
  }

  private loadData() {
    if (this.data && this.tableHeaders && this.tableHeaders.columns && this.tableHeaders.columns.length) {    
      this.loadingRecords = false;
      this.prepareColumn();
      const defaultSortColumn = _.find(this.tableHeaders.columns, item => item.isDefaultSort);
      if (defaultSortColumn && !this.sortEvent) {
        this.sortEvent = {
          column: defaultSortColumn.column,
          direction: defaultSortColumn.sortDeirection,
        };
      }
      if (this.sortEvent) {
        this.onSort(this.sortEvent);
      }
    }

  }

  private prepareColumn() {
    this.rawData = this.data;
  }

  public onSort({ column, direction }: SortEvent) {
    let sortColumn = column;
    const sortByColumn = _.find(this.tableHeaders.columns, item => item.column === column);
    this.sortEvent = { column, direction };
    sortColumn = sortByColumn.sortbyColumn ? sortByColumn.sortbyColumn : sortColumn;

    // resetting other headers
    if (this.headers) {
      this.headers.forEach(header => {
        if (header.sortable !== column) {
          header.direction = '';
        }
      });
    }

    // sorting countries
    if (direction === '') {
      this.data = this.rawData;
    } else {
      this.data = _.orderBy(this.rawData, item => item[sortColumn], direction);
    }
  }

  getStyles(header: TableColumn){
    let styles: { width?: string } = {};
    //style to set inbound and outbound width
    styles.width = ((header.column == 'Inbound Flight Leg') || (header.column == 'Outbound Flight Leg') || (header.column == 'Duty LABIT')) ? header.width*3 + '%' : header.width/1.5 + '%'
    return styles;
  }
}
