import { createSelector } from '@ngrx/store';

import { AppState } from '../..';
import * as fromSidebar from '../../reducers/sidebar/sidebar.reducer';

const selectNavigation = (state: AppState) => state.sidebarState;

export const selectFlightListExpanded = createSelector(
  selectNavigation,
  (state: fromSidebar.State) => state.FlightListExpanded
);

export const selectSidebarState = createSelector(
  selectNavigation,
  (state: fromSidebar.State) => state.SidebarExpanded
);
