import { Component, OnInit } from '@angular/core';
import * as moment_ from 'moment';
import 'moment-duration-format';
import { AlertEvent } from '../../models/alert-event.model';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { AppState } from '../../store';
import { selectAllAlerts } from '../../store/selectors/alert/alert.selectors';
const moment = moment_;

@Component({
  selector: 'emt-alerts-list',
  templateUrl: './alerts-list.component.html',
  styleUrls: ['./alerts-list.component.scss'],
})
export class AlertsListComponent implements OnInit {
  alerts$: Observable<AlertEvent[]>;

  constructor(private store: Store<AppState>) {}

  showTime(time: string) {
    return moment
      .duration(
        moment()
          .utc()
          .diff(moment.utc(time))
      )
      .humanize();
  }

  getAlertColor(alert: AlertEvent) {
    let severity = alert.severity.toLowerCase();
    switch (severity) {
      case 'critical':
        return '#9927c7';
      case 'high':
        return '#da4949';
      default:
        break;
    }
    return '#000000';
  }

  ngOnInit() {
    this.alerts$ = this.store.pipe(select(selectAllAlerts));
  }

  alertTrackByFn(index, item) {
    return item['createdDateTimeUUID'] + index;
  }
}
