import { Action } from '@ngrx/store';
import { AlertEvent } from '../../../models/alert-event.model';
import { HttpErrorResponse } from '@angular/common/http';

export enum AlertActionTypes {
  LOAD_ALERTS = '[Alert] Load Alerts',
  LOAD_ALERTS_SUCCESS = '[Alert] Load Alerts Success',
  LOAD_ALERTS_FAIL = '[Alert] Load Alerts Fails',
  DELETE_ALERTS = '[Alert] Delete Alerts',
  DELETE_ALERTS_SUCCESS = '[Alert] Delete Alerts Success',
  DELETE_ALERTS_FAIL = '[Alert] Delete Alerts Fail',
  DELETE_ALERT = '[Alert] Delete Alert',
  DELETE_ALERT_SUCCESS = '[Alert] Delete Alert Success',
  DELETE_ALERT_FAIL = '[Alert] Delete Alert Fail',
}

export class LoadAlertsAction implements Action {
  readonly type = AlertActionTypes.LOAD_ALERTS;
  constructor() {}
}

export class LoadAlertsSuccessAction implements Action {
  readonly type = AlertActionTypes.LOAD_ALERTS_SUCCESS;
  constructor(readonly payload: AlertEvent[]) {}
}

export class LoadAlertsFailAction implements Action {
  readonly type = AlertActionTypes.LOAD_ALERTS_FAIL;
  constructor(error: HttpErrorResponse) {}
}

export class DeleteAlertsAction implements Action {
  readonly type = AlertActionTypes.DELETE_ALERTS;
  constructor() {}
}

export class DeleteAlertsSuccessAction implements Action {
  readonly type = AlertActionTypes.DELETE_ALERTS_SUCCESS;
  constructor() {}
}

export class DeleteAlertsFailAction implements Action {
  readonly type = AlertActionTypes.DELETE_ALERTS_FAIL;
  constructor(errMsg: string) {}
}

export class DeleteAlertAction implements Action {
  readonly type = AlertActionTypes.DELETE_ALERT;
  constructor(readonly payload: AlertEvent) {}
}

export class DeleteAlertSuccessAction implements Action {
  readonly type = AlertActionTypes.DELETE_ALERT_SUCCESS;
  constructor(readonly payload: AlertEvent) {}
}

export class DeleteAlertFailAction implements Action {
  readonly type = AlertActionTypes.DELETE_ALERT_FAIL;
  constructor(errMsg: string) {}
}

export type Actions =
  | LoadAlertsAction
  | LoadAlertsSuccessAction
  | LoadAlertsFailAction
  | DeleteAlertsAction
  | DeleteAlertsSuccessAction
  | DeleteAlertsFailAction
  | DeleteAlertAction
  | DeleteAlertSuccessAction
  | DeleteAlertFailAction;
