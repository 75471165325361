<div class="main">
  <div class="flight-list" *ngIf="flightList$ | async">
    <router-outlet name="sidebar"></router-outlet>
  </div>
  <div class="body" [ngClass]="{'no-margin-top': isReportOpened, 'flight-list-expanded': flightList$ | async}">
    <emt-airplane-loader fullScreen="true" *ngIf="isLoading$ | async"></emt-airplane-loader>
    <emt-tab-bar [hidden]="isReportOpened"></emt-tab-bar>
    <router-outlet></router-outlet>
  </div>
</div>
