import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { ReportType, MetricCard, MetricCardColor, MetricCardCollection } from '../../models/metric-cards.model';

@Injectable()
export class MetricCardService {
  private subject: Subject<string>;
  private reportTypeSubject: Subject<ReportType>;

  constructor() {
    this.subject = new Subject<string>();
    this.reportTypeSubject = new Subject<ReportType>();
  }

  setSelectedCard = (selectedTime: string) => {
    this.subject.next(selectedTime);
  };

  getSelectedCard = (): Observable<string> => this.subject.asObservable();

  setSelectedReportType = (reportType: ReportType) => {
    this.reportTypeSubject.next(reportType);
  };

  getSelectedReportType = (): Observable<ReportType> => this.reportTypeSubject.asObservable();

  public generateMetricCard(
    cardId: string,
    cardTitle: string,
    cardValue: number | string,
    reportType: ReportType = undefined,
    width = '',
    cardColor = MetricCardColor.default,
    cardClickable = false,
    cardClickText?: string,
    cardTopCircleColor?: string
  ) {
    const metricCard = new MetricCard();
    metricCard.id = cardId;
    metricCard.title = cardTitle;
    metricCard.width = width;
    metricCard.value = cardValue;
    metricCard.cardColor = cardColor;
    metricCard.reportType = reportType;
    metricCard.clickable = cardClickable;
    metricCard.clickText = cardClickText;
    metricCard.topCircleColor = cardTopCircleColor;

    return metricCard;
  }

  public generateMetricCardThenAddToCollection(
    cardCollection: MetricCardCollection,
    cardId: string,
    cardTitle: string,
    cardValue: number | string,
    reportType: ReportType = undefined,
    width = '',
    cardColor = MetricCardColor.default,
    cardClickable = false,
    cardClickText?: string,
    cardTopCircleColor?: string,
    noBorderLine?: boolean,
    isValue2Available = false,
    value2 = undefined,
    value2Error = false
  ) {
    const metricCard = new MetricCard();
    metricCard.id = cardId;
    metricCard.title = cardTitle;
    metricCard.width = width;
    metricCard.value = cardValue;
    metricCard.cardColor = cardColor;
    metricCard.reportType = reportType;
    metricCard.clickable = cardClickable;
    metricCard.clickText = cardClickText;
    metricCard.topCircleColor = cardTopCircleColor;
    metricCard.noBorderLine = noBorderLine;
    
    metricCard.isValue2Available = isValue2Available;
    if (isValue2Available) {
      metricCard.value2 = value2;
      metricCard.value2Error = value2Error;
    }
    cardCollection.metricCards.push(metricCard);
  }

}
