import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { ConfigService } from '../../services/config/config.service';
import { StationSelectorDialogComponent } from 'projects/common/src/public_api';
import { Observable, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Airport } from '../../feature-modules/station-throughput/models/airport';
import { NavItem } from '../../models/nav-item.model';
import { AuthService } from '../../services/auth/auth.service';
import { RetractSidebar } from '../../store/actions/sidebar/sidebar.actions';
import { ToggleSidebar } from '../../store/actions/sidebar/sidebar.actions';
import { LoadStationsAction } from '../../store/actions/station/station.actions';
import { getStationsLoaded } from '../../store/reducers/station/station.reducers';
import { selectFlightListExpanded, selectSidebarState } from '../../store/selectors/sidebar/sidebar.selectors';
import { getAllStations } from '../../store/selectors/station/station.selectors';
import { NavRoutesService } from './nav-routes.service';
import { DataService } from '../../services/dataService/data-service.service';

const regex = /\((((?!(\/\/)).)*)\)/g; // removing auxiliary route from url for matching

@Component({
  selector: 'emt-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
})
export class SideNavComponent implements OnInit, OnDestroy {
  public showSideNav$: Observable<boolean>;
  public navItems: NavItem[] = [];
  public ngUnsubscribe = new Subject();
  private stationsLoaded = false;
  public authorization$: Subscription;
  private stations: Airport[];
  activePath: string;
  flightListExpanded: boolean;
  private routerSubscription: Subscription;

  constructor(
    private store: Store<any>,
    private dataService: DataService,
    private authService: AuthService,
    private configService: ConfigService,
    private router: Router,
    private modalService: NgbModal,
    private navRoutesService: NavRoutesService
  ) {
    this.showSideNav$ = this.store.select(selectSidebarState);
  }

  toggleSideNav() {
    this.store.dispatch(new ToggleSidebar());
  }

  ngOnInit() {
    this.store
      .select(getStationsLoaded)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(loaded => {
        if (!this.stationsLoaded) {
          this.store.dispatch(new LoadStationsAction());
          this.stationsLoaded = loaded;
        }
      });

    this.store
      .select(getAllStations)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((stations: Airport[]) => {
        if (!this.stations && stations && stations.length) {
          this.stations = stations;
        }
      });

    this.store
      .select(selectFlightListExpanded)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((flightList: boolean) => {
        this.flightListExpanded = flightList;
      });

    this.routerSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (event.url) {
          this.activePath = event.url.replace(regex, '');
        }
      }
    });

    this.authorization$ = this.authService.authorizationSubject.subscribe(auth => {
      if (auth && auth.sub) {
        const authMap = JSON.parse(auth.sub);
        const fullNav: NavItem[] = this.navRoutesService.navRoutes;
        const navItems = fullNav.reduce((total, navItem) => {
          // Check if external links
          if (navItem.externalLink) {
            navItem.route = this.configService.getSettings(navItem.externalLink);
          }
          if (navItem.children && navItem.children.length > 0 && navItem.access) {
            const navChildren = navItem.children.filter(navChild => {
              if (navChild.access && authMap[navChild.access]) {
                return authMap[navChild.access].access;
              }
              return false;
            });
            let newNavitem = { ...navItem, children: navChildren };
            if (newNavitem.children && newNavitem.children.length > 0) {
              total = [...total, newNavitem];
            }
          } else if (navItem.access && authMap[navItem.access] && authMap[navItem.access].access) {
            total = [...total, navItem];
          }
          return total;
        }, []);
        this.navItems = [
          ...navItems,
          this.navRoutesService.navRoutes[this.navRoutesService.navRoutes.length - 2],
          this.navRoutesService.navRoutes[this.navRoutesService.navRoutes.length - 1],
        ];
      }
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.ngUnsubscribe.unsubscribe();
    this.routerSubscription.unsubscribe();
    this.authorization$.unsubscribe();
  }

  onItemSelected(item: NavItem) {
    if (item.externalLink) {
      if (item.route.startsWith('mailto:')) {
        window.open(item.route, '_self');
      } else {
        window.open(item.route);
      }
    } else {
      if (['/deice', '/throughput', '/jv-gates'].includes(item.route)) {
        const modalRef: NgbModalRef = this.modalService.open(StationSelectorDialogComponent, { windowClass: 'customModalClass' });
        modalRef.componentInstance.modalTitle = item.displayName;
        modalRef.componentInstance.stations = this.stations;

        modalRef.result.then(result => {
          if (result) {
            this.router.navigate([item.route, result]);
          }
        });
      } else {
        if (item.dispatch) {
          this.store.dispatch(item.dispatch);
        }

        if (item.outlet) {
          if (this.flightListExpanded) {
            this.router.navigate([{ outlets: item.outlet }], { queryParamsHandling: 'preserve', state: { ignoreRouteChange: true } });
          } else {
            this.router.navigate([{ outlets: { sidebar: null } }], { queryParamsHandling: 'preserve', state: { ignoreRouteChange: true } });
          }
        } else {
          if (item.route === '/integrated-crew-hub') {
            this.router.navigate([item.route]).then(() => {
              this.dataService.setNotifyTabCreated('newTabCreated');
            });
          } else {
            this.router.navigate([item.route]);
          }
        }
      }

      this.store.dispatch(new RetractSidebar());
    }
  }
}
