import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'emt-dropdown-simple',
  templateUrl: './emt-dropdown-simple.component.html',
  styleUrls: ['./emt-dropdown-simple.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DropdownSimpleComponent implements OnInit {
  @ViewChild('ngSelect') ngSelect: NgSelectComponent;
  open = false;
  @Input() control: FormControl;
  @Input() data: [
    {
      label: string;
      value: string;
      description: string;
    }
  ];

  ngOnInit(): void {
    this.ngSelect.openEvent.subscribe(event => (this.open = true));
    this.ngSelect.closeEvent.subscribe(event => (this.open = false));
  }

  toggle() {
    if (this.open) {
      this.ngSelect.close();
    } else {
      this.ngSelect.open();
    }
  }
}
