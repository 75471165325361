<div class="flight-list-selector-dialog">
  <div class="modal-header">
    <h4 class="modal-title">{{ modalTitle }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="cancelDialog()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body flight-selector-content">
    <section class="selector-title">
      <div class="flight-selector-title">Please select a station.</div>
    </section>

    <section class="station-selector">
      <div class="flight-selector-form-label">Station</div>
      <ng-select
        class="custom-station-select"
        virtualScroll="true"
        ngDefaultControl
        [items]="stations"
        bindLabel="airportCode"
        [closeOnSelect]="true"
        bindValue="airportCode"
        [(ngModel)]="selectedStations"
        [ngClass]="{ 'flight-list-select-inactive': !!!selectedStations, 'flight-list-select-active': !!selectedStations }"
      >
        {{ stations }}
      </ng-select>
    </section>

    <section class="action-buttons">
      <div class="submit-button">
        <emt-button text="Submit" (click)="submitDialog()"></emt-button>
      </div>
      <div class="cancel-button">
        <emt-button text="Cancel" styleType="cancel" (click)="cancelDialog()"></emt-button>
      </div>
    </section>
  </div>
</div>
