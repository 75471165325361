import { Action } from '@ngrx/store';

export const TOGGLE_FLIGHT_LIST = '[Navigation] Toggle Flight List';
export const EXPAND_FLIGHT_LIST = '[Navigation] Expand Flight List';
export const RETRACT_FLIGHT_LIST = '[Navigation] Retract Flight List';

export const TOGGLE_SIDEBAR = '[Sidebar] Toggle Sidebar';
export const EXPAND_SIDEBAR = '[Sidebar] Expand Sidebar';
export const RETRACT_SIDEBAR = '[Sidebar] Retract Sidebar';

export class ToggleFlightList implements Action {
  readonly type = TOGGLE_FLIGHT_LIST;
  constructor() {}
}

export class ExpandFlightList implements Action {
  readonly type = EXPAND_FLIGHT_LIST;
  constructor() {}
}

export class RetractFlightList implements Action {
  readonly type = RETRACT_FLIGHT_LIST;
  constructor() {}
}

export class ToggleSidebar implements Action {
  readonly type = TOGGLE_SIDEBAR;
  constructor() {}
}

export class ExpandSidebar implements Action {
  readonly type = EXPAND_SIDEBAR;
  constructor() {}
}

export class RetractSidebar implements Action {
  readonly type = RETRACT_SIDEBAR;
  constructor() {}
}

export type Actions = ToggleFlightList | ExpandFlightList | RetractFlightList | ToggleSidebar | ExpandSidebar | RetractSidebar;
