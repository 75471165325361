import { Component, HostListener, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { QuickSearchService } from '../../services/quick-search/quick-search.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import * as moment_ from 'moment';
const moment = moment_;

@Component({
  selector: 'emt-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
})
export class SearchInputComponent implements OnDestroy {
  @ViewChild('searchTextField') searchTextFieldEl: ElementRef;
  public loading = false;
  public searchForm = new FormGroup({
    searchOption: new FormControl('flight'),
    searchText: new FormControl(''),
  });
  private flightSearchResultsSubscription: Subscription;

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.code === 'F2' || event.key === 'F2') {
      this.searchForm.patchValue({
        searchOption: 'flight',
      });
      this.searchTextFieldEl.nativeElement.focus();
    } else if (event.code === 'F3' || event.key === 'F3') {
      this.searchForm.patchValue({
        searchOption: 'ship',
      });
      this.searchTextFieldEl.nativeElement.focus();
    }
  }

  constructor(private quickSearchService: QuickSearchService, private router: Router) {}

  ngOnDestroy() {
    if (this.flightSearchResultsSubscription) {
      this.flightSearchResultsSubscription.unsubscribe();
    }
  }

  doSearch() {
    this.quickSearchService.isLoading.next(true);
    let today = moment();
    if (this.searchForm.get('searchOption').value === 'flight') {
      let queryText = this.searchForm.value.searchText;
      if (!queryText.includes("/")) {
        queryText = queryText.concat("/" + today.date());
      }
      this.quickSearchService.searchFlight(queryText);
      this.flightSearchResultsSubscription = this.quickSearchService.flightSearchResult.subscribe(leg => {
        let flightId = leg['flightId'];
        let title = `${leg['operatingCarrierCode']}${leg['flightNum']}`;
        let date = leg['flightOriginDate'];
        this.router.navigate([`/flight-info/${flightId}/history`], { queryParams: { t: title, d: date } });
      });
    } else {
      this.router.navigate(['/advanced-search/results'], {
        queryParams: {
          shipNo: this.searchForm.value.searchText,
          originDateFrom: today.format("YYYY-MM-DD[T]HH:mm:ss"),
          originDateTo: today.format("YYYY-MM-DD[T]HH:mm:ss"),
          timeDisplayIn: 'zulu'
        },
      });
      this.quickSearchService.isLoading.next(false);
    }

    this.resetSearchText();
  }

  numberOnly(event): boolean {
    // Allows only numbers to be entered for ship quick search
    if (this.searchForm.get('searchOption').value === 'ship') {
      const charCode = event.which ? event.which : event.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
      }
    }
  }

  resetSearchText() {
    this.searchForm.patchValue({
      searchText: '',
    });
  }

  getMaxLength() {
    if (this.searchForm.get('searchOption').value === 'ship') {
      return 4;
    }
    return 10;
  }
}
