import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of, defer } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import * as AlertActions from '../../actions/alert/alert.actions';
import { HttpErrorResponse } from '@angular/common/http';
import { AlertEvent } from '../../../models/alert-event.model';
import { Action } from '@ngrx/store';
import { AlertEventService } from 'projects/common/src/public_api';

@Injectable()
export class AlertEffects {
  constructor(private actions$: Actions, private alertEventService: AlertEventService) {}

  @Effect()
  loadAlerts$: Observable<Action> = defer(() =>
    this.actions$.pipe(
      ofType(AlertActions.AlertActionTypes.LOAD_ALERTS),
      switchMap((state: AlertActions.LoadAlertsAction) => {
        return this.alertEventService.getAlerts().pipe(
          // If successful, dispatch success action with result
          map((alerts: AlertEvent[]) => new AlertActions.LoadAlertsSuccessAction(alerts)),
          // If request fails, dispatch failed action
          catchError((err: HttpErrorResponse) => of(new AlertActions.LoadAlertsFailAction(err)))
        );
      })
    )
  );
}
