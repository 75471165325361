<table class="default-report-template-table">
  <thead>
    <tr class="header-row">
      <th class="table-header" [sortable]="item" (sort)="onSort($event)" *ngFor="let item of columns">
        <div class="column-wrapper">
          <div class="header-name">
            <span>
              {{ item.replace('_', '') }}
            </span>
            <span class="sort-indicator" [ngSwitch]="sortEvent.direction" *ngIf="sortEvent && sortEvent.column === item">
              <p *ngSwitchCase="'asc'">
                ↓
              </p>
              <p *ngSwitchCase="'desc'">
                ↑
              </p>
            </span>
          </div>
        </div>
      </th>
    </tr>
  </thead>
  <tbody>
    <tr class="table-row" *ngFor="let row of data">
      <td class="table-column" *ngFor="let column of columns" [ngStyle]="{ width: 100 / columns.length + '%' }">
        {{ row[column] }}
      </td>
    </tr>
  </tbody>
</table>
