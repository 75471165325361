<div class="select-role-wrapper">
  <h4>
    Please Select Your Role
  </h4>
  <emt-dropdown-v3 class="role-dropdown" [placeholder]="'User Role'" [options]="roles"
    (valueChanged)="roleSelected($event)"></emt-dropdown-v3>
  <button [disabled]="!this.selectedRole" [ngClass]="{ disabled: !this.selectedRole }" class="submit-button"
    (click)="submit()">
    Submit
  </button>
</div>