import { Injectable } from '@angular/core';
import { NgbDateStruct, NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import * as moment_ from 'moment';

const moment = moment_;

export function toInteger(value: any): number {
  return parseInt(`${value}`, 10);
}

export function toString(value: any): string {
  return value !== undefined && value !== null ? `${value}` : '';
}

export function getValueInRange(value: number, max: number, min = 0): number {
  return Math.max(Math.min(value, max), min);
}

export function isString(value: any): value is string {
  return typeof value === 'string';
}

export function isNumber(value: any): value is number {
  return !isNaN(toInteger(value));
}

export function isInteger(value: any): value is number {
  return typeof value === 'number' && isFinite(value) && Math.floor(value) === value;
}

export function isEmpty(string: string) {
  return !string || string === '';
}

export function isDefined(value: any): boolean {
  return value !== undefined && value !== null;
}

export function padNumber(value: number) {
  if (isNumber(value)) {
    return `0${value}`.slice(-2);
  } else {
    return '';
  }
}

export function regExpEscape(text) {
  return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

export function hasClassName(element: any, className: string): boolean {
  return element && element.className && element.className.split && element.className.split(/\s+/).indexOf(className) >= 0;
}

@Injectable()
export class NgbMomentDateAdapter implements NgbDateAdapter<moment_.Moment> {
  fromModel(momentDate: moment_.Moment): NgbDateStruct {
    if (!momentDate || !moment.isMoment(momentDate)) {
      return null;
    }
    return { year: momentDate.year(), month: momentDate.month() + 1, day: momentDate.date() };
  }

  toModel(date: NgbDateStruct): moment_.Moment {
    if (!date) {
      return null;
    }
    return moment()
      .year(date.year)
      .month(date.month - 1)
      .date(date.day)
      .startOf('day');
  }
}
