import { Component, Input, OnChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbDateAdapter, NgbDateParserFormatter, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { NgbDateCustomParserFormatter } from './ngb-date-custom-parser-formatter';
import { NgbMomentDateAdapter } from './ngb-util';

interface DateRange {
  year: number;
  month: number;
  day: number;
}

@Component({
  selector: 'emt-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  providers: [
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter },
    { provide: NgbDateAdapter, useClass: NgbMomentDateAdapter },
  ],
  encapsulation: ViewEncapsulation.None,
})
export class DatePickerComponent implements OnChanges {
  @Input() label: string;
  @Input() control: FormControl;
  @Input() tabIndex: string;
  @Input() placeholder: string;
  @Input() readonly: boolean;
  @Input() minDateInput: moment.Moment;
  @Input() maxDateInput: moment.Moment;
  @Input() customErrors: Array<{ type: string; message: string }> = [];
  @ViewChild('ngbDatepicker') ngbDatepicker: NgbInputDatepicker;
  public minDate: DateRange;
  public maxDate: DateRange;

  constructor() {}

  ngOnChanges() {
    if (this.minDateInput && moment.isMoment(this.minDateInput) && this.minDateInput.isValid()) {
      this.minDate = {
        year: parseInt(this.minDateInput.format('YYYY'), 10),
        month: parseInt(this.minDateInput.format('MM'), 10),
        day: parseInt(this.minDateInput.format('DD'), 10),
      };
    } else {
      this.minDate = null;
    }

    if (this.maxDateInput && moment.isMoment(this.maxDateInput) && this.maxDateInput.isValid()) {
      this.maxDate = {
        year: parseInt(this.maxDateInput.format('YYYY'), 10),
        month: parseInt(this.maxDateInput.format('MM'), 10),
        day: parseInt(this.maxDateInput.format('DD'), 10),
      };
    } else {
      this.maxDate = null;
    }
  }

  openDatepicker() {
    if (!this.control.disabled) {
      if (!this.control.dirty) {
        this.control.markAsDirty();
      }

      this.ngbDatepicker.toggle();
    }
  }

  validateInput() {
    if (this.control.value && !moment.isMoment(this.control.value) && moment(this.control.value).isValid()) {
      this.control.patchValue(moment(this.control.value).startOf('day'));
    }
  }
}
