import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';

@Component({
  selector: 'emt-logout-modal',
  templateUrl: './logout-modal.component.html',
  styleUrls: ['./logout-modal.component.scss'],
})
export class LogoutModalComponent implements OnInit {
  @Output() closeLogout = new EventEmitter();

  constructor(private authService: AuthService) {}

  ngOnInit() {}

  logout() {
    this.authService.startSignout();
    this.closeLogoutModal();
  }

  cancel() {
    this.closeLogoutModal();
  }

  private closeLogoutModal() {
    this.closeLogout.emit();
  }
}
