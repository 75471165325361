import { Action } from '@ngrx/store';
import { UserProfile } from '../../../models/userprofile.model';

export enum AuthActionTypes {
  USER_LOGIN = '[Auth] User Login',
  USER_LOGOUT = '[Auth] User Logout'
}

export class UserLoginAction implements Action {
  readonly type = AuthActionTypes.USER_LOGIN;
  constructor(public payload: {user: UserProfile}) {}
}

export class UserLogoutAction implements Action {
  readonly type = AuthActionTypes.USER_LOGOUT;
  constructor() {}
}

export type AuthActions = UserLoginAction | UserLogoutAction ;
