import { Component, OnDestroy, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import * as moment from 'moment';
import { QuickSearchService } from '../../../services/quick-search/quick-search.service';
import { FormControl, FormGroup, NgForm, Validators, ValidationErrors, AbstractControl } from '@angular/forms';
import { FlightLeg } from '../../../feature-modules/flight-info/models';
import { NgbDateCustomParserFormatter } from '../../../../../../common/src/lib/components/date-picker/ngb-date-custom-parser-formatter';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { delayCodes, LATE_CREW_CODE, LATE_EQ_CODE } from '../../../models/delay-codes';
import { isEmpty } from '../../../../../../common/src/lib/components/date-picker/ngb-util';
import { FlightLegsService } from '../../../feature-modules/flight-info/services';
import { BehaviorSubject, Subscription } from 'rxjs';
import { FlightLegList } from '../../../feature-modules/flight-info/models/flight-leg-list';
import { QuickEditFormModel } from '../../../models/quick-edit/quick-edit-form.model';
import { QuickEditRequest } from '../../../models/quick-edit/quick-edit-request.model';
import { AuthService } from '../../../services/auth/auth.service';
import {
  momentFromFields,
  timeRegex,
  dateRegex,
  regexMatch,
  validateDelayCode,
  validateDelay,
  validateTimeOrder,
  MessageList,
  errorObj
} from './quick-edit-util';
import { keyframes } from '@angular/animations';

@Component({
  selector: 'emt-quick-edit',
  templateUrl: './quick-edit.component.html',
  styleUrls: ['./quick-edit.component.scss'],
})
export class QuickEditComponent implements OnDestroy, AfterViewInit {
  constructor(
    private quickSearchService: QuickSearchService,
    private modal: NgbActiveModal,
    private flightLegService: FlightLegsService,
    private authService: AuthService
  ) {
    this.userId = this.authService.getFullUsername().split(' ')[0];
  }
  commentLimit = 117;
  userId: string;
  flightLeg: BehaviorSubject<FlightLeg> = new BehaviorSubject<FlightLeg>(null);
  quickEditFormModel = new QuickEditFormModel();
  searchForm = new FormGroup({
    flightId: new FormControl(),
  });
  quickEditForm = new FormGroup(
    {
      estimatedOutTime: new FormControl(null, { validators: [regexMatch(timeRegex)], updateOn: 'blur' }),
      estimatedOutDate: new FormControl(null, { validators: [regexMatch(dateRegex)], updateOn: 'blur' }),
      estimatedOffTime: new FormControl(null, { validators: [regexMatch(timeRegex)], updateOn: 'blur' }),
      estimatedOffDate: new FormControl(null, { validators: [regexMatch(dateRegex)], updateOn: 'blur' }),
      estimatedInTime: new FormControl(null, { validators: [regexMatch(timeRegex)], updateOn: 'blur' }),
      estimatedInDate: new FormControl(null, { validators: [regexMatch(dateRegex)], updateOn: 'blur' }),
      delayCode: new FormControl(null, { updateOn: 'submit' }),
      actualOutTime: new FormControl(),
      actualOutDate: new FormControl(),
      actualOffTime: new FormControl(),
      actualOffDate: new FormControl(),
      actualInTime: new FormControl(),
      actualInDate: new FormControl(),
      comments: new FormControl('', [Validators.maxLength(this.commentLimit)]),
    },
    { updateOn: 'submit' }
  );
  subscriptions: Subscription[] = [];
  demandCodes = delayCodes;
  flightNotFound = false;
  submitSuccess = false;
  commentsValid = false; 
  submissionError: string = null;
  messageList: MessageList = {warnings:[], errors:[], errorText:[]}
  @ViewChild('formDir') formDir: NgForm;
  @ViewChild('searchInput') searchInput: ElementRef;

  ngAfterViewInit(): void {
    this.searchInput.nativeElement.focus();
    this.delayCode.setValidators(validateDelayCode(this.estimatedOutDate.control, this.estimatedOutTime.control, this.flightLeg));
    this.quickEditForm.setValidators([validateDelay(this.flightLeg), validateTimeOrder(this.flightLeg)]);
  }

  ngOnDestroy(): void {
    NgbDateCustomParserFormatter.resetDateFormat();
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  flightSearch() {
    const formValue = this.searchForm.get('flightId').value;
    if (formValue === null || formValue === '') {
      this.flightNotFound = false;
    } else {
      const query = formValue.includes('/')
        ? formValue
        : `${formValue}/${moment()
            .utc()
            .date()}`;
      let flightSearchSubscription = this.quickSearchService.quickSearch(query).subscribe(legs => this.setFlightLeg(legs));
      this.subscriptions.push(flightSearchSubscription);
    }
  }

  async setDelayCode(flightLeg: FlightLeg) {
    const shipTurns = await this.flightLegService
      .getLegsData(flightLeg)
      .toPromise()
      .then(legData => legData);
    if (shipTurns && shipTurns.flightLegTabList && shipTurns.flightLegTabList.length > 0) {
      const shipLegData = shipTurns.flightLegTabList;
      const previousFlight = shipLegData.find(flightTab => flightTab.label === 'ShipDownStream');
      const currentFlight = shipLegData.find(flightTab => flightTab.label === 'CurrentFlight');
      const bestOut = moment(flightLeg.bestFlightMovementEventTimes.outEventUtcTs).utc(
        !flightLeg.bestFlightMovementEventTimes.outEventUtcTs.includes('Z')
      );
      if (currentFlight && previousFlight && previousFlight.bestTimes && previousFlight.bestTimes.inUtc) {
        this.setLateEqDelayCode(previousFlight, currentFlight, bestOut);
      } else {
        this.setLateCrewCode(flightLeg, bestOut);
      }
    }
  }

  private setLateEqDelayCode(previousFlight, currentFlight, bestOut) {
    let previousBestIn = moment(previousFlight.bestTimes.inUtc).utc(!previousFlight.bestTimes.inUtc.includes('Z'));
    if (currentFlight && currentFlight.minimumStandardGroundTime && currentFlight.minimumStandardGroundTime.minimumStdGroundTimeMinutes) {
      const msgt = currentFlight.minimumStandardGroundTime.minimumStdGroundTimeMinutes;
      if (bestOut.isBefore(previousBestIn.add(msgt, 'minutes'))) {
        this.delayCode.setValue(LATE_EQ_CODE);
      }
    }
  }

  private async setLateCrewCode(flightLeg, bestOut) {
    const flightOriginDate = bestOut.clone().startOf('day');
    const crewData = await this.flightLegService
      .getGanttChartCrewInfo(
        flightLeg.flightNum,
        flightOriginDate.format('YYYY-MM-DD'),
        flightLeg.originAirport.airportCode,
        flightLeg.destinationAirport.airportCode,
        flightLeg.operatingCarrierCode
      )
      .toPromise()
      .then(crewData => crewData);
    if (crewData && crewData.data && crewData.data.length > 0) {
      let latestArrivingCrewMember: moment.Moment;
      crewData.data.forEach(datum => {
        if (
          datum.legMovement &&
          datum.legMovement.previousFlightLeg &&
          datum.legMovement.previousFlightLeg.bestTimes &&
          datum.legMovement.previousFlightLeg.bestTimes.inUtc
        ) {
          let crewArrival = moment(datum.legMovement.previousFlightLeg.bestTimes.inUtc).utc(
            !datum.legMovement.previousFlightLeg.bestTimes.inUtc.includes('Z')
          );
          if (!latestArrivingCrewMember || crewArrival.isAfter(latestArrivingCrewMember)) {
            latestArrivingCrewMember = moment(crewArrival).clone();
          }
        }
      });
      if (latestArrivingCrewMember) {
        if (bestOut.isBefore(latestArrivingCrewMember)) {
          this.delayCode.setValue(LATE_CREW_CODE);
        }
      }
    }
  }

  selectLeg(index: number) {
    let flightLegSubscription = this.flightLegService
      .loadLeg(this.flightLeg.value.lineOfFlight[index].flightId)
      .subscribe(flightLegList => this.setFlightLeg(flightLegList));
    this.subscriptions.push(flightLegSubscription);
  }

  setFlightLeg(flightLegList: FlightLegList) {
    if (flightLegList && flightLegList.flightLegs && flightLegList.flightLegs.length > 0) {
      this.flightNotFound = false;
      this.flightLeg.next(flightLegList.flightLegs[0]);
      const flightLegCopy = Object.assign({}, this.flightLeg.value);
      this.quickEditForm.reset();
      this.formDir.resetForm();
      this.setTimes(flightLegCopy);
      this.setDelayCode(flightLegCopy);
    } else this.flightNotFound = true;
  }

  handleQuickEditResponse(event) {
    if (event) {
      this.submitSuccess = false;
      this.resetAll();
    } else {
      this.close();
    }
  }

  close() {
    this.modal.close();
  }

  isEmpty(control: AbstractControl) {
    return isEmpty(control.value);
  }

  reset() {
    this.clearWarningList();
    this.clearErrorList();
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.subscriptions = [];
    if (this.formDir) {
      this.formDir.resetForm();
    }
    this.setTimes(this.flightLeg.value);
    this.submissionError = null;
    this.flightNotFound = false;
    if (this.searchInput) {
      this.searchInput.nativeElement.focus();
    }
  }



  errorMessageHandler(inputError: errorObj){
    let errExists = false;
    this.messageList.errors.forEach(errObj =>{
      if(errObj.errorId == inputError.errorId){
        errExists = true;
      }
    })
    if(!errExists){
       this.messageList.errors.push(inputError);
       this.messageList.errorText.push(inputError.errorMessage)
    }
  }


  clearErrorList() {
    this.messageList.errors = [];
    this.messageList.errorText = [];
  }

  clearWarningList(){
    this.messageList.warnings = [];
  }

  resetAll() {
    this.clearErrorList();
    this.clearWarningList();
    this.reset();
    this.quickEditFormModel = new QuickEditFormModel();
    this.flightLeg.next(null);
    this.searchForm.reset();
    this.quickEditForm.reset();
  }

  changesMade() {
    this.clearWarningList();
      if (this.flightLeg.value) {
        const flightLeg = this.flightLeg.value;
        let estimatedOut, estimatedOff, estimatedIn;
        if (flightLeg.estimatedFlightMovementEventTimes) {
          const estimatedTimes = flightLeg.estimatedFlightMovementEventTimes;
          estimatedOut = estimatedTimes.outEventUtcTs ? moment.utc(estimatedTimes.outEventUtcTs) : null;
          estimatedOff = estimatedTimes.offEventUtcTs ? moment.utc(estimatedTimes.offEventUtcTs) : null;
          estimatedIn = estimatedTimes.inEventUtcTs ? moment.utc(estimatedTimes.inEventUtcTs) : null;
        }
        const newOut =
          this.estimatedOutDate.value && this.estimatedOutTime.value
            ? momentFromFields(this.estimatedOutTime.value, this.estimatedOutDate.value)
            : null;
        const newOff =
          this.estimatedOffDate.value && this.estimatedOffTime.value
            ? momentFromFields(this.estimatedOffTime.value, this.estimatedOffDate.value)
            : null;
        const newIn =
          this.estimatedInDate.value && this.estimatedInTime.value
            ? momentFromFields(this.estimatedInTime.value, this.estimatedInDate.value)
            : null;
          return this.valueChange(estimatedOut, newOut) || this.valueChange(estimatedOff, newOff) || this.valueChange(estimatedIn, newIn);
       }
    }

  commentsRequired(){
    if (this.quickEditForm.controls.comments.value && this.quickEditForm.controls.comments.value.length > 0) {
      this.commentsValid = true; 
      return true;
    }else{
      this.errorMessageHandler({errorId:'commentError',errorMessage:'comment required'});
      return false
    }
  }

  valueChange(val1: moment.Moment, val2: moment.Moment) {
    return (val1 && val2 && !val1.isSame(val2)) || (val1 && !val2) || (val2 && !val1);
  }

  submit() {
    this.validateDateTime();
    this.delayCode.updateValueAndValidity();
    if (this.quickEditForm.valid && this.changesMade() && this.commentsRequired()) {
      const estimatedOut =
        this.estimatedOutTime.value && this.estimatedOutDate.value
          ? momentFromFields(this.estimatedOutTime.value, this.estimatedOutDate.value)
          : null;
      const estimatedIn =
        this.estimatedInTime.value && this.estimatedInDate.value
          ? momentFromFields(this.estimatedInTime.value, this.estimatedInDate.value)
          : null;
      const estimatedOff =
        this.estimatedOffTime.value && this.estimatedOffDate.value
          ? momentFromFields(this.estimatedOffTime.value, this.estimatedOffDate.value)
          : null;
      let comment = `EMT/${this.userId}`;
      if (this.delayCode.value) {
        comment.concat(`/${this.delayCode.value}`);
      }
      if (this.comments.value) {
        comment.concat(`/${this.comments.value}`);
      }
      const submission = new QuickEditRequest(estimatedOut, estimatedIn, estimatedOff, comment, this.flightLeg.value);
      this.flightLegService.quickEdit(this.flightLeg.value.flightId, submission).subscribe(
        data => {
          this.submitSuccess = true;
        },
        error => {
          if (error.status === 409) {
            this.flightSearch();
            this.submissionError =
              'The flight you were viewing has been edited by another user. The table has been updated with the latest data.';
          } else {
            this.submissionError = 'An error was encountered while submitting the form.';
          }
        }
      );
    }
  }

  timeFrameWarning():void {
    this.clearWarningList();
    let newOutTime = momentFromFields(this.estimatedOutTime.control.value, this.estimatedOutDate.control.value);
    let scheduledOutTime = moment.utc(this.flightLeg.value.scheduledFlightMovementEventTimes.outEventUtcTs);
    if(newOutTime.diff(scheduledOutTime, 'minutes') > 600){
        this.messageList.warnings.push(`WARNING: flight is delayed more than 10 hours`)
    }
  }

  validateDateTime(){
    this.clearErrorList();
    this.invalidTimeLogic();
    if(this.messageList.errors.length == 0){
      this.timeFrameWarning()
    }
  }


  invalidTimeLogic(): boolean {
    let invalidDateInputs = this.invalidDateInputs()
    let invalidTimeInputs = this.invalidTimeInputs()
    if(!invalidDateInputs && !invalidTimeInputs && this.estimatedOutTime.control.value && this.estimatedOutDate.control.value){
      this.checkDelayTimeValid()
    }
    return false;
  }

  checkDelayTimeValid(){
      let newOutTime = momentFromFields(this.estimatedOutTime.control.value, this.estimatedOutDate.control.value);
      let scheduledOutTime = moment.utc(this.flightLeg.value.scheduledFlightMovementEventTimes.outEventUtcTs);
      if(newOutTime.diff(scheduledOutTime, 'minutes') > 1200){
        this.errorMessageHandler({errorId:'over20Error',errorMessage:`Estimated OUT cannot be more than 20 hours after Scheduled OUT`});
      }
      if(newOutTime.diff(scheduledOutTime, 'minutes') < -240){
        this.errorMessageHandler({errorId:'under4Error', errorMessage:`Estimated OUT cannot be more than 4 hours prior to Scheduled OUT`});
      }
  }


  missingDateTime(): boolean {
    const dateTimeFields = [
      this.estimatedInDate,
      this.estimatedOffDate,
      this.estimatedOutDate,
      this.estimatedInTime,
      this.estimatedOffTime,
      this.estimatedOutTime,
    ];
    for (let field of dateTimeFields) {
      if (field.control.errors && field.control.errors.required) {
        return true;
      }
    }
    return false;
  }

  private invalidTime(control: AbstractControl): boolean {
    return control.errors && control.errors.pattern;
  }


  invalidDateInputs(): boolean {
    let inputsInvalid = false
    const timeFields = [this.estimatedOutDate];
    for (let field of timeFields) {
      if (this.invalidTime(field.control)) {
          inputsInvalid = true;
          this.errorMessageHandler({errorId:'patchDateError',errorMessage:`Invalid date ${field.control.value}, use format month/day`});
      }
    }
    return inputsInvalid; 
  }

  invalidTimeInputs():boolean{
    let inputsInvalid = false
    const timeFields = [this.estimatedOutTime];
    for (let field of timeFields) {
      if (this.invalidTime(field.control)) {
        this.errorMessageHandler({errorId:'timeError',errorMessage:`Invalid time ${field.control.value}, use format HHMM`});
        inputsInvalid = true;
      }
    } 
    return inputsInvalid; 
  }


  isSelected(flightId: string) {
    return this.flightLeg.value.flightId === flightId;
  }

  // Getters
  public get estimatedOutTime() {
    const control = this.quickEditForm.get('estimatedOutTime') || null;
    const disabled =
      this.flightLeg.value &&
      this.flightLeg.value.actualFlightMovementEventTimes &&
      !isEmpty(this.flightLeg.value.actualFlightMovementEventTimes.outEventUtcTs);

    return {
      control: control,
      value: control.value || null,
      disabled: disabled,
      placeholder: 'HHMM',
    };
  }

  public get estimatedOutDate() {
    const control = this.quickEditForm.get('estimatedOutDate') || null;
    const disabled =
      this.flightLeg.value &&
      this.flightLeg.value.actualFlightMovementEventTimes &&
      !isEmpty(this.flightLeg.value.actualFlightMovementEventTimes.outEventUtcTs);

    return {
      control: control,
      value: control.value || null,
      disabled: disabled,
      placeholder: 'MM/DD',
    };
  }

  public get estimatedOffTime() {
    const control = this.quickEditForm.get('estimatedOffTime') || null;
    const disabled =
      (this.flightLeg.value &&
        this.flightLeg.value.actualFlightMovementEventTimes &&
        !isEmpty(this.flightLeg.value.actualFlightMovementEventTimes.offEventUtcTs)) ||
      (!this.flightLeg.value ||
        !this.flightLeg.value.actualFlightMovementEventTimes ||
        isEmpty(this.flightLeg.value.actualFlightMovementEventTimes.outEventUtcTs));
    // Returning blank value in accordance to story B-116640
    // const placeholder = this.getCalculatedOff() ? this.getCalculatedOff().time : 'HHMM';
    const placeholder = 'HHMM';
    return {
      control: control,
      value: control.value || null,
      disabled: disabled,
      placeholder: placeholder,
    };
  }

  public get estimatedOffDate() {
    const control = this.quickEditForm.get('estimatedOffDate') || null;
    const disabled =
      (this.flightLeg.value &&
        this.flightLeg.value.actualFlightMovementEventTimes &&
        !isEmpty(this.flightLeg.value.actualFlightMovementEventTimes.offEventUtcTs)) ||
      (!this.flightLeg.value ||
        !this.flightLeg.value.actualFlightMovementEventTimes ||
        isEmpty(this.flightLeg.value.actualFlightMovementEventTimes.outEventUtcTs));
    // Returning blank value in accordance to story B-116640
    // const placeholder = this.getCalculatedOff() ? this.getCalculatedOff().date : 'MM/DD';
    const placeholder = 'MM/DD';
    return {
      control: control,
      value: control.value || null,
      disabled: disabled,
      placeholder: placeholder,
    };
  }

  public get estimatedInTime() {
    const control = this.quickEditForm.get('estimatedInTime') || null;
    const disabled = true;
    // leaving this logic here in case we want to use it in the future
    //   this.flightLeg.value &&
    //   this.flightLeg.value.actualFlightMovementEventTimes &&
    //   !isEmpty(this.flightLeg.value.actualFlightMovementEventTimes.inEventUtcTs);
    const calculatedIn = this.getCalculatedIn();
    const placeholder = calculatedIn != null ? calculatedIn.time : null;
    const finalValue = placeholder || control.value;
    return {
      control: control,
      value: finalValue || null,
      disabled: disabled,
      placeholder: placeholder || 'HHMM'
    };
  }

  public get estimatedInDate() {
    const control = this.quickEditForm.get('estimatedInDate') || null;
    const disabled = true;
    // leaving this logic here in case we want to use it in the future
    // this.flightLeg.value &&
    // this.flightLeg.value.actualFlightMovementEventTimes &&
    // !isEmpty(this.flightLeg.value.actualFlightMovementEventTimes.inEventUtcTs);
    const calculatedIn = this.getCalculatedIn();
    const placeholder = calculatedIn != null ? calculatedIn.date : null;
    const finalValue = placeholder || control.value;
    return {
      control: control,
      value: finalValue || null,
      disabled: disabled,
      placeholder: placeholder || 'MM/DD',
    };
  }

  public setEstimatedInTimes() {
    const timeControl = this.quickEditForm.get('estimatedInTime');
    const dateControl = this.quickEditForm.get('estimatedInDate');
    const calculatedIn = this.getCalculatedIn();
    if (calculatedIn != null && timeControl != null && dateControl != null) {
      timeControl.setValue(calculatedIn.time);
      dateControl.setValue(calculatedIn.date);
    }
  }

  public actualTimeOutExists(flightLeg: BehaviorSubject<FlightLeg>): boolean {
    return flightLeg && flightLeg.value &&
      flightLeg.value.actualFlightMovementEventTimes &&
      !isEmpty(flightLeg.value.actualFlightMovementEventTimes.outEventUtcTs);
  }

  private get actualOutTime() {
    const control = this.quickEditForm.get('actualOutTime');
    const disabled = true;
    return {
      control: control,
      value: control.value || null,
      disabled: disabled,
    };
  }

  private get actualOutDate() {
    const control = this.quickEditForm.get('actualOutDate');
    const disabled = true;
    return {
      control: control,
      value: control.value || null,
      disabled: disabled,
    };
  }

  private get actualOffTime() {
    const control = this.quickEditForm.get('actualOffTime');
    const disabled = true;
    return {
      control: control,
      value: control.value || null,
      disabled: disabled,
    };
  }

  private get actualOffDate() {
    const control = this.quickEditForm.get('actualOffDate');
    const disabled = true;
    return {
      control: control,
      value: control.value || null,
      disabled: disabled,
    };
  }

  private get actualInTime() {
    const control = this.quickEditForm.get('actualInTime');
    const disabled = true;
    return {
      control: control,
      value: control.value || null,
      disabled: disabled,
    };
  }

  private get actualInDate() {
    const control = this.quickEditForm.get('actualInDate');
    const disabled = true;
    return {
      control: control,
      value: control.value || null,
      disabled: disabled,
    };
  }

  public get delayCode() {
    return this.quickEditForm.get('delayCode');
  }

  public get comments() {
    return this.quickEditForm.get('comments');
  }

  private validationClass(control: AbstractControl) {
    if (control.errors) {
      if (control.errors.pattern) {
        return this.formDir.submitted ? 'invalid' : 'warning';
      } else if (control.errors.required && this.formDir.submitted) {
        return 'invalid-bordered';
      }
    } else {
      return undefined;
    }
  }

  private setTimes(flightLeg: FlightLeg) {
    this.setEstimatedTimes(flightLeg);
    this.setActualTimes(flightLeg);
  }

  private setEstimatedTimes(flightLeg: FlightLeg) {
    if (flightLeg && flightLeg.estimatedFlightMovementEventTimes) {
      const estOutMoment = flightLeg.estimatedFlightMovementEventTimes.outEventUtcTs
        ? moment(flightLeg.estimatedFlightMovementEventTimes.outEventUtcTs).utc()
        : null;
      const estInMoment = flightLeg.estimatedFlightMovementEventTimes.inEventUtcTs
        ? moment(flightLeg.estimatedFlightMovementEventTimes.inEventUtcTs).utc()
        : null;

      // Blanking out estimated Off time in accordance to story B-116640
      // const estOffMoment = flightLeg.estimatedFlightMovementEventTimes.offEventUtcTs
      //   ? moment(flightLeg.estimatedFlightMovementEventTimes.offEventUtcTs).utc()
      //   : null;
      const estOffMoment = null;

      const [estOutTimeVal, estOutDateVal] = estOutMoment ? [estOutMoment.format('HHmm'), estOutMoment.format('MM/DD')] : [null, null];
      const [estInTimeVal, estInDateVal] = estInMoment ? [estInMoment.format('HHmm'), estInMoment.format('MM/DD')] : [null, null];
      const [estOffTimeVal, estOffDateVal] = estOffMoment ? [estOffMoment.format('HHmm'), estOffMoment.format('MM/DD')] : [null, null];

      this.estimatedOutTime.control.setValue(estOutTimeVal);
      this.estimatedOutDate.control.setValue(estOutDateVal);
      this.estimatedInTime.control.setValue(estInTimeVal);
      this.estimatedInDate.control.setValue(estInDateVal);
      this.estimatedOffTime.control.setValue(estOffTimeVal);
      this.estimatedOffDate.control.setValue(estOffDateVal);
    } else {
      this.estimatedOutTime.control.setValue(null);
      this.estimatedOutDate.control.setValue(null);
      this.estimatedInTime.control.setValue(null);
      this.estimatedInDate.control.setValue(null);
      this.estimatedOffTime.control.setValue(null);
      this.estimatedOffDate.control.setValue(null);
    }
  }

  private setActualTimes(flightLeg: FlightLeg) {
    if (flightLeg.actualFlightMovementEventTimes) {
      const actOutMoment = flightLeg.actualFlightMovementEventTimes.outEventUtcTs
        ? moment(flightLeg.actualFlightMovementEventTimes.outEventUtcTs).utc()
        : null;
      const actInMoment = flightLeg.actualFlightMovementEventTimes.inEventUtcTs
        ? moment(flightLeg.actualFlightMovementEventTimes.inEventUtcTs).utc()
        : null;
      const actOffMoment = flightLeg.actualFlightMovementEventTimes.offEventUtcTs
        ? moment(flightLeg.actualFlightMovementEventTimes.offEventUtcTs).utc()
        : null;

      const [actOutTimeVal, actOutDateVal] = actOutMoment ? [actOutMoment.format('HHmm'), actOutMoment.format('MM/DD')] : [null, null];
      const [actInTimeVal, actInDateVal] = actInMoment ? [actInMoment.format('HHmm'), actInMoment.format('MM/DD')] : [null, null];
      const [actOffTimeVal, actOffDateVal] = actOffMoment ? [actOffMoment.format('HHmm'), actOffMoment.format('MM/DD')] : [null, null];

      this.actualOutTime.control.setValue(actOutTimeVal);
      this.actualOutDate.control.setValue(actOutDateVal);
      this.actualInTime.control.setValue(actInTimeVal);
      this.actualInDate.control.setValue(actInDateVal);
      this.actualOffTime.control.setValue(actOffTimeVal);
      this.actualOffDate.control.setValue(actOffDateVal);
    } else {
      this.actualOutTime.control.setValue(null);
      this.actualOutDate.control.setValue(null);
      this.actualInTime.control.setValue(null);
      this.actualInDate.control.setValue(null);
      this.actualOffTime.control.setValue(null);
      this.actualOffDate.control.setValue(null);
    }
  }

  private getCalculatedOff() {
    if (isEmpty(this.estimatedOutDate.value) || isEmpty(this.estimatedOutTime.value)) {
      return null;
    } else {
      const estimatedOut = momentFromFields(this.estimatedOutTime.value, this.estimatedOutDate.value);
      if (estimatedOut.isValid()) {
        let gap = 15;
        if (this.flightLeg.value && this.flightLeg.value.scheduledFlightMovementEventTimes) {
          let scheduledTimes = this.flightLeg.value.scheduledFlightMovementEventTimes;
          if (scheduledTimes.outEventUtcTs && scheduledTimes.offEventUtcTs) {
            const scheduledOut = moment.utc(scheduledTimes.outEventUtcTs);
            const scheduledOff = moment.utc(scheduledTimes.offEventUtcTs);
            gap = scheduledOff.diff(scheduledOut, 'minutes');
          }
        }
        const calculatedEstimatedOff = estimatedOut.add(gap, 'minutes');
        return {
          time: calculatedEstimatedOff.format('HHmm'),
          date: calculatedEstimatedOff.format('MM/DD'),
        };
      }
    }
  }

  private patchDate(inputField) {
    this.clearErrorList();
    if (inputField.value && this.flightLeg.value) {
      let monthDay = this.getMonthDay(inputField.value);
      if (monthDay == 'Invalid date') {
        inputField.control.setValue(inputField.value);
      } else {
        inputField.control.setValue(monthDay);
      }
    }
  }

  public checkDelayCode(flightLeg: BehaviorSubject<FlightLeg>) {
    let scheduledOutTime = null;
    if (flightLeg && flightLeg.value && flightLeg.value.scheduledFlightMovementEventTimes && flightLeg.value.scheduledFlightMovementEventTimes.outEventUtcTs) {
      scheduledOutTime = moment(flightLeg.value.scheduledFlightMovementEventTimes.outEventUtcTs);
    }
    if (scheduledOutTime === null) {
      return;
    }
    const estimatedOut = momentFromFields(this.estimatedOutTime.value, this.estimatedOutDate.value);
    if (this.estimatedOutTime.value != null && this.estimatedOutDate.value != null && estimatedOut.isValid()) {
      if (estimatedOut.isBefore(scheduledOutTime)) {
        // Estimated Out is before Scheduled Out. Clear out the delay code.
        this.delayCode.setValue(null);
      }
    }
  }

  public getMonthDay(monthDay: string): string {
    monthDay = monthDay.toUpperCase();
    if (monthDay.match('.*/.*')) {
      let mmdd = monthDay.split('/');
      return moment.utc(this.sliceTime(mmdd[0]) + this.sliceTime(mmdd[1]), 'MMDD').format('MM/DD');
    } else if (monthDay.match('[a-zA-Z]')) {
      let month = monthDay.match(/[A-Z]/g).join('');
      if(monthDay.match(/[0-9]/g)){
      let day = monthDay.match(/\d/g).join('');
      return moment.utc(this.sliceTime(day) + month, 'DDMMM').format('MM/DD');
      }else return "Invalid date";
    } else if (monthDay.match('^[1-9]$|^[0-3][0-9]?$')) {
      let day = this.sliceTime(monthDay);
      let currentMonth = moment.utc().format('MM');
      return moment.utc(day + currentMonth, 'DDMM').format('MM/DD');
    } else {
      this.errorMessageHandler({errorId:'dateFormatError',errorMessage:`Invalid date input: ${monthDay}, use format month/day`});
      return monthDay;
    }
  }

  private sliceTime(time: string): string {
    return ('000' + time).slice(-2);
  }

  private getCalculatedIn() {
    if (isEmpty(this.estimatedOutDate.value) || isEmpty(this.estimatedOutTime.value)) {
      return null;
    } else {
      const estimatedOut = momentFromFields(this.estimatedOutTime.value, this.estimatedOutDate.value);
      if (this.flightLeg.value && this.flightLeg.value.scheduledFlightMovementEventTimes) {
        let scheduledTimes = this.flightLeg.value.scheduledFlightMovementEventTimes;
        if (scheduledTimes.outEventUtcTs && scheduledTimes.inEventUtcTs) {
          const scheduledOut = moment.utc(scheduledTimes.outEventUtcTs);
          const scheduledIn = moment.utc(scheduledTimes.inEventUtcTs);
          const gap = scheduledIn.diff(scheduledOut, 'minutes');
          const calculatedEstimatedIn = estimatedOut.add(gap, 'minutes');
          return {
            time: calculatedEstimatedIn.format('HHmm'),
            date: calculatedEstimatedIn.format('MM/DD'),
          };
        }
      }
      return null;
    }
  }
}
