import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'validateTableCellCollapsibility' })
export class ValidateTableCellCollapsibilityPipe implements PipeTransform {
  transform(cell: Object, isCollapsible: boolean, collapsibleKey: string): boolean {
    if (cell && isCollapsible && cell[collapsibleKey] && cell[collapsibleKey].length) {
      return true;
    } else {
      return false;
    }
  }
}
