import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';

@Component({
  selector: 'emt-airplane-loader',
  templateUrl: './airplane-loader.component.html',
  styleUrls: ['./airplane-loader.component.scss']
})
export class AirplaneLoaderComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() fullScreen = false; // use this to put full screen loader with gray background.
  @Input() componentOnly: boolean = false; // use this for component specific. will not disable whole page
  @ViewChild('loader') loaderElement: ElementRef;

  constructor(private renderer: Renderer2) {}

  ngOnDestroy() {
    if (this.fullScreen) {
      this.renderer.removeClass(document.body, 'no-scroll');
    }
  }

  ngOnInit() {
    if (this.fullScreen) {
      this.renderer.addClass(document.body, 'no-scroll');
    }
  }
  
  ngAfterViewInit() {
    if (this.componentOnly) {
      this.renderer.setStyle(this.loaderElement.nativeElement, 'position', 'relative')
      this.renderer.setStyle(this.loaderElement.nativeElement, 'background-color', 'transparent');
      this.renderer.setStyle(this.loaderElement.nativeElement, 'z-index', 'auto');
    }
  }
}
