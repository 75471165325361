import { ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import * as fromSidebar from './reducers/sidebar/sidebar.reducer';
import * as fromStations from './reducers/station/station.reducers';
import * as fromAlerts from './reducers/alert/alert.reducer';
import * as fromAuth from './reducers/auth/auth.reducers';
import { environment } from '../../environments/environment';

export interface AppState {
  sidebarState: fromSidebar.State;
  stations: fromStations.State;
  alertState: fromAlerts.State;
  authState: fromAuth.State;
}

export const reducers: ActionReducerMap<AppState> = {
  sidebarState: fromSidebar.reducer,
  stations: fromStations.stationsReducer,
  alertState: fromAlerts.reducer,
  authState: fromAuth.reducer
};

export function debug(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state, action) => {
    console.log('actions debugger', action);
    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<any>[] = !environment.production ? [] : [];
