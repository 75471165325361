import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';
import { Subscription } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { RoleSelectionService } from '../../services/role-selection/role-selection.service';

@Component({
  selector: 'emt-select-role-callback',
  templateUrl: './select-role-callback.component.html',
  styleUrls: ['./select-role-callback.component.scss'],
})
export class SelectRoleCallbackComponent implements OnInit, OnDestroy, AfterViewInit {
  public rolesSubscription: Subscription;
  public roles: { label: string; value: string }[];
  public selectedRole: string;
  constructor(private authService: AuthService, public activeModal: NgbActiveModal, private roleSelectionService: RoleSelectionService) {}

  ngOnInit() {
    this.rolesSubscription = this.authService.rolesSubject.subscribe((roles: { name: string; value: string }[]) => {
      if (roles && roles.length) {
        this.roles = roles
          .map((role: { name: string; value: string }) => ({
            label: role.name,
            value: role.value,
          }))
          .filter((v, i, a) => a.findIndex(t => t.label === v.label && t.value === v.value) === i)
          .sort((a, b) => a.label.localeCompare(b.label));
      }
    });
  }

  ngAfterViewInit(): void {
    if (sessionStorage.getItem('selectedRole') !== null) {
      this.selectedRole = sessionStorage.getItem('selectedRole');
      if (this.selectedRole != 'General') {
        this.submit();
      }
    }
  }

  roleSelected(role) {
    this.selectedRole = role.value;
  }

  submit() {
    this.roleSelectionService.setSelectedRole(this.selectedRole);
    this.activeModal.close(this.selectedRole);
  }

  ngOnDestroy() {
    if (this.rolesSubscription) {
      this.rolesSubscription.unsubscribe();
    }
  }
}
