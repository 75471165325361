<nav class="sidebar" [ngClass]="(showSideNav$ | async) && 'active'">
  <div class="sidebar-header">
    <span class="close-button" (click)="toggleSideNav()">×</span>
    EMT
    <emt-delta-icon class="icon-delta-logo" iconName="icon-delta-logo"></emt-delta-icon>
  </div>

  <div id="nav-container">
    <ng-container *ngFor="let item of navItems; let i = index">
      <div *ngIf="i === navItems.length - 2" class="nav-break"></div>
      <emt-navigation-item
        [item]="item"
        [flightListExpanded]="flightListExpanded"
        [activePath]="activePath"
        (itemSelected)="onItemSelected($event)"
      ></emt-navigation-item>
    </ng-container>
  </div>
</nav>
<div class="overlay" [ngClass]="(showSideNav$ | async) && 'show-overlay'" (click)="toggleSideNav()"></div>
