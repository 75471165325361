import { Component, Input } from '@angular/core';

@Component({
  selector: 'emt-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input() text: string;
  @Input() styleType: string;
  @Input() height: number;
  @Input() width: number;
  @Input() disabled: boolean;
  @Input() showCarot = false;

  constructor() {}
}
