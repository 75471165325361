import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '../../../../../core/src/app/services/config/config.service';
import { BehaviorSubject } from 'rxjs';
import { Airport } from '../../models/airport';

@Injectable({
  providedIn: 'root',
})
export class StationsService {
  public stations = new BehaviorSubject<Airport[]>(null);

  constructor(private http: HttpClient, private configService: ConfigService) {}

  getStations() {
    const baseUrl = this.configService.getSettings('gate-capacity');
    this.http.get(`${baseUrl}airports`).subscribe((stations: Airport[]) => this.stations.next(stations));
  }
}
