export function patchTimeInput(value) {
  if (value.includes(':')) {
    const parts = value.split(':');
    return `${parts[0].substr(0, 2).padStart(2, '0')}:${parts[1].substr(0, 2).padStart(2, '0')}`;
  } else if (value.length === 1) {
    return value.padStart(2, '0').padEnd(4, '0');
  } else if (value.length === 2) {
    return value.padEnd(4, '0');
  } else if (value.length === 3) {
    return value.padStart(4, '0');
  } else {
    return value ? value.substr(0, 4).padStart(4, '0') : '';
  }
}
