// Angular Libraries
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Third Party Libraries
// import { ConfigLoader, ConfigModule } from '@ngx-config/core';
// import { ConfigHttpLoader } from '@ngx-config/http-loader';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

// TODO: Deprecate this when we remove angular material
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Routing
import { AppRoutingModule } from './app-routing.module';

// Delta Libraries (pending)
import { CommonModule as EmtCommonModule } from 'projects/common/src/public_api';

// Components
import { AppComponent } from './app.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { NavigationItemComponent } from './components/side-nav/navigation-item/navigation-item.component';
import { LayoutComponent } from './components/layout/layout.component';
import { SearchInputComponent } from './components/search-input/search-input.component';
import { SideNavComponent } from './components/side-nav/side-nav.component';
import { TabBarComponent } from './components/tab-bar/tab-bar.component';

// Services
import { StationService } from './services/station/station.service';
import { DataService } from './services/dataService/data-service.service';
import { QuickSearchService } from './services/quick-search/quick-search.service';
import { RoleSelectionService } from './services/role-selection/role-selection.service';
import { NgBootstrapModule } from './shared/ng-bootstrap-module';

// Configuration
import { environment } from '../environments/environment';
import { ConfigLoader, ConfigModule, ConfigHttpLoader } from './services/config/config';

// Store Configuration
// TODO: Move store out of core into `store`
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { metaReducers, reducers } from './store';

import { MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { StationsEffects } from './store/effects/station/station.effects';
import { LogoutModalComponent } from './components/logout-modal/logout-modal.component';
import { AuthCallbackComponent } from './components/auth-callback/auth-callback.component';
import { AlertsListComponent } from './components/alerts-list/alerts-list.component';
import { AlertEffects } from './store/effects/alert/alert.effects';
import { AlertBarComponent } from './components/navbar/alert-bar/alert-bar.component';
import { AlertSeverityPipe } from './pipes/alert-severity.pipe';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { SelectRoleCallbackComponent } from './components/select-role-callback/select-role-callback.component';
import { QuickEditComponent } from './components/transactional/quick-edit/quick-edit.component';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { SuccessComponent } from './components/transactional/quick-edit/success/success.component';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { MAT_DATE_FORMATS } from '@angular/material/core';

import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';

// Setup ngx-config loader
export function configFactory(http: HttpClient): ConfigLoader {
  return new ConfigHttpLoader(http, './assets/config.json');
}

export const options: Partial<IConfig> | (() => Partial<IConfig>) = {
  dropSpecialCharacters: false,
};

export function storageFactory(): OAuthStorage {
  return sessionStorage;
}

@NgModule({
  declarations: [
    AppComponent,
    AuthCallbackComponent,
    NavbarComponent,
    NavigationItemComponent,
    SearchInputComponent,
    SideNavComponent,
    NavigationItemComponent,
    LayoutComponent,
    TabBarComponent,
    LogoutModalComponent,
    AlertsListComponent,
    AlertBarComponent,
    AlertSeverityPipe,
    SelectRoleCallbackComponent,
    QuickEditComponent,
    SuccessComponent,
  ],
  imports: [
    AppRoutingModule,
    HttpClientModule,
    MatMomentDateModule,
    BrowserModule,
    NgBootstrapModule,
    FormsModule,
    ReactiveFormsModule,
    EmtCommonModule,
    BrowserAnimationsModule,
    ConfigModule.forRoot({
      provide: ConfigLoader,
      useFactory: configFactory,
      deps: [HttpClient],
    }),
    StoreModule.forRoot(reducers, { metaReducers }),
    EffectsModule.forRoot([StationsEffects, AlertEffects]),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    NgbDatepickerModule,
    NgxMaskModule.forRoot(options),
    OAuthModule.forRoot()
  ],
  providers: [
    StationService,
    DataService,
    QuickSearchService,
    RoleSelectionService,
    AlertSeverityPipe,
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: { dateInput: 'DDMMMYYYY' },
        display: {
          dateInput: 'DDMMMYYYY',
          monthYearLabel: 'MMM YYYY',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM YYYY',
          parse: {
            dateInput: 'DDMMYYYY',
          },
        },
      },
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    { provide: OAuthStorage, useFactory: storageFactory },
  ],
  // entryComponents: [SelectRoleCallbackComponent, QuickEditComponent],
  bootstrap: [AppComponent],
})
export class AppModule { }
