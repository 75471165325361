import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { HttpErrorResponse } from '@angular/common/http';

import { Airport } from 'projects/common/src/public_api';
import { StationActions, StationActionTypes } from '../../actions/station/station.actions';

export interface State extends EntityState<Airport> {
  loaded: boolean;
  loading: boolean;
  error: HttpErrorResponse;
}

export const adapter: EntityAdapter<Airport> = createEntityAdapter<Airport>({
  selectId: state => state.airportCode,
  sortComparer: false,
});

export const initialState: State = adapter.getInitialState({
  loading: false,
  loaded: false,
  error: null,
});

export function stationsReducer(state = initialState, action: StationActions): State {
  switch (action.type) {
    case StationActionTypes.LoadStations:
      return {
        ...state,
        loading: true,
      };

    case StationActionTypes.LoadStationsSuccess:
      return adapter.upsertMany(action.payload, {
        ...state,
        loading: false,
        loaded: true,
        error: null,
      });

    case StationActionTypes.LoadStationsFail:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };

    default:
      return state;
  }
}

export const { selectIds: selectStationIDs, selectEntities: selectStationEntities, selectAll: selectAllStations } = adapter.getSelectors();

export const getStationsLoading = (state: State) => state.loading;
export const getStationsLoaded = (state: State) => state.loaded;
