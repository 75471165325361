export interface Flightplan {
  flightplanCategoryCode: string;
  flightReleaseTimeUtcTs: string;
  flightplanReleaseNum: number;
  flightRouteDescriptionText: string;
  flightplanTimeEnrouteCnt: number;
  flightplanAlternatePrimaryIataCode: string;
}

export class FlightplanClass implements Flightplan {
  public flightplanCategoryCode: string;
  public flightReleaseTimeUtcTs: string;
  public flightplanReleaseNum: number;
  public flightRouteDescriptionText: string;
  public flightplanTimeEnrouteCnt: number;
  public flightplanAlternatePrimaryIataCode: string;

  constructor(obj: Partial<Flightplan>) {
    if (obj) {
      this.flightplanCategoryCode = obj.flightplanCategoryCode || null;
      this.flightReleaseTimeUtcTs = obj.flightReleaseTimeUtcTs || null;
      this.flightplanReleaseNum = obj.flightplanReleaseNum || null;
      this.flightRouteDescriptionText = obj.flightRouteDescriptionText || null;
      this.flightplanTimeEnrouteCnt = obj.flightplanTimeEnrouteCnt || null;
      this.flightplanAlternatePrimaryIataCode = obj.flightplanAlternatePrimaryIataCode || null;
    } else {
      this.flightplanCategoryCode = null;
      this.flightReleaseTimeUtcTs = null;
      this.flightplanReleaseNum = null;
      this.flightRouteDescriptionText = null;
      this.flightplanTimeEnrouteCnt = null;
      this.flightplanAlternatePrimaryIataCode = null;
    }
  }
}
