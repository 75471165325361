export enum MetricCardColor {
  default = 0,
  red,
}
export class MetricCard {
  id: string;
  title: string;
  width?: string;
  cardColor: MetricCardColor;
  value: any;
  isError: boolean;
  reportType?: ReportType;
  clickable?: boolean;
  clickText?: string;
  topCircleColor?: string;
  noBorderLine?: boolean;
  value2?: number | string;
  isValue2Available?: boolean;
  value2Error?: boolean;
  value1Color?: string;
  value2Color?: string;
}

export class MetricCardCollection {
  title: string;
  metricCards: Array<MetricCard>;
  height: number;
  width: number;
  isTimedMetricCard?: boolean;
  statusMessage?: string;

  constructor() {
    this.metricCards = new Array<MetricCard>();
  }
}

export enum ReportType {
  None = 0,

  // STPD Reports
  TaxiInPastETA,
  GateConflicts,
  NotBoardingByDMinus35,
  RONs,
  GateBlockers,
  AircraftBadTurns,
  PotentialGateHoldouts,
  CrewTineouts,
  BadCrewTurns1to2,
  BadCrewTurns2to4,
  BadCrewTurns4to8,
  CrewWithTailsAll,
  CrewWithTailsPilot,
  CrewWithTailsFa,
  uncoveredFlight1to2,
  uncoveredFlight2to4,
  uncoveredFlight4to8,
  CrewTimeoutMinus1to2Negative,
  CrewTimeout2To4Negative,
  CrewTimeout4To8Negative,
  CrewTimeoutMinus1to2Warning,
  CrewTimeout2To4Warning,
  CrewTimeout4To8Warning,
  TaxiOut,
  TaxiIn,
  Outstation,
  TBFM,
  aircraftOnGrnd,

  // Deice Reports
  DeiceTrucks,
  DeiceCurrentThroughput,
  DeiceTaxiOut,
  DeiceTodayOff,
  DeicePastEtd,
  DeiceETDNext60,
  DeiceYesterdayOff,

  // extra uncoveredFlight
  uncoveredFlightToday,
  uncoveredFlightTomorrow,

  //whatIf popup type
  RestDelayProjected,

  //whatIf hotel requirements
  HotelRequirements,
}
