import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthService } from './services/auth/auth.service';
import { AuthCallbackComponent } from './components/auth-callback/auth-callback.component';
import { AuthGuard } from './guards/auth-guard/auth.guard';

const routes: Routes = [
  {
    path: 'flight-info',
    loadChildren: () => import('./feature-modules/flight-info/flight-info.module').then(m => m.FlightInfoModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'flight-list',
    outlet: 'sidebar',
    loadChildren: () => import('./feature-modules/flight-list/flight-list.module').then(m => m.FlightListModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'capacity',
    loadChildren: () => import('./feature-modules/gate-capacity/gate-capacity.module').then(m => m.GateCapacityModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'deice',
    loadChildren: () => import('./feature-modules/deice/deice.module').then (m => m.DeiceModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'throughput',
    loadChildren: () => import('./feature-modules/station-throughput/station-throughput.module').then(m => m.StationThroughputModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'irop-replay',
    loadChildren: () => import('./feature-modules/irop-replay-dashboard/irop-replay-dashboard.module').then(m => m.IropReplayDashboardModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'advanced-search',
    loadChildren: () => import('./feature-modules/advanced-search/advanced-search.module').then(m => m.AdvancedSearchModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'policy-engine',
    loadChildren: () => import('./feature-modules/policy-engine/policy-engine.module').then(m => m.PolicyEngineModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'auth-callback',
    component: AuthCallbackComponent,
  },
  {
    path: 'user-access',
    loadChildren: () => import('./feature-modules/user-access/user-access.module').then(m => m.UserAccessModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'jv-gates',
    loadChildren: () => import('./feature-modules/jv-gates/jv-gates.module').then(m => m.JvGatesModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'severe-weather-index',
    loadChildren: () => import('./feature-modules/severe-weather-index/severe-weather-index.module').then(m => m.SevereWeatherIndexModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'diversionManagement',
    loadChildren: () => import('./feature-modules/diversion-management/diversion-management.module').then(m => m.DiversionManagementModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'operational-codes',
    loadChildren: () => import('./feature-modules/operational-codes/operational-codes.module').then(m => m.OperationalCodesModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'air-traffic-control',
    loadChildren: () => import('./feature-modules/air-traffic-control/air-traffic-control.module').then(m => m.AirTrafficControlModule),
    canActivate: [AuthGuard],
  },
  {
    path: '',
    canActivate: [AuthGuard],
    children: [],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
  providers: [AuthGuard, AuthService],
})
export class AppRoutingModule {}
