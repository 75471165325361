export interface GeographicCoordinate {
  latitudeDegreeNum: number;
  longitudeDegreeNum: number;
}

export class GeographicCoordinateClass implements GeographicCoordinate {
  public latitudeDegreeNum: number;
  public longitudeDegreeNum: number;

  constructor(obj: Partial<GeographicCoordinate> = {}) {
    this.latitudeDegreeNum = obj.latitudeDegreeNum || null;
    this.longitudeDegreeNum = obj.longitudeDegreeNum || null;
  }
}
