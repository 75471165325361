import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { ConfigService } from '../config/config.service';
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { AlertService } from 'projects/common/src/public_api';
import { FlightLeg } from '../../feature-modules/flight-info/models';
import { FlightLegList } from '../../feature-modules/flight-info/models/flight-leg-list';

@Injectable({
  providedIn: 'root',
})
export class QuickSearchService {
  public isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public flightSearchResult = new Subject<FlightLeg>();

  constructor(private http: HttpClient, private configService: ConfigService, private alertService: AlertService) {}

  quickSearch(searchText: string): Observable<FlightLegList> {
    const searchParams = new HttpParams().set('query', searchText);
    const quickSearchBaseUrl = `${this.configService.getSettings('flightSuite')}quick`;
    return this.http.get<FlightLegList>(quickSearchBaseUrl, {
      params: searchParams,
    });
  }

  searchFlight(searchText: string) {
    return this.quickSearch(searchText).subscribe(
      res => {
        this.isLoading.next(false);
        // Extract the flightId out and return it
        const legs = res['flightLegs'];
        if (legs && Array.isArray(legs) && legs.length) {
          const leg = legs[0];
          this.flightSearchResult.next(leg);
        } else {
          // If we can't find the flight Id, show an error
          this.alertService.handleAlert('No results were found.', ['OK']);
        }
      },
      error => {
        this.isLoading.next(false);
        let errorMessage = 'An error has occurred.';
        if (error instanceof HttpErrorResponse) {
          errorMessage = (error as HttpErrorResponse).error;
        } else {
          errorMessage = error as string;
        }
        this.alertService.handleAlert(errorMessage, ['OK']);
      }
    );
  }
}
