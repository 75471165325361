import { Component, OnInit } from '@angular/core';
import * as moment from 'moment-timezone';
import { Store, select } from '@ngrx/store';
import { AppState } from '../../store';
import * as SidebarActions from '../../store/actions/sidebar/sidebar.actions';
import { AuthService } from '../../services/auth/auth.service';
import { RoleSelectionService } from '../../services/role-selection/role-selection.service';
import { formatRoleName } from '../../utils/Formatter';

@Component({
  selector: 'emt-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  constructor(private authService: AuthService, private store: Store<AppState>, private roleService: RoleSelectionService) {}
  time =
    moment()
      .utc()
      .format('HHmm') + 'z';
  searchValue = '';
  accountDropdownOpen = false;
  logoutModal = false;
  userRole = null;

  ngOnInit() {
    setInterval(() => {
      this.time =
        moment()
          .utc()
          .format('HHmm') + 'z';
      this.roleService.getSelectedRole().subscribe(role => {
        this.userRole = role;
      });
    }, 1000);
  }

  toggleSideNav() {
    this.store.dispatch(new SidebarActions.ToggleSidebar());
    window.scrollTo(0,0);
  }

  getLastName() {
    const username = this.authService.getUsername();
    const parts = username.split(', ');
    if (parts.length > 1) {
      return `${parts[0]}, `;
    }
    return '';
  }

  getFirstName() {
    const username = this.authService.getUsername();
    const parts = username.split(', ');
    if (parts.length > 1) {
      return parts[1];
    }
    return username;
  }

  getRoleName(role: string) {
    return formatRoleName(role);
  }

}
