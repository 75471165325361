import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ConfigService } from '../../../../../core/src/app/services/config/config.service';
import * as moment_ from 'moment';
import { pluck } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
const moment = moment_;
import { chain, mapKeys } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class AlertEventService {
  lastRequest: number = null;

  categoriesSubject = new BehaviorSubject<{}>({});
  reverseCategorySubject = new BehaviorSubject<{}>({});

  constructor(private http: HttpClient, private configService: ConfigService) {}

  public getAlerts() {
    let baseUrl = this.configService.getSettings('alerting');
    let params: HttpParams;
    // If its the first request, don't send the lastRequest param.
    // This gets all the alerts.
    if (this.lastRequest !== null) {
      params = new HttpParams().set('lastRequest', `${this.lastRequest}`);
    }
    this.lastRequest = moment().valueOf();
    return this.http.get(`${baseUrl}/events`, { params: params });
  }

  public getCategories() {
    let baseUrl = this.configService.getSettings('alerting');
    this.http
      .get(`${baseUrl}/categories`)
      .pipe(pluck('categories'))
      .subscribe(categories => {
        this.categoriesSubject.next(categories);
        this.getReverseLookupMap(categories);
      });
  }

  public archiveAlert(alertEvent) {
    const baseUrl = this.configService.getSettings('alerting');
    return this.http.post(`${baseUrl}/acknowledge-event`, alertEvent);
  }

  // TODO: Get back end to send the "category", this sets up for a terrible and inefficient reverse lookup
  private getReverseLookupMap(categories) {
    let reverseCategoryMap = chain(categories)
      .mapKeys((value, key) => value.value)
      .reduce((result, value, key) => {
        let newValue = mapKeys(value.types, (newValue, newKey) => {
          return newValue.value;
        });
        value = newValue;
        let objectKeys = Object.keys(value);
        let fixedKeys = {};

        objectKeys.forEach(element => {
          fixedKeys[element] = key;
        });

        return { ...result, ...fixedKeys };
      }, {})
      .value();

    this.reverseCategorySubject.next(reverseCategoryMap);
  }
}
