import { Injectable } from '@angular/core';
import { OAuthService, AuthConfig, OAuthErrorEvent } from 'angular-oauth2-oidc';
import { Observable, from, of, Subject, BehaviorSubject } from 'rxjs';
import { ConfigService } from '../config/config.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { take, map, switchMap, filter } from 'rxjs/operators';
import { Authorization } from '../../models/authorization.model';
import { TokenService } from './token.service';
import { RoleSelectionService } from '../role-selection/role-selection.service';
import { UserLoginAction, UserLogoutAction } from '../../store/actions/auth/auth.actions';
import { Store, select } from '@ngrx/store';
import { AppState } from '../../store';
import { UserProfile } from '../../models/userprofile.model';
import { getUser } from '../../store/selectors/auth/auth.selectors';


@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public static readonly preAuthTypeKeyFull = 'full';
  public authorization: Authorization;
  public authorizationSubject = new Subject<Authorization>();
  private enableLogging = false;
  private user$: Observable<UserProfile> = this.store.pipe(select(getUser));
  private roles: { name: string; value: string }[];
  public rolesSubject = new BehaviorSubject<{ name: string; value: string }[]>([]);
  private interceptorUrls;

  constructor(
    private configService: ConfigService, 
    private http: HttpClient, 
    private roleSelectionService: RoleSelectionService, 
    private tokenService: TokenService,
    private oauthService: OAuthService,
    private store: Store<AppState>) {

    this.enableLogging = this.configService.getSettings('authcfg_enable_logging');

    const config: AuthConfig = {
      issuer: this.configService.getSettings('authcfg_issuer'),
      clientId: this.configService.getSettings('authcfg_client_id'),
      responseType: this.configService.getSettings('authcfg_response_type'),
      redirectUri: `${window.location.protocol}//${window.location.host}${this.configService.getSettings('authcfg_redirect_uri')}`,
      silentRefreshRedirectUri: `${window.location.protocol}//${window.location.host}${this.configService.getSettings('authcfg_silent_redirect_uri')}`,
      scope: this.configService.getSettings('authcfg_scope'),
      useSilentRefresh: this.configService.getSettings('authcfg_automaticSilentRenew'),
      silentRefreshTimeout: this.configService.getSettings('authcfg_silentRefreshTimeout'),
      timeoutFactor: this.configService.getSettings('authcfg_timeoutFactor'),
      sessionChecksEnabled: this.configService.getSettings('authcfg_sessionChecksEnabled'),
      showDebugInformation: this.configService.getSettings('authcfg_showDebugInformation'),
      nonceStateSeparator: this.configService.getSettings('authcfg_nonceStateSeparator'), // Real semicolon gets mangled by IdentityServer's URI encoding,
      userinfoEndpoint: this.configService.getSettings('authcfg_userinfoEndpoint'),
      tokenEndpoint: this.configService.getSettings('authcfg_token_endpoint'),
      loginUrl: this.configService.getSettings('authcfg_authorization_endpoint'),
      logoutUrl: this.configService.getSettings('authcfg_end_session_endpoint'),
    };

    this.oauthService.configure(config);

    this.oauthService.events
      .pipe(filter(e => ['token_received'].includes(e.type)))
      .subscribe(e => {
        const user: UserProfile = this.parseJwt(this.oauthService.getAccessToken());
        this.store.dispatch(new UserLoginAction({user}));
        this.writeAuthDebug('User object is assgined.');
      });   

    this.oauthService.setupAutomaticSilentRefresh();
    this.oauthService.tryLogin()

    this.oauthService.events.subscribe(event => {
      if (event instanceof OAuthErrorEvent) {
        console.error('OAuthErrorEvent Object:', event);
      } else {
        console.warn('OAuthEvent Object:', event);
      }
    });

    try{
      const keys = this.configService.getSettings('token-interceptor-keys');

      let interCeptorUrls = keys.map(key => {
        return this.configService.getSettings(key);
      });
      this.interceptorUrls = interCeptorUrls || [];

      const user: UserProfile = this.parseJwt(this.oauthService.getAccessToken());
      this.store.dispatch(new UserLoginAction({user}));
      this.writeAuthDebug('User object is assgined.');
    }catch(e){
      console.log(`Error in AuthService, constructor: ${e}`)
    }
  }

  public isLoggedIn(): Promise<boolean> {
    return of(this.oauthService.hasValidAccessToken()).pipe(take(1)).toPromise();
  }

  public startAuthentication(): void {
    console.log('startAuthentication invoked...');
    // initial redirect to IdP
      this.oauthService.initCodeFlow()
    }
    
  public writeAuthDebug(objToLog: any): void {
    if (this.enableLogging) {
      console.log(JSON.stringify(objToLog));
    }
  }

  public hasPermission(application: string, accessLevel: string): boolean {
    if (!this.authorization) return false;
    const appPermissions = JSON.parse(this.authorization.sub);
    return appPermissions[application] && appPermissions[application][accessLevel];
  }

  public completeAuthentication(router: any): void {
    this.writeAuthDebug('completeAuthentication invoked...');

    // Determine whether this is a full redirect auth or silent redirect
    const authType = localStorage.getItem(this.configService.getSettings('preAuthTypeKey'));
    // this.writeAuthDebug(`authType is: ${authType}`);
    localStorage.removeItem(this.configService.getSettings('preAuthTypeKey'));

    if (authType != null && authType.toLowerCase() === AuthService.preAuthTypeKeyFull) {
      // Retrieve the original location requiring auth that the use was trying to access
      // and redirect her to it

      const nextUrl = localStorage.getItem(this.configService.getSettings('postAuthPageSessionKey'));
      if (nextUrl != null && nextUrl.length > 0) {
        localStorage.removeItem(this.configService.getSettings('postAuthPageSessionKey'));
        // this.writeAuthDebug('Re-Routing to next requested url: ' + nextUrl);
        router.navigateByUrl(nextUrl);
      } else {
        const originalUrl = localStorage.getItem(this.configService.getSettings('preAuthPageSessionKey'));
        localStorage.removeItem(this.configService.getSettings('preAuthPageSessionKey'));

        // this.writeAuthDebug('Re-Routing to originally requested url: ' + originalUrl);
        router.navigateByUrl(originalUrl);
      }
    }
  }


  public getUsername(): string {
    let username: string;
    this.user$.pipe(
      take(1),
      map(user=> `${user.family_name}, ${user.given_name}`),
    )
    .subscribe(res =>{
      username = res      
    });

    return username ? username : 'EMT User'
  }

  public getFullUsername() {
    let fullUsername: string;

    this.user$.pipe(
      take(1),
      map(user=> `${user.preferred_username} ${user.family_name}, ${user.given_name}`),
    )
    .subscribe(res =>{
      fullUsername = res      
    });

    return fullUsername
  }

  public getUserId() {
    let userId: string;

    this.user$.pipe(
      take(1),
      map(user=> user.preferred_username),
    )
    .subscribe(res =>{
      userId = res      
    });

    return userId ? userId : null;
  }

  public getRoles() {
    this.rolesSubject.next(this.roles);
  }

  public startSignout() {
    console.log('logout invoked...');
    this.oauthService.logOut();
    this.store.dispatch(new UserLogoutAction());
  }

  public getAuthorization(selectedRole?: string, vipsRole?: string): Promise<Authorization> {
    const baseUrl = this.configService.getSettings('authorization');
    if (this.authorization) {
      this.writeAuthDebug('Using stored authorization');
      return Promise.resolve(this.authorization);
    }
    return this.store
      .pipe(
        select(getUser),
        take(1),
        switchMap(user => {
          if (user && this.oauthService.getAccessToken()) {
            this.writeAuthDebug('Making First BFF call to fetch authorization');
            const requestOptions = {
              headers: new HttpHeaders({ accessToken: this.oauthService.getAccessToken() }),
            };
            if (selectedRole) {
              requestOptions['params'] = { selectedRole };
            }
            if (vipsRole) {
              sessionStorage.setItem('rawVDIRole', vipsRole);
              requestOptions['params'] = { ...requestOptions['params'], 'Role': vipsRole };
            }

            return this.http.get(baseUrl, requestOptions).pipe(
              map((results: { accessPermission?: string; roles: Array<string>; roleMap: { name: string; value: string }[]; selectedRole?: string; }) => {
                if (results.accessPermission) {
                  this.authorization = this.parseJwt(results.accessPermission);
                  this.tokenService.setToken(results.accessPermission);
                  this.tokenService.setInterceptorUrls(this.interceptorUrls);
                  this.authorizationSubject.next(this.authorization);

                  // Need to set role if response has selectedRole defined
                  if (results.selectedRole && results.selectedRole.length > 0) {
                    this.rolesSubject.next(results.roleMap);
                    this.roleSelectionService.setSelectedRole(results.selectedRole);
                  }
                  return this.authorization;
                } else {
                  this.roles = results.roleMap;
                  this.rolesSubject.next(this.roles);
                  return of(null);
                }
              })
            );
          } else {
            return of(null);
          }
        })
      )
      .toPromise();
  }

  // This is a method to parse a JWT token copied from here:
  // https://stackoverflow.com/a/38552302
  private parseJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = decodeURIComponent(
      atob(base64Url)
        .split('')
        .map(function(c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );

    return JSON.parse(base64);
  }

  /*
    @active-branch: what-if-submissions
  */
  public getPPR() {
    let ppr: string;

    this.user$.pipe(
      take(1),
      map(user=> user.sub),
    )
    .subscribe(res =>{
      ppr = res      
    });

    return ppr ? ppr : null;
  }

  /*
    @name getUserProperty
    @desc get property of the user (private) profile
    @active-branch: auth and bug
  */
  public getUserProperty(p: string) {
    let prop: string;

    this.user$.pipe(
      take(1),
      map(user=> user[p]),
    )
    .subscribe(res =>{
      prop = res      
    });

    return prop ? prop : null;
  }
}
