import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';

import { Observable, combineLatest } from 'rxjs';
import { TokenService } from '../services/auth/token.service';
import { some } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class TokenInterceptor implements HttpInterceptor {
  private token$: Observable<string>;
  private token;
  private urls$: Observable<string[]>;
  private urls;
  private matchingUrls: [];
  constructor(private tokenService: TokenService) {
    this.token$ = tokenService.tokenSubject;
    this.urls$ = tokenService.urlsSubject;
    combineLatest(this.token$, this.urls$).subscribe(values => {
      this.token = values[0];
      this.urls = values[1];
    });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let isInterceptable =
      this.token &&
      this.urls &&
      this.urls.length &&
      some(this.urls, url => {
        return request.url.includes(url);
      });
    request = isInterceptable ? this.addHeader(request) : request.clone();
    return next.handle(request);
  }

  addHeader(request: HttpRequest<any>) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  }
}
