import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { Store, select } from '@ngrx/store';
import * as SidebarActions from '../../store/actions/sidebar/sidebar.actions';
import { AppState } from '../../store';
import { selectFlightListExpanded } from '../../store/selectors/sidebar/sidebar.selectors';
import { QuickSearchService } from '../../services/quick-search/quick-search.service';

@Component({
  selector: 'emt-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit, OnDestroy {
  public flightList$: Observable<boolean>;
  public isLoading$: Observable<boolean>;
  public routerSidebarOutletActive: boolean;
  private routerSubscription: Subscription;
  public isReportOpened: boolean;

  constructor(private store: Store<AppState>, private router: Router, private quickSearchService: QuickSearchService) {}

  ngOnInit() {
    this.flightList$ = this.store.pipe(select(selectFlightListExpanded));
    this.isLoading$ = this.quickSearchService.isLoading;

    this.routerSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (!event.url.includes('sidebar') && this.routerSidebarOutletActive) {
          this.store.dispatch(new SidebarActions.RetractFlightList());
        }
        this.routerSidebarOutletActive = event.url.includes('sidebar');
      } else if (event instanceof NavigationStart) {
        this.isReportOpened = event.url.includes('report');
      }
    });
  }

  ngOnDestroy() {
    this.routerSubscription.unsubscribe();
  }
}
