import * as moment from 'moment';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'formatQuickEditDate' })
export class FormatQuickEditDatePipe implements PipeTransform {
  transform(value: string): string {
    const format = 'MM/DD/YYYY HHmm[z]';
    return moment(value)
      .utc()
      .format(format);
  }
}
