<div class="search-date-input" [ngClass]="{ error: control.errors, disabled: control.disabled }">
  <label class="heavy-label" [style.color]="control && control.errors && control.dirty ? '#C01933' : 'black'">
    {{ label }}
  </label>
  <input
    autocomplete="off"
    ngbDatepicker
    #ngbDatepicker="ngbDatepicker"
    name="date-input"
    [formControl]="control"
    [tabindex]="tabIndex"
    [placeholder]="placeholder"
    [readonly]="readonly"
    minDate="07/17/2019"
    [minDate]="minDate"
    [maxDate]="maxDate"
    (blur)="validateInput()"
    [style.borderColor]="control && control.errors && control.dirty ? '#c01933' : 'black'"
  />
  <span class="bar"></span>
  <emt-delta-icon class="icon-search" iconName="icon-calendar" width="17" height="20" (click)="openDatepicker()"></emt-delta-icon>
  <div *ngIf="control && control.errors && control.dirty" class="error-msg">
    <emt-delta-icon iconName="circle-x"></emt-delta-icon>
    <ng-template [ngIf]="control.hasError('required')">
      Please enter a date
    </ng-template>
    <ng-template [ngIf]="control.hasError('ngbDate') && control.errors['ngbDate']['invalid']">
      Enter the date in format{{ placeholder ? ': ' + placeholder : '' }}
    </ng-template>
    <ng-template [ngIf]="control.hasError('ngbDate') && control.errors['ngbDate']['requiredBefore']">
      Enter a date after {{ minDateInput.format(placeholder || 'MM/DD/YYYY') }}
    </ng-template>
    <ng-template [ngIf]="control.hasError('ngbDate') && control.errors['ngbDate']['requiredAfter']">
      Enter a date before {{ maxDateInput.format(placeholder || 'MM/DD/YYYY') }}
    </ng-template>
    <span *ngFor="let error of customErrors">
      <ng-template [ngIf]="control.hasError(error.type)">
        {{ error.message }}
      </ng-template>
    </span>
  </div>
</div>
