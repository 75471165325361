<div #loader class="loader-overlay">
  <div class="content">
    <div class="loading-text">
      <span id="red-letter-L" class="loading-letter red-letter">L</span>
      <span id="red-letter-o" class="loading-letter red-letter">o</span>
      <span id="red-letter-a" class="loading-letter red-letter">a</span>
      <span id="red-letter-d" class="loading-letter red-letter">d</span>
      <span id="red-letter-i" class="loading-letter red-letter">i</span>
      <span id="red-letter-n" class="loading-letter red-letter">n</span>
      <span id="red-letter-g" class="loading-letter red-letter">g</span>
    </div>
    <div class="loading-text">
      <span id="blue-letter-L" class="loading-letter blue-letter">L</span>
      <span id="blue-letter-o" class="loading-letter blue-letter">o</span>
      <span id="blue-letter-a" class="loading-letter blue-letter">a</span>
      <span id="blue-letter-d" class="loading-letter blue-letter">d</span>
      <span id="blue-letter-i" class="loading-letter blue-letter">i</span>
      <span id="blue-letter-n" class="loading-letter blue-letter">n</span>
      <span id="blue-letter-g" class="loading-letter blue-letter">g</span>
    </div>
    <img id="loading-image" src="../../../assets/svgs/airplane-loader.svg" />
  </div>
</div>
