import { SortParams } from './sort-params.model';
import { Params } from '@angular/router';

export class SortParamsUpdate {
  sortParams: SortParams;
  queryParams: Params;

  constructor(sortParams: SortParams, paramMap: Params) {
    this.sortParams = sortParams;
    this.queryParams = paramMap;
  }
}
